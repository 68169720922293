import React from 'react';
import SchoolsOfGroup from './components/SchoolsOfGroup';
import GroupSettings from '../GroupSettings';
import { SchoolGroupStructureType } from 'searchality-data';
import { useParams } from 'react-router-dom';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

const SchoolSettings: React.FC = () => {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { structureType } = schoolGroup || {};
  return structureType === SchoolGroupStructureType.STANDALONE ? (
    <GroupSettings />
  ) : (
    <SchoolsOfGroup />
  );
};

export default SchoolSettings;
