import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, Modal } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { required } from 'validations';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { ApplicationSchoolStatus } from 'searchality-data';
import {
  useGetCandidateQuery,
  useUpdateCandidateMutation,
} from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';

import './AcceptCandidateModal.styles.scss';

type AcceptCandidateModalProps = {
  className?: string;
};

const AcceptCandidateModal: ForwardRefRenderFunction<
  ModalRef,
  AcceptCandidateModalProps
> = (props, ref) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { schoolGroupId, candidateId } = useParams();

  const [updateCandidate] = useUpdateCandidateMutation();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const classes = classNames('proceed-applicant-modal', className);

  const { data: candidate } = useGetCandidateQuery({
    schoolGroupId,
    candidateId,
  });

  const { firstName, schools, vacancy } = candidate || {};

  const schoolName = schools?.map(({ name }) => name).join('/');

  const handleSubmit = useCallback(
    async (values: { message: string }) => {
      updateCandidate({
        candidateId,
        schoolGroupId,
        message: values.message,
        status: ApplicationSchoolStatus.IN_PROCESS,
      })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('CandidateAccepted')));
          modal.close();
        })
        .catch((error) => dispatch(popServerError(error)));
    },
    [candidateId, dispatch, modal, schoolGroupId, t, updateCandidate],
  );

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('Profile.AcceptCandidateModal.title')}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          message: `${t('Profile.AcceptCandidateModal.initial1', {
            firstName,
          })}${schoolName}${t('Profile.AcceptCandidateModal.initial2', {
            positionTitle: vacancy?.positionTitle,
          })}`,
        }}
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('Profile.AcceptCandidateModal.description')}</p>
            <Field
              name="message"
              component={TextAreaField}
              validate={required()}
              required
            />
            <div className="actions">
              <Button variant="outline" onClick={() => modal.close()}>
                {t('cancel')}
              </Button>
              <Button disabled={submitting || invalid} type="submit">
                {t('Profile.AcceptCandidateModal.confirm')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(AcceptCandidateModal);
