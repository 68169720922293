import {
  useDeleteSchoolMutation,
  useGetSchoolAccountQuery,
} from 'api/schoolGroups.api';
import GoBackButton from 'components/GoBackButton';
import Heading from 'components/Heading';
import { Button } from 'ncoded-component-library';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSchoolProfileTabs from '../../../hooks/useSchoolProfileTabs';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import TabsNavigation from 'components/TabsNavigation';

const SchoolGroupHeader: React.FC = () => {
  const { schoolGroupId } = useParams();
  const { data: school } = useGetSchoolAccountQuery(schoolGroupId);

  const tabs = useSchoolProfileTabs();
  const { t } = useTranslation();
  const [deleteSchool] = useDeleteSchoolMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { name, status, owner } = school || {};

  const handleDeleteRequest = useCallback(async () => {
    deleteSchool({ schoolGroupId })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('DeleteSchoolModal.success')));
        paramsActions.initializeParams('schoolGroupsParams', {
          $limit: 20,
          $populate: ['schools'],
        });
        return navigate('/school-groups');
      })
      .catch(() => {
        dispatch(popError(t('DeleteSchoolModal.fail')));
      });
  }, [deleteSchool, dispatch, navigate, schoolGroupId, t]);

  const handleSchoolDelete = useCallback(async () => {
    await confirm({
      title: t('deleteSchool'),
      content: t('DeleteSchoolModal.content'),
      confirmBtnText: t('deleteSchool'),
      onSubmit: handleDeleteRequest,
    });
  }, [handleDeleteRequest, t]);

  return (
    <>
      <GoBackButton to="../../" />
      <Heading title={name}>
        <div className="heading-buttons-group">
          <p className={`vacancy-status`}>
            {t('status')}:&nbsp; <span>{status}</span>
          </p>
          <Button
            variant="outline"
            onClick={() => (window.location.href = `mailto:${owner?.email}`)}
          >
            {t('contactSchoolOwner')}
          </Button>
          <Button onClick={handleSchoolDelete} variant="outline">
            {t('deleteSchool')}
          </Button>
        </div>
      </Heading>
      <TabsNavigation tabs={tabs} />
    </>
  );
};

export default SchoolGroupHeader;
