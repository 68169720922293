import React from 'react';
import { Outlet } from 'react-router-dom';

import './Auth.styles.scss';

const AdminAuthRouter: React.FC = () => {
  return <Outlet />;
};

export default AdminAuthRouter;
