import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default (types: Record<string, string>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.entries(types || {}).map(({ 0: key, 1: value }) => ({
      label: t(`VacancyType.${value}`),
      value: value,
    }));
  }, [t, types]);

  return options;
};
