import React from 'react';
import classNames from 'classnames';
import ProfileMain from './components/ProfileMain';
import { ProfileCardType } from 'models/ProfileCard.type';

import './Profile.styles.scss';

type ProfileProps = {
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  cardInfo: ProfileCardType;
};

const Profile: React.FC<ProfileProps> = (props) => {
  const { className, children, cardInfo } = props;

  const classes = classNames(className, 'searchality-profile');

  return (
    <div className={classes}>
      {children}
      <ProfileMain cardInfo={cardInfo} />
    </div>
  );
};

export default Profile;
