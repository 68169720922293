import React from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import { useCreateAssociationMutation } from 'api/associations.api';
import { CreateAssociationFrom } from 'models/Association';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import { useNavigate } from 'react-router-dom';
import AssociationInformationForm from '../../components/AssociationInformationForm';

import './CreateAssociation.styles.scss';

type CreateAssociationProps = {
  className?: string;
};

const CreateAssociation: React.FC<CreateAssociationProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('create-association', className);

  const [createAssociation] = useCreateAssociationMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCreateAssociation = (values: CreateAssociationFrom) => {
    const { state, ...rest } = values;
    createAssociation({
      ...rest,
      states: values?.state ? [values.state] : values?.states,
    })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('Associations.CreateAssociation.success')));
        navigate('../');
      })
      .catch(() =>
        dispatch(popError(t('Associations.CreateAssociation.fail'))),
      );
  };

  return (
    <div className={classes}>
      <GoBackButton to="../" />
      <AssociationInformationForm onFormSubmit={handleCreateAssociation} />
    </div>
  );
};

export default CreateAssociation;
