import React, { useCallback } from 'react';
import classNames from 'classnames';
import SchoolGroupInformation from './components/SchoolGroupInformation';
import SchoolPricingPlan from './components/SchoolPricingPlan';
import SchoolProfileInformation from './components/SchoolProfileInformation';
import { useTranslation } from 'react-i18next';

import './GroupSettings.styles.scss';
import { Field, Form } from 'react-final-form';
import ToggleField from 'components/ToggleField';
import { useParams } from 'react-router-dom';
import {
  useGetSchoolAccountQuery,
  useSchoolGroupTestFlagMutation,
} from 'api/schoolGroups.api';
import { Button } from 'ncoded-component-library';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

type GroupSettingsProps = {
  className?: string;
};

const GroupSettings: React.FC<GroupSettingsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('group-settings', className);

  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const [testFlagUpdate] = useSchoolGroupTestFlagMutation();
  const dispatch = useAppDispatch();

  const { matchingInformation } = schoolGroup || {};

  const { isForTesting } = matchingInformation || {};

  const handleisForTesting = useCallback(
    async ({ isForTesting }: { isForTesting: boolean }) => {
      await testFlagUpdate({
        schoolGroupId,
        isForTesting,
      })
        .unwrap()
        .then(() => dispatch(popSuccess(t('changedAccTypeSuccess'))))
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, schoolGroupId, t, testFlagUpdate],
  );

  return (
    <div className={classes}>
      <SchoolGroupInformation />
      <SchoolPricingPlan />
      <SchoolProfileInformation />
      <div className="group-settings__account-type">
        <h5>{t('accountType')}</h5>
        <Form
          onSubmit={handleisForTesting}
          initialValues={{ isForTesting: !!isForTesting }}
          render={({ handleSubmit, dirty, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="isForTesting"
                component={ToggleField}
                label={t('isForTesting')}
              />
              {dirty && (
                <Button type="submit" disabled={submitting}>
                  {t('changeAccountType')}
                </Button>
              )}
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default GroupSettings;
