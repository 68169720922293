import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Chip.styles.scss';
import './Chip.styles.responsive.scss';

type ChipProps = {
  className?: string;
  text: ReactNode;
  children?: ReactNode;
};

const Chip: React.FC<ChipProps> = (props) => {
  const { children, className, text } = props;

  const classes = classNames('searchality-chip', className);

  return (
    <p className={classes}>
      <span>{text}</span>
      {children}
    </p>
  );
};

export default Chip;
