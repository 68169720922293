import React, { useMemo } from 'react';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';
import { Role } from 'searchality-data';
import credentialsService from 'services/credentialsService';

export type EnhancedRouteProps = {
  authorized?: boolean;
  onlyPublic?: boolean;
  authorizedRoles?: Array<Role>;
} & Omit<RouteProps, 'element'> & {
    element: React.FC | React.ReactNode;
  };

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const { element, onlyPublic, authorized, authorizedRoles } = props;

  const isLoggedIn = !!credentialsService.token;
  const { pathname } = useLocation();

  const finalRoute = useMemo(() => {
    if ((authorized || authorizedRoles) && !isLoggedIn) {
      return (
        <Navigate
          to={{
            pathname: `/auth/login`,
          }}
          state={{ from: pathname }}
          replace
        />
      );
    }

    if (isLoggedIn && onlyPublic) {
      return <Navigate to="/" />;
    }

    if (typeof element === 'function') {
      const Component = element;

      return <Component />;
    }
    return element as React.ReactElement;
  }, [authorized, authorizedRoles, element, isLoggedIn, onlyPublic, pathname]);

  return finalRoute;
};

export default EnhancedRoute;
