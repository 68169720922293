import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import './HrisSettings.styles.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { Form } from 'react-final-form';
import { CSV_MAP } from 'constants/index';
import CheckboxWithInputField from 'components/CheckboxWithInputField';
import { useParams } from 'react-router-dom';
import {
  useGetSchoolAccountQuery,
  useUpdateCSVMapMutation,
} from 'api/schoolGroups.api';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';

type HrisSettingsProps = {
  className?: string;
};

const HrisSettings: React.FC<HrisSettingsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { schoolGroupId } = useParams();
  const dispatch = useAppDispatch();

  const {
    data: { candidateCsvPropertyMap },
  } = useGetSchoolAccountQuery(schoolGroupId);

  const classes = classNames('hris-settings', className);

  const [updateCSVMap] = useUpdateCSVMapMutation();

  const initialValues = useMemo(() => {
    const initV = candidateCsvPropertyMap?.reduce(
      (prev, acc) => ({
        ...prev,
        [`${acc.property}`]: {
          isIncluded: true,
          value: acc.exportProperty,
        },
      }),
      {},
    );

    return initV;
  }, [candidateCsvPropertyMap]);

  const handleSubmit = useCallback(
    (values: Record<string, { isIncluded: boolean; value?: string }>) => {
      const candidateCsvPropertyMap = Object.entries(values)
        .filter(([_, value]) => value.isIncluded)
        .map(([key, value]) => ({
          property: key,
          exportProperty: value.value,
        }));
      updateCSVMap({ schoolGroupId, candidateCsvPropertyMap })
        .unwrap()
        .then(() => dispatch(popSuccess(t('successfullyUpdateHRIS'))))
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, schoolGroupId, t, updateCSVMap],
  );

  const onDeactivate = useCallback(() => {
    updateCSVMap({ schoolGroupId, candidateCsvPropertyMap: [] })
      .unwrap()
      .then(() => dispatch(popSuccess(t('successfullyUpdateHRIS'))))
      .catch((e) => dispatch(popServerError(e)));
  }, [dispatch, schoolGroupId, t, updateCSVMap]);

  return (
    <div className={classes}>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine }) => (
          <form className="hris-settings__form" onSubmit={handleSubmit}>
            <div className="hris-settings__title">
              <h3>{t('hrisIntegrationMappingTable')}</h3>
              <div>
                {!!candidateCsvPropertyMap?.length && (
                  <Button variant="outline" onClick={onDeactivate}>
                    {t('deactivate')}
                  </Button>
                )}
                <Button type="submit" disabled={pristine}>
                  {t('saveChanges')}
                </Button>
              </div>
            </div>
            <div className="hris-settings__form__fields">
              <div className="hris-settings__form__fields__column">
                <p>{t('searchalityField')}</p>
                <p>{t('hrisField')}</p>
              </div>
              {Object.entries(CSV_MAP).map(([key, value]) => (
                <CheckboxWithInputField
                  key={key}
                  name={`['${key}']`}
                  label={value}
                />
              ))}
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default HrisSettings;
