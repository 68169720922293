import React from 'react';
import classNames from 'classnames';
import Select, {
  SelectProps,
} from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './SortSelect.styles.scss';

type SortSelectProps = SelectProps<string>;

const SortSelect: React.FC<SortSelectProps> = (props) => {
  const { className, ...rest } = props;

  const classes = classNames('sort-select', className);

  return <Select className={classes} {...rest} />;
};

export default SortSelect;
