import React, { ChangeEvent, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import Input from 'components/Input/Input.component';

export type InputFieldProps = FieldRenderProps<string, HTMLElement>;

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    className,
    input: { onChange, value, ...inputRest },
    meta: { error, touched },
    onlyNumbers = false,
    ...rest
  } = props;

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;

      if (onlyNumbers && value && !/^(\d){0,}$/.test(value)) {
        return onChange(value.replace(/[^\d]/g, ''));
      }
      onChange(value);
    },
    [onChange, onlyNumbers],
  );

  return (
    <Input
      wrapperClassName={className}
      error={error && touched ? error : ''}
      {...rest}
      {...inputRest}
      onChange={handleOnChange}
      value={value}
    />
  );
};

export default InputField;
