import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Field, useFormState } from 'react-final-form';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';

import './CheckboxWithInputField.styles.scss';

type CheckboxWithInputFieldProps = {
  className?: string;
  name: string;
  label: string;
};

const CheckboxWithInputField: React.FC<CheckboxWithInputFieldProps> = (
  props,
) => {
  const { className, name, label } = props;

  const { t } = useTranslation();

  const classes = classNames('checkbox-with-input-field', className);

  const { values } = useFormState();

  const fieldName = name.split("'")[1];

  const validation = useCallback(
    (v: any, allValues: any) => {
      const value = allValues[fieldName];

      if (value?.isIncluded && !value?.value?.length) {
        return t('requiredField');
      }
    },
    [fieldName, t],
  );

  return (
    <div className={classes}>
      <Field
        name={`${name}.isIncluded`}
        component={CheckboxField}
        type="checkbox"
        isStyledDefault
        label={label}
      />
      <Field
        name={`${name}.value`}
        component={InputField}
        placeholder={t('hrisInputPlaceholder')}
        validate={validation}
        disabled={!values[fieldName]?.isIncluded}
      />
    </div>
  );
};

export default CheckboxWithInputField;
