import { useParams } from 'react-router-dom';

export default () => {
  const { associationId } = useParams();
  return [
    {
      to: `/associations/${associationId}/schools`,
      label: 'schools',
    },
    {
      to: `/associations/${associationId}/members`,
      label: 'members',
    },
    {
      to: `/associations/${associationId}/account`,
      label: 'account',
    },
  ];
};
