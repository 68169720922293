import { Association } from 'models/Association';
import { useTranslation } from 'react-i18next';

export default (association: Association) => {
  const { t } = useTranslation();

  const { name, website, states, type } = association || {};

  return [
    {
      label: t('associationName'),
      value: name,
    },
    {
      label: t('associationWebsite'),
      value: website,
    },
    ...(states?.length
      ? [
          {
            label: t('state'),
            value: states.toString(),
          },
        ]
      : []),
    {
      label: t('associationType'),
      value: type,
    },
  ];
};
