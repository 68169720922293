import React from 'react';

import { connect } from 'react-redux';
import { popServerError } from 'store/slices/popNotifications.slice';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    const { dispatch } = this.props;
    dispatch(popServerError(error));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // eslint-disable-next-line react/jsx-filename-extension
      return <p>Something went wrong.</p>;
    }

    return <>{this.props.children}</>;
  }
}

export default connect()(ErrorBoundary);
