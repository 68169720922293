export type STORAGE_KEYS = 'token' | 'refreshToken';

export default {
  ram: {} as Record<string, string>,
  setItem(key: STORAGE_KEYS, value: Record<string, any> | string) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: STORAGE_KEYS): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: STORAGE_KEYS): T {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key]);
    }
  },
};
