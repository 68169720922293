import React from 'react';
import { useTranslation } from 'react-i18next';
import SliderButtons from 'components/SliderButtons';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';
import useSchoolInformation from '../../../../hooks/useSchoolInformation';

const SchoolProfileInformation: React.FC = () => {
  const { list, schools, nextStep, previousStep } = useSchoolInformation();

  const { t } = useTranslation();

  return (
    <div className="school-information-preview">
      <ReviewCardWrapper list={list} title={t('schoolInformation')} />
      {schools?.length > 1 && (
        <SliderButtons onNext={nextStep} onPrevious={previousStep} />
      )}
    </div>
  );
};

export default SchoolProfileInformation;
