import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './TemplateIllustration.styles.scss';

type TemplateIllustrationProps = {
  className?: string;
  img?: ReactNode;
  title: string;
  description?: string;
  children?: ReactNode;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
};

const TemplateIllustration: React.FC<TemplateIllustrationProps> = (props) => {
  const {
    className,
    img,
    title,
    description,
    headingLevel = 1,
    children,
  } = props;

  const classes = classNames('template-illustration', className);

  const Heading = `h${headingLevel}` as const;

  return (
    <div className={classes}>
      <div className="template-illustration__image-container">{img}</div>
      <Heading>{title}</Heading>
      {description && <p>{description}</p>}
      {children}
    </div>
  );
};

export default TemplateIllustration;
