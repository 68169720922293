import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileCardType } from 'models/ProfileCard.type';
import DateService from 'services/Date.service';
import ProfilePreviewPdfSection from '../../../ProfilePreviewPdfSection';
import ProfileWorkAvailabilitySection from '../../../ProfileWorkAvailabilitySection';

import './ProfileWorkProfile.styles.scss';

type ProfileWorkProfileProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  | 'educationPhilosophyUrl'
  | 'videoUrl'
  | 'rightToWork'
  | 'startDate'
  | 'hasRightToWork'
  | 'coverLetterUrl'
  | 'rightToWorkDescription'
  | 'educationPhilosophyKeyUpdatedAt'
  | 'coverLetterKeyUpdatedAt'
>;

const ProfileWorkProfile: React.FC<ProfileWorkProfileProps> = (props) => {
  const {
    className,
    educationPhilosophyUrl,
    videoUrl,
    rightToWork,
    startDate,
    coverLetterUrl,
    hasRightToWork,
    rightToWorkDescription,
    coverLetterKeyUpdatedAt,
    educationPhilosophyKeyUpdatedAt,
  } = props;

  const [iframeLoaded, setIframeLoaded] = useState(false);

  const { t } = useTranslation();

  const getEmbedId = useCallback(() => {
    let ID = '';
    const tempUrl = videoUrl
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (tempUrl[2] !== undefined) {
      ID = tempUrl[2].split(/[^0-9a-z_-]/i)[0];
    } else {
      ID = videoUrl;
    }
    return ID;
  }, [videoUrl]);

  const baseClass = 'searchality-profile-work-profile';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <ProfilePreviewPdfSection
        title={t('Profile.WorkProfile.educationalStatement')}
        linkText={t('Profile.WorkProfile.link')}
        pdfUrl={educationPhilosophyUrl}
        updatedText={t('updateAt', {
          date: DateService.getUSADateFormat(
            educationPhilosophyKeyUpdatedAt ?? new Date(),
          ),
        })}
      />
      {coverLetterUrl && (
        <ProfilePreviewPdfSection
          title={t('Profile.WorkProfile.coverLetter')}
          linkText={t('Profile.WorkProfile.coverLetterLink')}
          pdfUrl={coverLetterUrl}
          updatedText={t('updateAt', {
            date: DateService.getUSADateFormat(
              coverLetterKeyUpdatedAt ?? new Date(),
            ),
          })}
        />
      )}
      <hr />
      <div className="profile-section">
        <div className="profile-section__title">
          <p>{t('Profile.WorkProfile.introductionVideo')}</p>
        </div>
        {videoUrl ? (
          <div className="iframe-video-wrapper">
            <iframe
              title="Introduction Video"
              src={`https://www.youtube.com/embed/${getEmbedId()}`}
              className={classNames({ loading: !iframeLoaded })}
              onLoad={() => setIframeLoaded(true)}
            />
          </div>
        ) : (
          <p className="no-data">{t('noData')}</p>
        )}
      </div>
      <hr />
      <ProfileWorkAvailabilitySection
        startDate={startDate}
        hasRightToWork={hasRightToWork}
        rightToWork={rightToWork}
        rightToWorkDescription={rightToWorkDescription}
      />
    </div>
  );
};

export default ProfileWorkProfile;
