import { ComplexRoute } from 'router/components/Routes/Routes';
import Teachers from './pages/Teachers';
import TeacherProfile from './subrouters/TeacherProfile/TeacherProfile.router';
import TeacherProfileRoutes from './subrouters/TeacherProfile/TeacherProfile.routes';

export default [
  {
    path: ':teacherId',
    element: TeacherProfile,
    routes: TeacherProfileRoutes,
  },
  {
    index: true,
    element: Teachers,
  },
] as Array<ComplexRoute>;
