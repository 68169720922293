import classNames from 'classnames';
import TabsNavigation from 'components/TabsNavigation';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import SchoolGroupHeader from '../components/SchoolGroupHeader/SchoolGroupHeader.component';
import useSchoolSettingsTabs from './hooks/useSchoolSettingsTabs';

import './SchoolGroupSettings.styles.scss';

const SchoolGroupSettings: React.FC = () => {
  const { schoolId } = useParams();
  const tabs = useSchoolSettingsTabs();

  const dontShowTabs = !!schoolId;

  return (
    <>
      {!dontShowTabs && <SchoolGroupHeader />}
      <div className={classNames({ 'settings-wrapper': !dontShowTabs })}>
        {!dontShowTabs && <TabsNavigation tabs={tabs} />}
        <Outlet />
      </div>
    </>
  );
};

export default SchoolGroupSettings;
