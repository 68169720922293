import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetTeacherApplicationsQuery } from 'api/teachers.api';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import VacancyCard from 'router/subrouters/Dashboard/components/VacancyCard';
import Pagination from 'components/Pagination';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';

import './TeacherApplications.styles.scss';

type TeacherApplicationsProps = {
  className?: string;
};

const TeacherApplications: React.FC<TeacherApplicationsProps> = (props) => {
  const { className } = props;

  const { teacherId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useAppSelector(
    ({ teacherApplicationsParams }) => teacherApplicationsParams,
  );

  const { t } = useTranslation();

  const { data, isLoading } = useGetTeacherApplicationsQuery({
    teacherId,
    params,
  });

  const classes = classNames('teacher-applications', className);

  if (isLoading) {
    return <PageLoader />;
  }

  const { items, ...pagination } = data;

  return (
    <div className={classes}>
      {!items.length && <p>{t('userDoentHaveApplications')}</p>}
      <ul>
        {items?.map((application) => (
          <VacancyCard
            key={application?._id}
            {...application?.vacancy}
            applicationCreatedAt={application?.createdAt}
            applicationSchools={application?.schools}
            onClick={() =>
              navigate(
                `/school-groups/${application?.vacancy?.schoolGroupId}/vacancies/${application?.vacancy?._id}/candidates`,
              )
            }
          />
        ))}
      </ul>
      {pagination?.totalPages > 1 && (
        <Pagination
          onChange={(pageNumber) =>
            dispatch(
              paramsActions.changeCurrentPage(
                'teacherApplicationsParams',
                pageNumber,
              ),
            )
          }
          {...pagination}
        />
      )}
    </div>
  );
};

export default TeacherApplications;
