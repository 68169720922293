import classNames from 'classnames';
import PhotoPreview from 'components/PhotoPreview';
import { Candidate } from 'models/Candidate';
import React, { useMemo } from 'react';
import DateService from 'services/Date.service';
import { getNameInitials } from 'utils';

import './CandidateCard.styles.scss';

export type CandidateCardProps = {
  isActive?: boolean;
  onClick?: () => void;
  suffixNode?: React.ReactNode;
} & Pick<
  Candidate,
  | '_id'
  | 'statusInSchool'
  | 'firstName'
  | 'lastName'
  | 'imageFile'
  | 'vacancy'
  | 'createdAt'
  | 'color'
>;

const CandidateCard: React.FC<CandidateCardProps> = ({
  statusInSchool,
  firstName,
  lastName,
  imageFile,
  createdAt,
  color,
  isActive = false,
  onClick,
  suffixNode,
}) => {
  const baseClassname = 'searchality-candidate-card';

  const imageFileSrc = useMemo(() => {
    if (!imageFile) return null;
    const imageSrc128 = imageFile.variants.find((v) => v.width === 128);
    if (imageSrc128) {
      return imageSrc128.url;
    }
    return imageFile.url;
  }, [imageFile]);

  const classes = classNames(baseClassname, {
    [`${baseClassname}--active`]: isActive,
    [`${baseClassname}--clickable`]: !!onClick,
  });

  return (
    <li className={classes} onClick={onClick}>
      <PhotoPreview
        src={imageFileSrc}
        placeHolderText={getNameInitials(firstName, lastName)}
        backgroundColor={color}
      />
      <section className={`${baseClassname}__info`}>
        <h1>
          {firstName}&nbsp;{lastName}
        </h1>
        <span className={`${baseClassname}__info__applied`}>
          <p>Applied: &nbsp;</p>
          <p>{DateService.toDateString(createdAt)}</p>
        </span>

        <span className={`${baseClassname}__info__status`}>
          {statusInSchool}
        </span>
      </section>
      {suffixNode && (
        <section className={baseClassname + '__right'}>{suffixNode}</section>
      )}
    </li>
  );
};

export default CandidateCard;
