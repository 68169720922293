import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button, Input, Modal, RadioGroup } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { useTranslation } from 'react-i18next';
import useMethodOptions from './hooks/useMethodOptions';
import { TwoFactorAuthenticationMethod } from 'types';
import { useGenerate2FAMutation, useTurnOn2FAMutation } from 'api/auth.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import credentialsService from 'services/credentialsService';

import './Enable2FaModal.styles.scss';

type Enable2FaModalProps = {
  className?: string;
};

const Enable2FaModal: React.ForwardRefRenderFunction<
  ModalRef,
  Enable2FaModalProps
> = (props, ref) => {
  const { className } = props;

  const [method, setMethod] = useState<TwoFactorAuthenticationMethod>();
  const [isMethodChosen, setIsMethodChosen] = useState(false);
  const [code, setCode] = useState('');

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const dispatch = useAppDispatch();
  const [generate2fa, { data: imgSrc }] = useGenerate2FAMutation();
  const [turnOn2fa] = useTurnOn2FAMutation();

  const { t } = useTranslation();

  useImperativeHandle(ref, () => modal, [modal]);

  const options = useMethodOptions();

  const classes = classNames('enable2-fa-modal', className);

  const onMethodChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;

      setMethod(value as TwoFactorAuthenticationMethod);
    },
    [],
  );

  const initiate2fa = useCallback(() => {
    generate2fa(method)
      .unwrap()
      .then((res) => {
        setIsMethodChosen(true);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [generate2fa, method]);

  const handleTurnOn2fa = useCallback(() => {
    turnOn2fa({ code, method })
      .unwrap()
      .then((res) => {
        dispatch(popSuccess(t('successInitiate2fa')));
        credentialsService.saveAuthBody(res);
        setIsMethodChosen(false);
        setMethod(undefined);
        setCode('');
        modal.close();
      })
      .catch((e) => dispatch(popServerError(e)));
  }, [code, dispatch, method, modal, t, turnOn2fa]);

  return (
    <Modal
      ref={modalRef}
      title={!isMethodChosen ? t('select2FAMethod') : t('enter2faInitiate')}
      className={classes}
      footer={
        <>
          <Button variant="outline" onClick={() => modal.close()}>
            {t('cancel')}
          </Button>
          {!isMethodChosen ? (
            <Button disabled={!method} onClick={initiate2fa}>
              {t('initiate2fa')}
            </Button>
          ) : (
            <Button onClick={handleTurnOn2fa} disabled={!code}>
              {t('submit')}
            </Button>
          )}
        </>
      }
    >
      {!isMethodChosen ? (
        <RadioGroup
          name="method"
          options={options}
          onChange={onMethodChange}
          value={method}
        />
      ) : (
        <>
          {method === TwoFactorAuthenticationMethod.AUTHENTICATOR_APP && (
            <img src={imgSrc} alt="2fa-qr" />
          )}
          <Input
            innerLabel={t('enter2FACode')}
            value={code}
            onChange={({ target: { value } }) => setCode(value)}
          />
        </>
      )}
    </Modal>
  );
};

export default forwardRef(Enable2FaModal);
