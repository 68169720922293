import React, { useCallback, useMemo } from 'react';
import GoBackButton from 'components/GoBackButton';
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate, useParams as useUrlParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import SchoolProfileCard from './components/SchoolProfileCard';
import confirm from 'modules/confirm';
import useAppDispatch from 'hooks/useAppDispatch';
import PageLoader from 'components/PageLoader';
import DateService from 'services/Date.service';
import { PaginatedResponse, Params } from 'types';
import Pagination from 'components/Pagination';
import { User } from 'models/User';
import {
  useDeleteSchoolGroupSchoolMutation,
  useGetSchoolAccountQuery,
  useGetSchoolAccountVacanciesQuery,
  useGetSchoolGroupMembersQuery,
} from 'api/schoolGroups.api';
import usePublicSchoolType from './hooks/usePublicSchoolType';
import MembersTable from '../../../SchoolGroupMembers/components/MembersTable';
import paramsActions from 'store/actions/params.actions';

import './SchoolProfile.styles.scss';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

const SchoolProfile: React.FC = () => {
  const { schoolId, schoolGroupId } = useUrlParams();

  const [deleteSchoolGroupSchool] = useDeleteSchoolGroupSchoolMutation();
  const navigate = useNavigate();

  const vacanciesParams = {
    $where: {
      schoolIds: { $all: [schoolId] },
      status: { $in: ['Ongoing'] },
    },
  } as Params;
  const { data: onGoingVacancies, isLoading } =
    useGetSchoolAccountVacanciesQuery({
      ...vacanciesParams,
      schoolGroupId,
    });

  const params = useAppSelector(({ schoolGroupMembers }) => schoolGroupMembers);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { data: schoolGroup, isLoading: isLoadingSchoolGroup } =
    useGetSchoolAccountQuery(schoolGroupId);

  const publicSchoolType = usePublicSchoolType();

  const { data } = useGetSchoolGroupMembersQuery({
    params: {
      ...params,
      $where: {
        schoolIds: { $in: [schoolId] },
      },
    },
    schoolGroupId,
  });

  const { items: members, ...paginationProps } = data || {};

  const school = useMemo(
    () => schoolGroup?.schools?.find((el) => el._id === schoolId),
    [schoolGroup?.schools, schoolId],
  );

  const handleOngoingVacancies = useCallback(async () => {
    await confirm({
      title: t('RemoveSchoolModalTitleOngoing.title'),
      content: <p>{t('RemoveSchoolModalTitleOngoing.description')}</p>,
      cancelBtnText: t('RemoveSchoolModalTitleOngoing.cancel'),
      showConfirm: false,
    });
  }, [t]);

  const handleDeleteSchool = useCallback(async () => {
    deleteSchoolGroupSchool({ schoolId })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('deleteSchoolSuccess')));
        navigate('..');
      })
      .catch((e) => dispatch(popServerError(e)));
  }, [deleteSchoolGroupSchool, dispatch, navigate, schoolId, t]);

  const handleConfirmationModal = useCallback(async () => {
    await confirm({
      title: t('removeSchoolModal.title', {
        schoolType: publicSchoolType,
      }),
      content: (
        <p>
          {schoolGroup?.subscriptionEndDate
            ? t('removeSchoolModal.descriptionWithPrice', {
                date: DateService.getUSADateFormatLong(
                  DateService.getDayBefore(schoolGroup?.subscriptionEndDate),
                ),
              })
            : t('removeSchoolModal.description')}
        </p>
      ),
      confirmBtnText: t('removeSchoolModal.buttonText'),
      onSubmit: () => {
        handleDeleteSchool();
      },
    });
  }, [
    handleDeleteSchool,
    publicSchoolType,
    schoolGroup?.subscriptionEndDate,
    t,
  ]);

  const baseClass = 'school-profile';

  if (isLoading || isLoadingSchoolGroup) {
    return <PageLoader />;
  }

  return (
    <div className={baseClass}>
      <GoBackButton />
      <h1>{school?.name}</h1>
      <p className={`${baseClass}__description`}>{t('billingInformation')}</p>
      <SchoolProfileCard schoolId={school?._id} />
      <Button
        variant="outline"
        className={`${baseClass}__remove-button`}
        onClick={() => {
          !(onGoingVacancies?.totalItems > 0)
            ? handleConfirmationModal()
            : handleOngoingVacancies();
        }}
      >
        {t('removeSchoolFromPlan')}
      </Button>
      <div className={`${baseClass}__members`}>
        <p className={`${baseClass}__members__title`}>
          {t('membersAndPermissions')}
        </p>
        <p className={`${baseClass}__members__sub`}>
          {t('membersAndPermissionsSub')}
        </p>
        <MembersTable
          members={members}
          className={`${baseClass}__members__table`}
          schoolName={school?.name}
          isReadonly
        />
        {(paginationProps as PaginatedResponse<User>)?.totalPages > 1 && (
          <Pagination
            onChange={(pageNumber) =>
              dispatch(
                paramsActions.changeCurrentPage(
                  'schoolGroupMembers',
                  pageNumber,
                ),
              )
            }
            {...(paginationProps as PaginatedResponse<User>)}
          />
        )}
      </div>
    </div>
  );
};

export default SchoolProfile;
