import React, { useContext } from 'react';
import Pagination from 'components/Pagination';
import PageLoader from 'components/PageLoader';
import PaginationInfo from 'components/PaginationInfo';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetVacancyCandidatesQuery } from 'api/schoolGroups.api';
import ValueContext from 'providers/ValueContext/Value.context';
import CandidateCardWrapper from '../CandidateCard/components/CandidateCardWrapper';
import useAppSelector from 'hooks/useAppSelector';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';

import './VacancyFillFactory.styles.scss';
import CandidatesFilters from '../CandidatesFilters';

const VacancyFillFactory: React.FC = (props) => {
  const { t } = useTranslation();

  const { vacancyId, schoolGroupId } = useParams();
  const dispatch = useAppDispatch();

  const { selectedIds, manageId } = useContext(ValueContext);

  const params = useAppSelector((state) => state.schoolGroupVacancyCandidates);

  const { isLoading, data, isFetching } = useGetVacancyCandidatesQuery(
    { vacancyId, schoolGroupId, params },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  if (isLoading || isFetching) {
    return <PageLoader />;
  }

  const { items: candidates, ...pagination } = data;

  return (
    <div className="vacancies-fill-factory">
      <p className="vacancies-fill-factory__description">
        {t('vacancyFillCandidateModalModal.description')}
      </p>
      <CandidatesFilters />
      <PaginationInfo
        name={t('Candidates.candidate', { count: pagination.totalItems })}
        {...pagination}
        limit={params?.$limit}
      />
      <ul>
        {candidates?.map(({ _id, ...restVacancy }) => {
          return (
            <CandidateCardWrapper
              _id={_id}
              key={_id}
              {...restVacancy}
              isActive={_id === selectedIds[0]}
              onClick={() => manageId(_id)}
              additionalContent={
                <input
                  type="radio"
                  onChange={() => manageId(_id)}
                  checked={selectedIds.includes(_id)}
                  style={{ marginRight: '16px' }}
                />
              }
            />
          );
        })}
      </ul>
      {pagination?.totalPages > 1 && (
        <Pagination
          {...pagination}
          onChange={(pageNumber: number) =>
            dispatch(
              paramsActions.changeCurrentPage(
                'schoolGroupVacancyCandidates',
                pageNumber,
              ),
            )
          }
        />
      )}
    </div>
  );
};

export default VacancyFillFactory;
