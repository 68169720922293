import { useParams } from 'react-router-dom';

export default () => {
  const { teacherId } = useParams();

  return [
    {
      to: `/teachers/${teacherId}/profile`,
      label: 'userProfile',
    },
    {
      to: `/teachers/${teacherId}/applications`,
      label: 'userApplications',
    },
    {
      to: `/teachers/${teacherId}/details`,
      label: 'accountDetails',
    },
  ];
};
