import React from 'react';
import { LanguageRequirement } from 'models/Candidate';
import { LanguageLevel } from 'searchality-data';
import { ReactComponent as GlobeIcon } from 'icons/Globe.icon.svg';
import { useTranslation } from 'react-i18next';

type ProfileLanguagesSectionProps = {
  baseClass: string;
  languages: LanguageRequirement[];
  englishLevel: LanguageLevel;
};

const ProfileLanguagesSection: React.FC<ProfileLanguagesSectionProps> = (
  props,
) => {
  const { baseClass, englishLevel, languages } = props;

  const { t } = useTranslation();

  return (
    <div className="profile-section">
      <div className="profile-section__title">
        <GlobeIcon />
        <p>{t('Profile.Languages.languages')}</p>
      </div>
      <div className={`${baseClass}__section`}>
        <p className="language-title">{t('english')}</p>
        <p className="subtitle">{englishLevel ?? t('noData')}</p>
      </div>
      {languages
        ?.filter(({ level }) => level)
        .map(({ language, level }) => (
          <div key={language} className={`${baseClass}__section`}>
            <p className="language-title">{language}</p>
            <p className="subtitle">{level}</p>
          </div>
        ))}
    </div>
  );
};

export default ProfileLanguagesSection;
