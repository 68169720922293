import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type HeightAnimationProps = {
  element: HTMLElement | null | undefined;
  duration?: number;
  minElementHeight?: number;
  open: boolean;
};

export default function useHeightAnimation(props: HeightAnimationProps) {
  const { element, duration = 330, minElementHeight = 0, open } = props;

  const resizeObserverRef = useRef<ResizeObserver>();
  const lastHeightRef = useRef(0);
  const isAnimating = useRef(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const activeAnimationCycle = useRef(0);
  const openRef = useRef(open);

  const classes = useMemo(
    () =>
      classNames('s-collapsible-anim', {
        transitioning: isTransitioning,
        opened: open && !isTransitioning,
      }),
    [isTransitioning, open],
  );

  const animate = useCallback(
    (
      cycleId: number,
      first: number,
      last: number,
      currentTime = Date.now(),
    ) => {
      const diff = last - first;

      if (!diff) return;
      if (!element) return;
      if (cycleId !== activeAnimationCycle.current) return;

      const elapsedTime = Date.now() - currentTime;

      const { current: open } = openRef;
      if (elapsedTime >= duration) {
        if (open) {
          element.removeAttribute('style');
        } else {
          element.style.height = `${last}px`;
        }
        lastHeightRef.current = last;
        isAnimating.current = false;
        resizeObserverRef.current?.observe(element);

        setIsTransitioning(false);

        return;
      } else {
        const ratio = elapsedTime / duration;
        const height = first + ratio * diff;
        lastHeightRef.current = height;
        element.style.height = `${height}px`;
      }

      requestAnimationFrame(() => animate(cycleId, first, last, currentTime));
    },
    [duration, element],
  );

  useEffect(() => {
    if (!element) return;
    const onResize: ResizeObserverCallback = ([entry]) => {
      const first = lastHeightRef.current;
      const [{ blockSize: height }] = entry.borderBoxSize;
      const last = height;
      lastHeightRef.current = height;

      isAnimating.current = true;

      if (Math.abs(first - last) > 1) {
        resizeObserverRef.current?.unobserve(element);
        animate(++activeAnimationCycle.current, first, last);
      }
    };

    if (!resizeObserverRef.current) {
      resizeObserverRef.current = new ResizeObserver(onResize);
      resizeObserverRef.current.observe(element);
    }

    return () => {
      resizeObserverRef.current?.unobserve(element);
    };
  }, [element, animate]);

  useEffect(() => {
    if (!element) return;

    if (!isAnimating.current) {
      if (!open) {
        element.style.height = `${minElementHeight}px`;
      } else {
        element.removeAttribute('style');
      }
    }

    let height = element.clientHeight;

    let first = lastHeightRef.current;
    let last = open ? height : minElementHeight;

    if (isAnimating.current) {
      isAnimating.current = false;
      if (open) {
        first = element.clientHeight;
        element.removeAttribute('style');
        last = element.clientHeight;
        height = first;
        element.style.height = `${first}px`;
        lastHeightRef.current = first;
      }
    }

    const diff = last - first;

    if (diff) {
      resizeObserverRef.current?.unobserve(element);
      isAnimating.current = true;

      setIsTransitioning(true);
      animate(++activeAnimationCycle.current, first, last);
    }
  }, [open, element, duration, animate, minElementHeight]);

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  return { classes } as const;
}
