import React, { ChangeEvent, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Toggle } from 'ncoded-component-library';
import classNames from 'classnames';
import FormFieldLabel from 'components/FormFieldLabel';

import './ToggleField.styles.scss';

export type ToggleFieldProps = FieldRenderProps<boolean, HTMLElement> & {
  label: string;
};

const ToggleField: React.FC<ToggleFieldProps> = (props) => {
  const {
    className,
    label,
    input: { onChange, value },
  } = props;

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;

      onChange(checked);
    },
    [onChange],
  );

  const classes = classNames('toggle-field', className);

  return (
    <div className={classes}>
      {label && <FormFieldLabel text={label} />}
      <Toggle checked={value} onChange={handleOnChange} />
    </div>
  );
};

export default ToggleField;
