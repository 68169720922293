import React, { useCallback } from 'react';
import MemberInfo from '../components/MemberInfo';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { useLoadCurrentUserQuery } from 'api/auth.api';
import DateService from 'services/Date.service';
import { Admin } from 'models/Admin';
import { useDeleteAdminMutation } from 'api/admins.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import confirm from '../../../../../../modules/confirm';
import utils, { getNameInitials } from 'utils';

const useAdminsTableData = (admins: Admin[]) => {
  const { t } = useTranslation();
  const { data } = useLoadCurrentUserQuery();
  const [deleteAdmin] = useDeleteAdminMutation();
  const dispatch = useAppDispatch();

  const { role: userRole, _id: userId } = data || {};

  const handleDelete = useCallback(
    async (id: string) => {
      await confirm({
        title: t('DeleteAdminModal.title'),
        content: <p>{t('DeleteAdminModal.descirption')}</p>,
        onSubmit: () => {
          deleteAdmin(id)
            .unwrap()
            .then(() => dispatch(popSuccess(t('DeleteAdminModal.success'))))
            .catch((e) => dispatch(popServerError(e)));
        },
      });
    },
    [deleteAdmin, dispatch, t],
  );

  return admins?.map(
    ({ _id, role, imageFile, firstName, lastName, email, lastLogin }) => ({
      id: _id,
      data: {
        name: (
          <MemberInfo
            src={imageFile?.url}
            placeholderText={getNameInitials(firstName, lastName)}
            name={utils.trimLongName(`${firstName} ${lastName}`, 35)}
            email={email}
            displayRemaingTime={false}
          />
        ),
        teamPermissions: role,
        lastLogin: lastLogin ? DateService.getUSADateFormat(lastLogin) : '',
        ...((userRole as string) === 'Super admin' && {
          action:
            userId !== _id ? (
              <Button variant="outline" onClick={() => handleDelete(_id)}>
                {t('delete')}
              </Button>
            ) : (
              ''
            ),
        }),
      },
    }),
  );
};

export default useAdminsTableData;
