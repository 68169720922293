import { t } from 'i18next';
import { Candidate } from 'models/Candidate';
import { ProfileCardType } from 'models/ProfileCard.type';
import { User } from 'models/User';
import { Vacancy } from 'models/Vacancy';
import {
  CurriculumExperience,
  RightToWorkType,
  YearsOfExperience,
} from 'searchality-data';
import { JobApplicationFormValues } from 'types/JobApplication';

export function extractProfileInfoFromUser(user: User) {
  const {
    _id,
    firstName,
    lastName,
    email,
    hasRightToWork,
    systemColor,
    startDate,
    educationPhilosophyUrl,
    videoUrl,
    rightToWork,
    education,
    experience,
    curricula,
    sports,
    activities,
    languages,
    englishLevel,
    educationPhilosophyKeyUpdatedAt,
    references,
    imageFile,
    certificates,
    visaType,
    rightToWorkDescription,
    profileStatus,
  } = user;
  return {
    _id,
    firstName,
    lastName,
    email,
    systemColor,
    startDate,
    hasRightToWork,
    educationPhilosophyUrl,
    educationPhilosophyKeyUpdatedAt,
    videoUrl,
    rightToWork,
    education,
    experience,
    curricula,
    sports,
    activities,
    languages,
    englishLevel,
    references,
    certificates,
    visaType,
    rightToWorkDescription,
    profileStatus,
    teacherId: _id,
    imageUrl: imageFile?.url,
  } as unknown as ProfileCardType;
}

export function extractProfileInfoFromJobApplication(
  values: JobApplicationFormValues,
  candidate?: User,
  vacancy?: Vacancy,
) {
  if (JSON.stringify(values) === '{}') return;

  const {
    startDate: jobStartingDate,
    curriculumExperience: vacancyCurricullumExperience,
    educationLevel,
    yearsOfExperienceRequired,
  } = vacancy;

  const {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber: mobilePhone,
      address,
      videoUrl,
      applicantPhoto,
    } = {},

    yourProfile: {
      education,
      experience,
      educationPhilosophy,
      coverLetter,
      resume,
    } = {},
    positionRequirements: {
      languageRequirement: languages,
      rightToWork,
      visaType,
      dateUntilVisaIsValid,
      rightToWorkDescription,
      isAbleToStartOnRequiredDate,
      earliestAvailableStartingDate,
      hasRightToWork,
      yearsOfExperienceWithFaithBasedSchools,
      doesHaveExperinceWithFaithBasedSchools,
      doesHaveExperienceInTheField,
      yearsOfExperienceInTheField,
      englishLevel,
      doesMeetMinimumEducationLevel,
      doesHaveYearsOfExperience,
    } = {},
    references,
  } = values || ({} as JobApplicationFormValues);

  const cardInfo: ProfileCardType = {
    firstName,
    lastName,
    email,
    mobilePhone,
    address,
    videoUrl,
    englishLevel,
    education,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    ...(doesMeetMinimumEducationLevel && { educationLevel }),
    ...(yearsOfExperienceRequired && { yearsOfExperienceRequired }),
    references,
    experience,
    resumeUrl: resume?.length ? URL.createObjectURL(resume[0]) : undefined,
    educationPhilosophyUrl: educationPhilosophy?.length
      ? URL.createObjectURL(educationPhilosophy[0])
      : undefined,
    coverLetterUrl: coverLetter?.length
      ? URL.createObjectURL(coverLetter[0])
      : undefined,
    languages: Object.keys(languages || {}).map((key) => ({
      language: key,
      level: languages[key].level ? languages[key].level : undefined,
      doesSpeak: languages[key].level ? true : false,
    })),
    rightToWork: [rightToWork],
    visaType,
    rightToWorkDescription,
    dateUntilVisaIsValid,
    imageUrl: applicantPhoto?.length
      ? URL.createObjectURL(applicantPhoto[0])
      : undefined,
    systemColor: '',
    startDate: isAbleToStartOnRequiredDate
      ? jobStartingDate ?? null
      : earliestAvailableStartingDate,
    hasRightToWork,
    sports: candidate?.sports ?? undefined,
    activities: candidate?.activities ?? undefined,
    curricula: doesHaveExperienceInTheField
      ? [
          {
            yearsOfExperience: yearsOfExperienceInTheField,
            curriculumExperience:
              vacancyCurricullumExperience as CurriculumExperience,
          },
        ]
      : undefined,
    certificates: candidate?.certificates,
  };

  return cardInfo;
}

export function extractProfileInfoFromCandidate(candidate: Candidate) {
  if (JSON.stringify(candidate) === '{}') return;

  const { vacancy } = candidate || {};

  const {
    startDate,
    curriculumExperience: vacancyCurricula,
    educationLevel,
    yearsOfExperienceRequired,
  } = vacancy || {};

  const {
    _id,
    firstName,
    lastName,
    email,
    educationPhilosophyUrl,
    educationPhilosophyKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
    resumeKeyUpdatedAt,
    coverLetterUrl,
    videoUrl,
    resumeUrl,
    education,
    type,
    earliestAvailableStartingDate,
    isAbleToStartOnRequiredDate,
    experience,
    address,
    references,
    yearsOfExperienceInTheField,
    doesHaveExperienceInTheField,
    doesHaveExperinceWithFaithBasedSchools,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    imageFile,
    rightToWork,
    color,
    updatedAt,
    isFavourite,
    hasRightToWork,
    rightToWorkDescription,
    teacher,
    englishLevel,
    languageRequirement,
  } = candidate;

  const cardInfo: ProfileCardType = {
    firstName,
    _id,
    lastName,
    email,
    videoUrl,
    resumeUrl,
    educationPhilosophyKeyUpdatedAt,
    resumeKeyUpdatedAt,
    coverLetterKeyUpdatedAt,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    type,
    education,
    references,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    experience,
    educationPhilosophyUrl,
    ...(doesMeetMinimumEducationLevel && { educationLevel }),
    ...(yearsOfExperienceRequired && { yearsOfExperienceRequired }),
    coverLetterUrl,
    imageUrl: imageFile?.url,
    address,
    systemColor: color,
    startDate: isAbleToStartOnRequiredDate
      ? startDate
      : earliestAvailableStartingDate,
    rightToWork: rightToWork as RightToWorkType[],
    updatedAt,
    isFavourite,
    curricula: vacancyCurricula
      ? ([
          {
            yearsOfExperience: doesHaveExperienceInTheField
              ? (yearsOfExperienceInTheField?.toString() as YearsOfExperience)
              : (t('haveNoExperience') as YearsOfExperience),
            curriculumExperience: vacancyCurricula,
          },
        ] as any)
      : [],
    hasRightToWork,
    rightToWorkDescription,
    englishLevel,
    languages: languageRequirement,
    sports: teacher?.sports,
    activities: teacher?.activities,
    certificates: teacher?.certificates,
  };

  return cardInfo;
}
