import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewCardItemList } from 'types';
import { numberPriceToCurrencyString } from 'utils';
import {
  SchoolGroupStructureType,
  WEBSITE_INTEGRATION_PRICE,
} from 'searchality-data';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';
import { useSchoolPrices } from './useSchoolPrices';

export default function () {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);
  const { schools, structureType, hasOldPricingSystem } = schoolGroup || {};

  const { t } = useTranslation();

  const { schoolPrice, websiteAdditionalPrice, matchingPrice } =
    useSchoolPrices(structureType, schools, hasOldPricingSystem);

  const list: ReviewCardItemList = useMemo(() => {
    if (schoolGroup?.structureType === SchoolGroupStructureType.STANDALONE) {
      return [
        {
          label: t('standaloneSchool'),
          value: t('standaloneSchoolReviewPrice', {
            price: numberPriceToCurrencyString(schoolPrice),
          }),
          description: t('standaloneSchoolReviewPriceDescription'),
        },
        {
          label: t('websiteIntegration'),
          value: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
          description: t('standaloneSchoolWebsitePriceDescription'),
        },
        {
          label: t('matchingSystem'),
          value: numberPriceToCurrencyString(matchingPrice),
          description: t('standaloneSchoolReviewPriceDescription'),
        },
      ];
    }

    return [
      {
        label: t('publicSchoolGroup'),
        value: t('publicSchoolGroupPrice', {
          price: numberPriceToCurrencyString(schoolPrice),
        }),
        description: t('publicSchoolGroupPriceDescription'),
      },
      {
        label: t('websiteIntegration'),
        value: numberPriceToCurrencyString(
          websiteAdditionalPrice + WEBSITE_INTEGRATION_PRICE,
        ),
        description: t('publicSchoolGroupWebsitePriceDescription'),
      },
      ...(matchingPrice > 0
        ? [
            {
              label: t('matchingSystem'),
              value: numberPriceToCurrencyString(matchingPrice),
              description: t('standaloneSchoolReviewPriceDescription'),
            },
          ]
        : []),
    ];
  }, [
    schoolGroup?.structureType,
    t,
    schoolPrice,
    websiteAdditionalPrice,
    matchingPrice,
  ]);

  return list;
}
