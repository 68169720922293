import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { PaginationProps } from 'types';
import utils from 'utils';
import { Button } from 'ncoded-component-library';
import { ReactComponent as PaginationArrowIcon } from 'icons/PaginationArrow.icon.svg';

import './Pagination.styles.scss';

type PaginationComponentProps = {
  onChange: (value: number) => void;
  className?: string;
} & PaginationProps;

const Pagination: React.FC<PaginationComponentProps> = (props) => {
  const { currentPage, totalPages, onChange, className } = props;

  const paginationButtons = useMemo(
    () =>
      utils
        .getPaginationPages(currentPage, totalPages)
        .map((paginationItem) => {
          if (paginationItem === '...') {
            return <span key={Math.random()}>{paginationItem}</span>;
          }

          return (
            <Button
              key={paginationItem}
              className={classNames('page-button', {
                'page-button--active': currentPage === paginationItem,
              })}
              onClick={() => {
                onChange(paginationItem);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              {paginationItem}
            </Button>
          );
        }),
    [currentPage, totalPages, onChange],
  );

  const baseClass = 'searchality-pagination';
  const classes = classNames(baseClass, className);

  const handleArrowLeft = useCallback(() => {
    onChange(currentPage - 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage, onChange]);

  const handleArrowRight = useCallback(() => {
    onChange(currentPage + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage, onChange]);

  return (
    <div className={classes}>
      <Button
        className="arrow-left"
        disabled={currentPage <= 1}
        onClick={handleArrowLeft}
      >
        {<PaginationArrowIcon />}
      </Button>
      <div className={`${baseClass}__pages`}>{paginationButtons}</div>
      <Button
        className="arrow-right"
        disabled={totalPages === currentPage}
        onClick={handleArrowRight}
      >
        {<PaginationArrowIcon />}
      </Button>
    </div>
  );
};

export default Pagination;
