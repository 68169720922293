import Input from 'components/Input';
import useDebouncedState from 'hooks/useDebouncedState';
import React, { InputHTMLAttributes, useState } from 'react';
import { FormControl } from 'components/Input/Input.component';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';

import './DebouncedInput.styles.scss';
import classNames from 'classnames';

type DebouncedInputProps = FormControl &
  InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean | undefined;
    innerLabel?: string | undefined;
    prefixNode?: React.ReactNode;
    suffixNode?: React.ReactNode;
    onValueChange: (value: string) => void;
    className?: string;
  };

const DebouncedInput: React.FC<DebouncedInputProps> = (props) => {
  const { onValueChange, className, ...rest } = props;

  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useDebouncedState({
    init: '',
    debounceTime: 500,
  });

  useEffectSkipFirst(() => {
    onValueChange(debouncedValue);
  }, [debouncedValue, onValueChange]);

  return (
    <Input
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setDebouncedValue(e.target.value);
      }}
      className={classNames(className, 'debounced-input')}
      {...rest}
    />
  );
};

export default DebouncedInput;
