import { Middleware } from '@reduxjs/toolkit';
import { ParamsActionTypes } from 'store/actions/params.actions';

export const requestParamsMiddleware: Middleware =
  (api) => (next) => (action) => {
    if (Object.values(ParamsActionTypes).includes(action.type)) {
      const { reducerPath, payload } = action;
      const newActionType = `${reducerPath}/${action.type}`;
      next({ type: newActionType, payload });
      return;
    }

    return next(action);
  };

export default requestParamsMiddleware;
