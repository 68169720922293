import classNames from 'classnames';
import useCallbackRef from 'hooks/useCallbackRef';
import useHeightAnimation from 'hooks/useHeightAnimation';
import React, { ReactNode } from 'react';
import { Transition } from 'react-transition-group';

import './Collapse.styles.scss';

type CollapseProps = {
  active: boolean;
  duration?: number;
  children?: ReactNode;
};

const Collapse: React.FC<CollapseProps> = (props) => {
  const { children, active, duration = 250 } = props;

  const [element, ref] = useCallbackRef<any>();

  const { classes } = useHeightAnimation({
    element,
    open: active,
    duration,
  });

  return (
    <Transition in={active} appear unmountOnExit timeout={duration}>
      {(state) =>
        ['exiting', 'entering'].includes(state) ? (
          <div ref={ref} className={classNames(classes, 's-anim-collapse')}>
            {children}
          </div>
        ) : (
          children
        )
      }
    </Transition>
  );
};

export default Collapse;
