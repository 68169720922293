import { createApi } from '@reduxjs/toolkit/query/react';
import { Admin } from 'models/Admin';
import { AuthBody, LoginData, TwoFactorAuthenticationMethod } from 'types';
import utils from 'utils';
import baseQuery from './baseQuery';

export const authApi = createApi({
  reducerPath: 'admin/auth',
  baseQuery,
  tagTypes: ['CurrentUser'],
  endpoints: (builder) => ({
    loginAdmin: builder.query<AuthBody, LoginData>({
      query: ({ password, email }) => {
        return {
          url: `auth/login`,
          method: 'post',
          body: {
            password,
            username: email,
          },
        };
      },
    }),

    loginAdmin2fa: builder.query<AuthBody, { code: string; token: string }>({
      query: ({ code, token }) => {
        return {
          url: 'auth/login/2fa',
          method: 'post',
          body: {
            code,
            token,
          },
        };
      },
    }),

    loadCurrentUser: builder.query<Admin, void>({
      query: () => {
        return {
          url: 'users/current',
          method: 'get',
        };
      },
      providesTags: ['CurrentUser'],
    }),

    forgetPassword: builder.mutation<unknown, { email: string }>({
      query({ email }) {
        return {
          url: 'auth/forgot-password',
          method: 'post',
          body: {
            email,
          },
        };
      },
    }),

    resetPassword: builder.mutation<
      unknown,
      { password: string; token: string }
    >({
      query: ({ password, token }) => {
        return {
          url: 'auth/reset-password',
          method: 'PATCH',
          body: {
            password,
            token,
          },
        };
      },
    }),

    changePassword: builder.mutation<
      unknown,
      { currentPassword: string; newPassword: string }
    >({
      query: ({ currentPassword, newPassword }) => {
        return {
          url: 'auth/change-password',
          method: 'PATCH',
          body: {
            currentPassword,
            newPassword,
          },
        };
      },
    }),

    generate2FA: builder.mutation<string, TwoFactorAuthenticationMethod>({
      query: (twoFactorAuthenticationMethod) => {
        return {
          url: '2fa/generate',
          method: 'post',
          responseHandler: (res) => {
            return res.arrayBuffer().then((arrBuffer) => {
              const blob = utils.arrayBufferToBlob(arrBuffer, 'image/png');

              return URL.createObjectURL(blob);
            });
          },
          body: {
            twoFactorAuthenticationMethod,
          },
        };
      },
    }),

    turnOn2FA: builder.mutation<
      AuthBody,
      { method: TwoFactorAuthenticationMethod; code: string }
    >({
      query: ({ method, code }) => {
        return {
          url: '2fa/turn-on',
          method: 'post',
          body: {
            method,
            code,
          },
        };
      },
      invalidatesTags: ['CurrentUser'],
    }),

    turnOff2FA: builder.mutation<unknown, void>({
      query: () => {
        return {
          url: '2fa/turn-off',
          method: 'post',
        };
      },
      invalidatesTags: ['CurrentUser'],
    }),
  }),
});

export const {
  useLoginAdminQuery,
  useLoadCurrentUserQuery,
  useLazyLoginAdminQuery,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGenerate2FAMutation,
  useTurnOff2FAMutation,
  useTurnOn2FAMutation,
  useLazyLoadCurrentUserQuery,
  useLazyLoginAdmin2faQuery,
} = authApi;
