import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import Error404 from 'router/pages/Error404';
import AdminAuthRouter from './subrouters/Auth/Auth.router';
import authRoutes from './subrouters/Auth/Auth.routes';
import Dashboard from './subrouters/Dashboard/Dashboard.router';
import dashboardRoutes from './subrouters/Dashboard/Dashboard.routes';

export default [
  {
    path: 'auth',
    element: AdminAuthRouter,
    routes: authRoutes,
    onlyPublic: true,
  },
  {
    path: '/',
    element: Dashboard,
    routes: dashboardRoutes,
    authorized: true,
  },
  {
    index: true,
    element: <Navigate to="auth" replace />,
  },
  {
    path: '*',
    element: <Error404 />,
  },
] as Array<ComplexRoute>;
