import React from 'react';
import PhotoPreview from 'components/PhotoPreview';
import { useParams } from 'react-router-dom';
import MembersActions from './components/MemberActions';
import { Role } from 'searchality-data';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { getNameInitials } from 'utils';
import { useGetSchoolMemberQuery } from 'api/schoolGroups.api';

import './MemberInformation.styles.scss';

const MemberInformation: React.FC = () => {
  const { memberId, schoolGroupId } = useParams();
  const { t } = useTranslation();
  const { data: member } = useGetSchoolMemberQuery({ schoolGroupId, memberId });

  const baseClass = 'member-information';

  if (!member) return <></>;

  const { imageFile, firstName, lastName, systemColor, invitedAt, createdAt } =
    member;

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__left`}>
        <PhotoPreview
          src={imageFile?.url}
          placeHolderText={getNameInitials(firstName, lastName)}
          backgroundColor={systemColor}
        />
        <div className={`${baseClass}__data`}>
          <h3>
            {firstName} {lastName}
          </h3>
          <span className={`${baseClass}__data__bullet-span`}>
            <strong>{t('invitedBy')}</strong>
            {Role.AccountOwner}
          </span>
          <span>
            <strong>{`${t('joined')}`}</strong>
            {DateService.getDayMonthYear(invitedAt || createdAt)}
          </span>
        </div>
      </div>

      <div className={`${baseClass}__right`}>
        <MembersActions />
      </div>
    </div>
  );
};

export default MemberInformation;
