import { useGetTeacherQuery } from 'api/teachers.api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const usePersonalInformationList = () => {
  const { teacherId } = useParams();
  const { data } = useGetTeacherQuery(teacherId);

  const { firstName, lastName, streetAddress, city, state, postcode } =
    data || {};

  const { t } = useTranslation();

  return [
    {
      value: `${firstName} ${lastName}`,
      label: t('nameAndSurname'),
    },
    {
      value: streetAddress,
      label: t('address'),
    },
    {
      value: `${city ?? 'N/A'} - ${state ?? 'N/A'}`,
      label: t('cityAndState'),
    },
    {
      value: postcode,
      label: t('postcode'),
    },
  ];
};

export default usePersonalInformationList;
