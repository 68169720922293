import { useGetSchoolAccountVacanciesQuery } from 'api/schoolGroups.api';
import Pagination from 'components/Pagination';
import PaginationInfo from 'components/PaginationInfo';
import useAppSelector from 'hooks/useAppSelector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import VacancyCard from 'router/subrouters/Dashboard/components/VacancyCard';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';
import VacanciesFilters from '../../components/VacanciesFilters';
import { PaginatedResponse } from 'types';
import { Vacancy } from 'models/Vacancy';
import SchoolGroupHeader from '../../../components/SchoolGroupHeader/SchoolGroupHeader.component';
import PageLoader from 'components/PageLoader';

const SchoolGroupVacancies: React.FC = () => {
  const { t } = useTranslation();
  const params = useAppSelector((state) => state.schoolGroupVacancies);
  const { schoolGroupId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, isLoading } = useGetSchoolAccountVacanciesQuery({
    ...params,
    schoolGroupId,
  });

  const { items, ...pagination } = data || ({} as PaginatedResponse<Vacancy>);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <SchoolGroupHeader />
      <div>
        <VacanciesFilters reducerPath="schoolGroupVacancies" />
        <PaginationInfo
          name={t('SchoolGroupVacancies.vacancy', {
            count: pagination?.totalItems,
          })}
          {...pagination}
          limit={params?.$limit}
        />
        <ul>
          {items?.map((vacancy) => (
            <VacancyCard
              onClick={() => navigate(`${vacancy?._id}/candidates`)}
              key={vacancy._id}
              {...vacancy}
              noActions
            />
          ))}
        </ul>
        {pagination?.totalPages > 1 && (
          <Pagination
            onChange={(pageNumber) =>
              dispatch(
                paramsActions.changeCurrentPage(
                  'schoolGroupVacancies',
                  pageNumber,
                ),
              )
            }
            {...pagination}
          />
        )}
      </div>
    </>
  );
};

export default SchoolGroupVacancies;
