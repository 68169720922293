import React from 'react';
import { ComplexRoute } from 'router/components/Routes/Routes';
import ChangePassword from './pages/ChangePassword';
import MyAccountPage from './pages/MyAccount';

export default [
  {
    path: 'change-password',
    element: ChangePassword,
  },
  {
    index: true,
    element: <MyAccountPage />,
  },
] as Array<ComplexRoute>;
