import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { states } from 'constants/index';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SchoolGroupStructureType } from 'searchality-data';
import { ReviewCardItemList } from 'types';

export const schoolTypeLabelMapping: { [name: string]: string } = {
  'public school': 'publicSchool',
  charter: 'charterSchool',
  independent: 'independentSchool',
};

export default function () {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const {
    type: schoolType,
    structureType,
    name,
    website,
    county,
    state,
  } = schoolGroup || {};

  const { t } = useTranslation();

  const list: ReviewCardItemList = useMemo(() => {
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      return [
        {
          label: t(schoolTypeLabelMapping[schoolType]),
          value: t('standalone'),
        },
      ];
    }

    if (schoolType === 'independent' || schoolType === 'charter') {
      return [
        {
          label: t('name'),
          value: name,
        },
        {
          label: t('website'),
          value: website,
        },
        {
          label: t('state'),
          value: states[state as keyof typeof states],
        },
      ];
    }

    return [
      {
        label: t('districtGroupName'),
        value: name,
      },
      {
        label: t('districtGroupWebsite'),
        value: website,
      },
      {
        label: t('state'),
        value: states[state as keyof typeof states],
      },
      {
        label: t('county'),
        value: county?.split(',')?.[0],
      },
    ];
  }, [county, name, schoolType, state, structureType, t, website]);

  return list;
}
