import React from 'react';
import classNames from 'classnames';

import './ProfileTabs.styles.scss';

export type ProfileTab = {
  label: string;
  onClick: () => void;
  key: string;
};

type ProfileTabsProps = {
  className?: string;
  tabs: ProfileTab[];
  activeTab?: string;
};

const ProfileTabs: React.FC<ProfileTabsProps> = (props) => {
  const { className, tabs, activeTab } = props;

  const classes = classNames('searchality-profile-tabs', className);

  return (
    <div className={classes}>
      {tabs?.map(({ label, onClick, key }) => (
        <button
          key={label}
          onClick={onClick}
          type="button"
          className={classNames('svg-button-wrapper', {
            'active-tab': key === activeTab,
          })}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default ProfileTabs;
