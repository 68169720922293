import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import AssociationAccount from './pages/AssociationAccount';
import AssociationMembers from './pages/AssociationMembers';
import AssociationSchools from './pages/AssociationSchools';
import EditAssociation from './pages/EditAssociation';

export default [
  {
    path: 'schools',
    element: AssociationSchools,
  },
  {
    path: 'members',
    element: AssociationMembers,
  },
  {
    path: 'account',
    element: AssociationAccount,
  },
  {
    path: 'edit-association',
    element: EditAssociation,
  },
  {
    index: true,
    element: <Navigate to={'schools'} replace />,
  },
] as Array<ComplexRoute>;
