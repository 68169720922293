import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';
import useAssociationInformationList from '../../hooks/useAssociationInformationList';
import { useParams } from 'react-router-dom';
import {
  useAssociationTestFlagMutation,
  useCreateAssociationOwnerMutation,
  useGetAssociationOwnerQuery,
  useGetAssociationQuery,
  useSendInvitationLinkMutation,
  useUpdateAssociationOwnerMutation,
} from 'api/associations.api';
import PageLoader from 'components/PageLoader';
import useContantPersonInformation from '../../hooks/useContantPersonInformation';
import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { composeValidators, emailValidation, required } from 'validations';
import PhoneNumberField from 'components/PhoneNumberField';
import { CreateOwnerForm } from 'models/Association';
import { FormApi } from 'final-form';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import ToggleField from 'components/ToggleField';

import './AssociationAccount.styles.scss';

type AssociationAccountProps = {
  className?: string;
};

const AssociationAccount: React.FC<AssociationAccountProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const { associationId } = useParams();
  const modalRef = useRef<ModalRef>();

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetAssociationQuery(associationId);
  const {
    data: owner,
    isLoading: isOwnerLoading,
    refetch,
  } = useGetAssociationOwnerQuery(associationId);

  const [createOwner] = useCreateAssociationOwnerMutation();
  const [updateOwner] = useUpdateAssociationOwnerMutation();
  const [sendInvitationLink] = useSendInvitationLinkMutation();
  const [testFlagUpdate] = useAssociationTestFlagMutation();

  const { isForTesting } = data || {};

  const handleisForTesting = useCallback(
    async ({ isForTesting }: { isForTesting: boolean }) => {
      await testFlagUpdate({
        associationId,
        isForTesting,
      })
        .unwrap()
        .then(() => dispatch(popSuccess(t('changedAccTypeSuccess'))))
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, associationId, t, testFlagUpdate],
  );

  const list = useAssociationInformationList(data);

  const contactPersonList = useContantPersonInformation(owner);

  const ownerInitialValues = useMemo(() => {
    if (!owner)
      return {
        mobilePhone: {
          countryCode: '+1',
          phoneNumber: undefined,
        },
      };

    const { lastName, firstName, email, mobilePhone, title } = owner;

    return {
      lastName,
      firstName,
      title,
      email,
      mobilePhone: mobilePhone ?? {
        countryCode: '+1',
        phoneNumber: undefined,
      },
    };
  }, [owner]);

  const handleAddOwner = useCallback(
    async (
      values: CreateOwnerForm,
      form: FormApi<CreateOwnerForm, Partial<CreateOwnerForm>>,
    ) => {
      if (form.getState().pristine) return;

      const { mobilePhone, ...rest } = values || {};
      let body = values;

      if (!mobilePhone?.phoneNumber) {
        body = rest as CreateOwnerForm;
      }

      await createOwner({ body, associationId })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('successfullyAddedOwner')));
          refetch();
          form.reset();
          modalRef.current.close();
        })
        .catch(() => {
          t('addingOwnerFail');
        });
    },
    [associationId, createOwner, dispatch, refetch, t],
  );

  const handleUpdateOwner = useCallback(
    async (
      values: CreateOwnerForm,
      form: FormApi<CreateOwnerForm, Partial<CreateOwnerForm>>,
    ) => {
      if (form.getState().pristine) return;

      const { mobilePhone, ...rest } = values || {};
      let body = values;

      if (!mobilePhone?.phoneNumber) {
        body = rest as CreateOwnerForm;
      }

      await updateOwner({ body, userId: owner?._id })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('successfullyUpdatedOwner')));
          form.reset();
          modalRef.current.close();
        })
        .catch(() => {
          t('ownerUpdateFail');
        });
    },
    [dispatch, owner?._id, t, updateOwner],
  );
  const handleEditOwner = useCallback(() => {
    modalRef?.current?.open();
  }, []);

  const handleSendInvitationLink = useCallback(async () => {
    await sendInvitationLink({ associationId })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('registrationEmailSent')));
        modalRef?.current?.close();
      })
      .catch(() => {
        t('registrationEmailFail');
      });
  }, [associationId, dispatch, sendInvitationLink, t]);

  if (isLoading || isOwnerLoading) return <PageLoader />;

  const classes = classNames('association-account', className);

  return (
    <div className={classes}>
      <ReviewCardWrapper list={list} title={t('associationInformation')} />
      {owner ? (
        <ReviewCardWrapper
          list={contactPersonList}
          title={t('contactPersonInformation')}
          onEdit={handleEditOwner}
        />
      ) : (
        <div className="association-account__add-owner">
          <p>{t('currentlyNoOwnerForAssociation')}</p>
          <Button onClick={() => modalRef?.current.open()}>
            {t('addOwnerForAssociation')}
          </Button>
        </div>
      )}
      <Form<CreateOwnerForm>
        onSubmit={owner ? handleUpdateOwner : handleAddOwner}
        initialValues={ownerInitialValues}
        render={({ handleSubmit, submitting, invalid, pristine }) => (
          <Modal
            ref={modalRef}
            title={t('addOwnerForAssociation')}
            renderAsPortal={false}
            footer={
              <>
                <Button
                  variant="outline"
                  disabled={invalid || submitting || pristine}
                  onClick={handleSubmit}
                >
                  {t(owner ? 'updateOwner' : 'addOwner')}
                </Button>
                <Button
                  onClick={async () => {
                    await handleSubmit();
                    handleSendInvitationLink();
                  }}
                  disabled={invalid || submitting}
                >
                  {t('sendInvitationLink')}
                </Button>
              </>
            }
          >
            <form>
              <div>
                <Field
                  name="firstName"
                  component={InputField}
                  label={t('firstName')}
                  validate={required()}
                  required
                />
                <Field
                  name="lastName"
                  component={InputField}
                  label={t('lastName')}
                  validate={required()}
                  required
                />
              </div>
              <Field
                name="email"
                component={InputField}
                label={t('email')}
                validate={composeValidators(required(), emailValidation())}
                required
              />
              <div>
                <Field
                  name="title"
                  component={InputField}
                  label={t('title')}
                  validate={required()}
                  required
                />
                <div className={`association-account__phone-number`}>
                  <PhoneNumberField
                    name="mobilePhone"
                    label={t('phoneNumberOptional')}
                    renderAsPortal
                  />
                </div>
              </div>
            </form>
          </Modal>
        )}
      />
      <div className="association-account__account-type">
        <h5>{t('accountType')}</h5>
        <Form
          onSubmit={handleisForTesting}
          initialValues={{ isForTesting: !!isForTesting }}
          render={({ handleSubmit, dirty, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="isForTesting"
                component={ToggleField}
                label={t('isForTesting')}
              />
              {dirty && (
                <Button type="submit" disabled={submitting}>
                  {t('changeAssociationType')}
                </Button>
              )}
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default AssociationAccount;
