import React, { useState } from 'react';
import InputField, {
  InputFieldProps,
} from 'components/InputField/InputField.component';
import { ReactComponent as EyeIcon } from 'icons/Eye.icon.svg';
import classNames from 'classnames';

import './PasswordField.styles.scss';
import './PasswordField.styles.responsive.scss';

const PasswordField: React.FC<InputFieldProps> = (props) => {
  const [type, setType] = useState<'text' | 'password'>('password');

  const classes = classNames('password-field', props.className);

  return (
    <InputField
      {...props}
      className={classes}
      type={type}
      suffixNode={
        <button
          type="button"
          onClick={() => {
            setType((old) => (old === 'text' ? 'password' : 'text'));
          }}
        >
          <EyeIcon />
        </button>
      }
    />
  );
};

export default PasswordField;
