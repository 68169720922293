import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useCallbackRef from 'hooks/useCallbackRef';
import { Field, Form } from 'react-final-form';
import TextAreaField from 'components/TextAreaField';
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { ApplicationSchoolStatus } from 'searchality-data';
import {
  useGetCandidateQuery,
  useUpdateCandidateMutation,
} from 'api/schoolGroups.api';
import CheckboxField from 'components/CheckboxField';

import './RejectCondidateModal.styles.scss';

type RejectCandidateValues = {
  message: string;
  skipNotification?: boolean;
};

type RejectCondidateModalProps = {
  className?: string;
};

const RejectCondidateModal: ForwardRefRenderFunction<
  ModalRef,
  RejectCondidateModalProps
> = (props, ref) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const [updateCandidate] = useUpdateCandidateMutation();

  const { t } = useTranslation();
  const { schoolGroupId, candidateId } = useParams();

  const { data: candidate } = useGetCandidateQuery({
    schoolGroupId,
    candidateId,
  });

  const { firstName, schools, vacancy } = candidate || {};

  const schoolName = schools?.map(({ name }) => name).join('/');

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const classes = classNames('reject-condidate-modal', className);

  const validate = useCallback(
    (values: RejectCandidateValues) => {
      const { skipNotification, message } = values;
      if (skipNotification) return {};

      if (!message) {
        return { message: t('requiredField') };
      }
    },
    [t],
  );

  const handleSubmit = useCallback(
    async (values: RejectCandidateValues) => {
      const { message, skipNotification } = values;

      updateCandidate({
        candidateId,
        schoolGroupId,
        message,
        skipNotification,
        status: ApplicationSchoolStatus.REJECTED,
      })
        .unwrap()
        .then(() => {
          modal.close();
          dispatch(popSuccess(t('applicationRejected')));
        })
        .catch((error) => dispatch(popServerError(error.message)));
    },
    [candidateId, dispatch, modal, schoolGroupId, t, updateCandidate],
  );

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('Profile.RejectCandidateModal.title')}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          skipNotification: false,
          saveCandidate: false,
          message: `${t('Profile.RejectCandidateModal.initial1', {
            firstName,
          })}${schoolName}${t(
            'Profile.RejectCandidateModal.initial2',
          )}${schoolName}${t('Profile.RejectCandidateModal.initial3', {
            firstName,
            positionTitle: vacancy?.positionTitle,
          })}`,
        }}
        validate={validate}
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <p>{t('Profile.RejectCandidateModal.description')}</p>
            <Field name="message" component={TextAreaField} />
            <Field
              name="skipNotification"
              component={CheckboxField}
              type="checkbox"
              isStyledDefault
              label={t('Profile.RejectCandidateModal.checkboxLabel')}
            />
            <div className="actions">
              <Button variant="outline" onClick={() => modal.close()}>
                {t('cancel')}
              </Button>
              <Button disabled={submitting || invalid} type="submit">
                {t('Profile.RejectCandidateModal.confirm')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default forwardRef(RejectCondidateModal);
