import { DateTime } from 'luxon';
type PastTime = {
  num: number;
  type: 'month' | 'day' | 'hour' | 'min' | 'sec';
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default {
  fullShorterDate: (date: string) =>
    DateTime.fromISO(date).toFormat('LLL dd') +
    ' - ' +
    DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE),

  compareDates(date1: string | Date, date2: string | Date) {
    return (
      DateTime.fromJSDate(new Date(date1)) >
      DateTime.fromJSDate(new Date(date2))
    );
  },
  formatDateString(date: string | Date) {
    return DateTime.fromJSDate(new Date(date));
  },
  formatDate(date: string | Date) {
    const { monthLong, day, year } = DateTime.fromJSDate(new Date(date));
    return `${day} ${monthLong} ${year}`;
  },
  substractDatesForInviteExpire(date: string | Date, exprDays = 20) {
    const startDate = DateTime.fromJSDate(new Date(date));

    const diff = DateTime.now().diff(startDate, 'days');

    return Math.round(exprDays - diff.days);
  },

  renewDate(subscriptionEndDate: string | Date) {
    const endDate = DateTime.fromJSDate(new Date(subscriptionEndDate));

    const diff = endDate.diff(DateTime.now(), 'days');

    return Math.floor(diff.days);
  },

  getDayBefore(date: string | Date) {
    return DateTime.fromJSDate(new Date(date)).minus({ days: 1 }).toJSDate();
  },

  toDateString(date: Date | string) {
    return DateTime.fromJSDate(new Date(date)).toLocaleString(
      DateTime.DATE_MED,
    );
  },
  toDateTime(date: string | Date) {
    return DateTime.fromJSDate(new Date(date)).toLocaleString(
      DateTime.DATETIME_MED,
    );
  },
  getDayMonthYear: (date: string | Date) => {
    const { day, monthLong, year } = DateTime.fromJSDate(new Date(date));
    return `${day} ${monthLong} ${year}`;
  },
  getUSADateFormatLong: (date: string | Date) => {
    const { day, monthLong, year } = DateTime.fromJSDate(new Date(date));
    return `${monthLong} ${day}, ${year}`;
  },
  getUSADateFormatLongWithTime: (date: string | Date) => {
    return DateTime.fromJSDate(new Date(date)).toFormat(
      'MMMM dd yyyy, hh:mm a',
    );
  },
  getUSADateFormat: (date: string | Date) => {
    const { day, month, year } = DateTime.fromJSDate(new Date(date));
    return `${month}/${day}/${year}`;
  },
  calculatePastTime(dateStr: string): PastTime {
    const date = new Date(dateStr);
    const currentDate = new Date();

    const timeDiff = Math.abs((date.getTime() - currentDate.getTime()) / 1000);

    return timeDiff > 60 * 60 * 24 * 30
      ? {
          num: parseInt((timeDiff / (60 * 60 * 24 * 30)).toFixed(0)),
          type: 'month',
        }
      : timeDiff > 60 * 60 * 24
      ? { num: parseInt((timeDiff / (60 * 60 * 24)).toFixed(0)), type: 'day' }
      : timeDiff > 60 * 60
      ? { num: parseInt((timeDiff / (60 * 60)).toFixed(0)), type: 'hour' }
      : timeDiff > 60
      ? { num: parseInt((timeDiff / 60).toFixed(0)), type: 'min' }
      : { num: parseInt(timeDiff.toFixed(0)), type: 'sec' };
  },
  getInputUSAFormat: (date: string | Date) => {
    const { day, month, year } = DateTime.fromJSDate(new Date(date));
    return `${month}-${day}-${year}`;
  },
};
