import React from 'react';
import classNames from 'classnames';
import {
  useGetSchoolAccountQuery,
  useGetSchoolGroupMembersQuery,
} from 'api/schoolGroups.api';
import useAppSelector from 'hooks/useAppSelector';
import Pagination from 'components/Pagination';
import { PaginatedResponse } from 'types';
import { User } from 'models/User';
import MembersFilters from '../../components/MembersFilters';
import MembersPaginationInfo from '../../components/MembersPaginationInfo';
import MembersTable from '../../components/MembersTable';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import { useParams } from 'react-router-dom';
import SchoolGroupHeader from '../../../components/SchoolGroupHeader/SchoolGroupHeader.component';
import { useGetSchoolAccountSchoolsQuery } from 'api/schoolGroups.api';
import PageLoader from 'components/PageLoader';
import { SchoolGroupStructureType } from 'searchality-data';

import './SchoolGroupMembers.styles.scss';

type MembersProps = {
  className?: string;
};

const Members: React.FC<MembersProps> = (props) => {
  const { className } = props;
  const dispatch = useAppDispatch();
  const params = useAppSelector(({ schoolGroupMembers }) => schoolGroupMembers);
  const { schoolGroupId } = useParams();

  const { data: { items: schools } = {}, isLoading } =
    useGetSchoolAccountSchoolsQuery(schoolGroupId);

  const { data } = useGetSchoolGroupMembersQuery({
    params,
    schoolGroupId,
  });

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const classes = classNames('members-page', className);

  const { items: members, ...paginationProps } =
    data || ({} as PaginatedResponse<User>);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <SchoolGroupHeader />
      <div className={classes}>
        <MembersFilters className="members-page__filters" />
        <MembersPaginationInfo
          className="members-page__pagination-info"
          numberOfSchools={schools?.length}
          numberOfMembers={members?.length}
          filteredRole={params?.where?.role}
        />
        {!!(paginationProps as PaginatedResponse<User>)?.totalItems && (
          <MembersTable
            className="members-page__table"
            members={members}
            schoolName={
              schoolGroup?.structureType ===
                SchoolGroupStructureType.STANDALONE && schools?.[0]?.name
            }
          />
        )}
        {(paginationProps as PaginatedResponse<User>)?.totalPages > 1 && (
          <Pagination
            onChange={(pageNumber: number) => {
              dispatch(
                paramsActions.changeCurrentPage(
                  'schoolGroupMembers',
                  pageNumber,
                ),
              );
            }}
            {...paginationProps}
          />
        )}
      </div>
    </>
  );
};

export default Members;
