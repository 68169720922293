import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { composeValidators, emailValidation, required } from 'validations';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import MainLayout from 'components/layout/MainLayout';
import AuthSection from '../../components/AuthSection';
import { useForgetPasswordMutation } from 'api/auth.api';

import './ForgotPassword.styles.scss';

type ForgotPasswordProps = {
  className?: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [forgotPassword] = useForgetPasswordMutation();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async ({ email }: { email: string }) => {
      await forgotPassword({ email })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('ForgotPassword.success')));
          navigate(`../login`);
        })
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [dispatch, forgotPassword, navigate, t],
  );

  return (
    <MainLayout className="forgot-password" isAuth>
      <AuthSection className="auth-form">
        <h1>{t('ForgotPassword.forgotPassword')}</h1>
        <p>{t('ForgotPassword.weHaveAllBeenThere')}</p>
        <div className="forgot-password__dont-have-account">
          <p className="descriptive">{t('ForgotPassword.goBackToLogin')}</p>
          <Link to={`../login`}>{t('login')}</Link>
        </div>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, invalid, submitting }) => (
            <form onSubmit={handleSubmit} className="auth-section__form">
              <Field
                name="email"
                component={InputField}
                label={t('email')}
                required
                validate={composeValidators(required(), emailValidation())}
              />
              <Button
                type="submit"
                disabled={invalid || submitting}
                className="forgot-password__submit"
              >
                {t('send')}
              </Button>
            </form>
          )}
        />
      </AuthSection>
    </MainLayout>
  );
};

export default ForgotPassword;
