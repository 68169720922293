import React from 'react';
import classNames from 'classnames';

import './JobPreviewSection.styles.scss';
import './JobPreviewSection.styles.responsive.scss';

type JobPreviewSectionProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  sectionNumber: 1 | 2 | 3;
};

const JobPreviewSection: React.FC<JobPreviewSectionProps> = (props) => {
  const { children, className, sectionNumber, title } = props;

  const baseClass = 'job-preview-section';
  const classes = classNames(baseClass, className);

  return (
    <section className={classes}>
      <div className={`${baseClass}__left`}>
        <p className={`${baseClass}__left__section-number`}>{sectionNumber}</p>
        <p className={`${baseClass}__left__title`}>{title}</p>
      </div>
      <div className={`${baseClass}__right`}>{children}</div>
    </section>
  );
};

export default JobPreviewSection;
