import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './AuthSection.styles.scss';
import './AuthSection.styles.responsive.scss';

type AuthSectionProps = {
  className?: string;
  children?: ReactNode;
};

const AuthSection: React.FC<AuthSectionProps> = (props) => {
  const { children, className } = props;

  const classes = classNames('auth-section', className);

  return <section className={classes}>{children}</section>;
};

export default AuthSection;
