import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'types';

const useCard = (paymentMethod: PaymentMethod) => {
  const { type } = paymentMethod;

  const { t } = useTranslation();

  return useMemo(() => {
    if (type === 'card') {
      const {
        card: { brand, country, exp_month, exp_year, last4 },
      } = paymentMethod;

      return [
        {
          label: t('brand'),
          value: brand.toUpperCase(),
        },
        {
          label: t('country'),
          value: country,
        },
        {
          label: t('expDate'),
          value: `${exp_month.toString()}/${exp_year.toString()}`,
        },
        {
          label: t('last4Digits'),
          value: last4.toString(),
        },
      ];
    }

    const {
      usBankAccount: { bank_name, last4 },
    } = paymentMethod;

    return [
      {
        label: t('bankName'),
        value: bank_name,
      },
      {
        label: t('last4Digits'),
        value: last4,
      },
    ];
  }, [paymentMethod, t, type]);
};

export default useCard;
