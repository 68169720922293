import React, { useCallback } from 'react';
import { Button, Select } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useDivisionOptions from '../../hooks/useDivisionOptions';
import { useGetVacancyFiltersQuery } from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';
import { useDefaultSortByOptions } from 'router/subrouters/Dashboard/hooks/useDefaultSortByOptions';
import useSubjectGrades from '../../hooks/useSubjectGrades';
import useSchoolFromYourGroup from '../../hooks/useSchoolFromYourGroup';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import useAppSelector from 'hooks/useAppSelector';
import { batch } from 'react-redux';
import useCategoryOptions from '../../hooks/useCategoryOptions';
import useTypeOptions from '../../hooks/useTypeOptions';
import { VacancyType } from 'searchality-data';
import { Params } from 'types';
import classNames from 'classnames';

import './VacanciesFilters.styles.scss';

type VacanciesFiltersProps = {
  noTypeFilter?: boolean;
  isNonAcademicFilters?: boolean;
  initialParams?: Partial<Params>;
  isDraftVacancies?: boolean;
  reducerPath: 'schoolGroupVacancies';
};

const VacanciesFilters: React.FC<VacanciesFiltersProps> = (props) => {
  const {
    noTypeFilter = false,
    isNonAcademicFilters,
    reducerPath,
    initialParams,
    isDraftVacancies,
  } = props;

  const params = useAppSelector((state) => state[reducerPath]);
  const { schoolGroupId } = useParams();
  const dispatch = useAppDispatch();

  const { data: filters } = useGetVacancyFiltersQuery({
    schoolGroupId,
  });

  const { $where, $sort } = params || {};
  const { schoolIds, positionDivision, subjectGradeLevel, type, category } =
    $where || {};

  const { t } = useTranslation();

  const sortByOptions = useDefaultSortByOptions();

  const subjectGradeOptions = useSubjectGrades(filters?.subjects);

  const divisionOptions = useDivisionOptions(filters?.divisions);

  const categoryOptions = useCategoryOptions(filters?.categories);

  const typeOptions = useTypeOptions(filters?.types);

  const schoolsFromYourGroupOptions = useSchoolFromYourGroup(filters?.schools);

  const className = 'vacancies-filters';

  const onReset = useCallback(() => {
    batch(() => {
      if (initialParams) {
        return dispatch(
          paramsActions.initializeParams(reducerPath, initialParams),
        );
      }

      dispatch(paramsActions.resetFilters(reducerPath));
      dispatch(paramsActions.resetSort(reducerPath));
    });
  }, [dispatch, initialParams, reducerPath]);

  const onFilter = useCallback(
    (value: string, key: string) => {
      const filterValue = {
        [key]: value,
      };
      dispatch(paramsActions.onFilter(reducerPath, filterValue));
    },
    [dispatch, reducerPath],
  );

  const onSort = useCallback(
    (value: string) => {
      dispatch(paramsActions.sort(reducerPath, value));
    },
    [dispatch, reducerPath],
  );

  return (
    <div className={`${className}__wrapper`}>
      <div className={`${className}`}>
        <div className={`${className}__left`}>
          {schoolsFromYourGroupOptions?.length > 1 && (
            <Select
              options={schoolsFromYourGroupOptions}
              innerLabel={t('schoolName')}
              className="vacancies-filters__wide-select"
              multiple={false}
              onChange={({ value }) => onFilter(value, 'schoolIds')}
              value={schoolIds}
            />
          )}
          {!isDraftVacancies && divisionOptions?.length > 1 && (
            <Select
              className={classNames({
                disabled: type === VacancyType.NON_ACADEMIC,
              })}
              options={divisionOptions}
              multiple={false}
              onChange={({ value }) => {
                onFilter(value, 'positionDivision');

                if (!type) {
                  onFilter(VacancyType.ACADEMIC, 'type');
                }
              }}
              innerLabel={t('schoolLevel')}
              value={positionDivision}
            />
          )}
          {!isDraftVacancies && subjectGradeOptions?.length > 1 && (
            <Select
              className={classNames({
                disabled: type === VacancyType.NON_ACADEMIC,
              })}
              options={subjectGradeOptions}
              multiple={false}
              onChange={({ value }) => {
                onFilter(value, 'subjectGradeLevel');

                if (!type) {
                  onFilter(VacancyType.ACADEMIC, 'type');
                }
              }}
              innerLabel={t('roleSubject')}
              value={subjectGradeLevel}
            />
          )}
          {!isDraftVacancies &&
            categoryOptions?.length > 1 &&
            isNonAcademicFilters !== false && (
              <Select
                className={classNames({
                  disabled: type === VacancyType.ACADEMIC,
                })}
                options={categoryOptions}
                value={category}
                multiple={false}
                onChange={({ value }) => {
                  onFilter(value, 'category');
                  if (!type) {
                    onFilter(VacancyType.NON_ACADEMIC, 'type');
                  }
                }}
                innerLabel={t('department')}
              />
            )}
          {typeOptions?.length > 1 && !noTypeFilter && (
            <Select
              options={typeOptions}
              value={type}
              multiple={false}
              onChange={({ value }) => {
                onFilter(value, 'type');

                if (value === VacancyType.ACADEMIC) {
                  if (category) {
                    onFilter(undefined, 'category');
                  }
                } else {
                  if (subjectGradeLevel) {
                    onFilter(undefined, 'subjectGradeLevel');
                  }
                  if (positionDivision) {
                    onFilter(undefined, 'positionDivision');
                  }
                }
              }}
              innerLabel={t('vacancyType')}
            />
          )}
        </div>
        <div className={`${className}__right`}>
          <Select
            options={sortByOptions}
            innerLabel={t('sortBy')}
            multiple={false}
            value={$sort}
            onChange={({ value }) => {
              onSort(value);
            }}
          />
        </div>
      </div>
      <Button variant="link" onClick={onReset}>
        {t('resetFilters')}
      </Button>
    </div>
  );
};

export default VacanciesFilters;
