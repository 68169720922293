import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GlobeIcon } from 'icons/Globe.icon.svg';
import { ReactComponent as SportIcon } from 'icons/Sport.icon.svg';
import { ProfileCardType } from 'models/ProfileCard.type';

import './ProfileLanguages.styles.scss';

type ProfileLanguagesProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  'languages' | 'englishLevel' | 'sports' | 'activities'
>;

const ProfileLanguages: React.FC<ProfileLanguagesProps> = (props) => {
  const { className, languages, englishLevel, sports, activities } = props;

  const { t } = useTranslation();

  const baseClass = 'searchality-profile-languages';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className="profile-section">
        <div className="profile-section__title">
          <GlobeIcon />
          <p>{t('Profile.Languages.languages')}</p>
        </div>
        <div className={`${baseClass}__section`}>
          <p className="title">{t('english')}</p>
          <p className="subtitle">
            {englishLevel ? englishLevel : t('noData')}
          </p>
        </div>
        {languages?.map(({ language, level }) => (
          <div key={language} className={`${baseClass}__section`}>
            <p className="title">{language}</p>
            <p className="subtitle">{level}</p>
          </div>
        ))}
      </div>
      <hr />
      <div className="profile-section">
        <div className="profile-section__title">
          <SportIcon />
          <p>{t('Profile.Languages.sports')}</p>
        </div>
        <div className={`${baseClass}__section`}>
          <p className="title">{t('sports')}</p>
          {sports?.map((el, index) => (
            <p key={index} className="subtitle">
              {el}
            </p>
          ))}
          <p className="title">{t('activities')}</p>
          {activities?.map((el, index) => (
            <p key={index} className="subtitle">
              {el}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileLanguages;
