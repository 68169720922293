import { ComplexRoute } from 'router/components/Routes/Routes';
import MembersProfilePage from './pages/MembersProfile';
import SchoolGroupMembers from './pages/SchoolGroupMambers';

export default [
  {
    path: ':memberId',
    element: MembersProfilePage,
  },
  {
    index: true,
    element: SchoolGroupMembers,
  },
] as Array<ComplexRoute>;
