import React from 'react';
import PageLoader from 'components/PageLoader';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetVacancyQuery } from 'api/schoolGroups.api';
import JobPreviewComponent from '../../../../../../../../../../../../../components/JobPreviewComponent';

type VacancyPreviewProps = {
  className?: string;
};

const VacancyPreview: React.FC<VacancyPreviewProps> = () => {
  const { vacancyId, schoolGroupId } = useParams();
  const { data: vacancy, isLoading } = useGetVacancyQuery({
    vacancyId,
    schoolGroupId,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <JobPreviewComponent
      vacancy={vacancy}
      showGoBack
      previousPageButtonText={t('goBackToVacancy')}
      previousPage={() => navigate('..')}
      noActions
    />
  );
};

export default VacancyPreview;
