import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useMatchMedia from 'hooks/useMatchMedia';
import PopNotification from 'modules/PopNotifications/components/PopNotification';
import { PopNotificationRef } from 'modules/PopNotifications/components/PopNotification/PopNotification.component';
import React, { useEffect, useRef } from 'react';
import {
  closeAll,
  closeNotification,
} from 'store/slices/popNotifications.slice';

const PopNotifications: React.FC = () => {
  const notifications = useAppSelector((state) => state.popNotifications);
  const dispatch = useAppDispatch();
  const notificationsRef = useRef(new Set<PopNotificationRef>());
  const isPhablet = useMatchMedia('isPhablet');

  useEffect(() => {
    Array.from(notificationsRef.current)
      .slice(0, notifications.length - 1)
      .find((n) => n.open)
      ?.setOpen(false);
  }, [notifications.length]);

  useEffect(() => {
    return () => {
      dispatch(closeAll());
    };
  }, [dispatch]);

  return (
    <>
      {notifications.map((n, ind) => (
        <PopNotification
          side={isPhablet ? 'top' : 'bottom-right'}
          ref={(r) => r && notificationsRef.current.add(r)}
          key={n.notId}
          {...n}
          onClose={() => {
            notificationsRef.current.delete(
              Array.from(notificationsRef.current)[ind],
            );
            dispatch(closeNotification(n));
          }}
        >
          {n.content}
        </PopNotification>
      ))}
    </>
  );
};

export default PopNotifications;
