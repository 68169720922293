import React, { useCallback, useMemo, useRef } from 'react';
import {
  useDeleteSchoolMemberMutation,
  useGetSchoolMemberQuery,
  useUpdateMemberPermissionMutation,
} from 'api/schoolGroups.api';
import { Button } from 'ncoded-component-library';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { useTranslation } from 'react-i18next';
import useAppDispatch from 'hooks/useAppDispatch';
import { Role } from 'searchality-data';
import confirm from 'modules/confirm';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import SelectVacanciesModal from 'router/subrouters/Dashboard/components/SelectVacanciesModal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import RolesSelect from '../../../../../../components/RolesSelect';

import './MembersActions.styles.scss';
import paramsActions from 'store/actions/params.actions';

const MembersActions: React.FC = () => {
  const { memberId, schoolGroupId } = useParams();

  const dispatch = useAppDispatch();
  const [deleteMember] = useDeleteSchoolMemberMutation();
  const [updateMemberPermission] = useUpdateMemberPermissionMutation();

  const selectVacanciesModalRef = useRef<ModalRef>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: member } = useGetSchoolMemberQuery({ schoolGroupId, memberId });

  const { role: memberRole, _id } = member;

  const baseClass = 'members-actions';

  //TODO FIX ERRORS
  const handleOnDelete = async () => {
    await confirm({
      title: t('DeleteMemberModal.title'),
      content: t('DeleteMemberModal.content'),
      confirmBtnText: t('DeleteMemberModal.delete'),
      onSubmit: () => {
        deleteMember({ memberId, schoolGroupId })
          .unwrap()
          .then(() => {
            dispatch(popSuccess(t('memberDeletedSuccesfuly')));
            setTimeout(() => {
              navigate('..');
            }, 500);
          })
          .catch((err) => dispatch(popServerError(err)));
      },
    });
  };

  const handleManageReviewerVacancies = useCallback(
    (selectedIds: string[]) => {
      updateMemberPermission({
        schoolGroupId,
        memberId: _id,
        role: Role.Reviewer,
        vacancyIds: selectedIds,
      })
        .unwrap()
        .then(() => {
          selectVacanciesModalRef.current.close();
          dispatch(popSuccess(t('updateReviewerVacanciesSuccess')));
        })
        .catch(() => dispatch(popError(t('updateReviewerVacanciesFail'))));
    },
    [_id, dispatch, schoolGroupId, t, updateMemberPermission],
  );

  const vacanciesParams = useMemo(
    () => ({
      $where: {
        status: { $in: ['Published', 'Ongoing'] },
      },
      $populate: ['schools', 'schoolGroup'],
    }),
    [],
  );

  return (
    <div className={baseClass}>
      <Button
        icon={<DeleteIcon />}
        iconPosition="right"
        onClick={memberRole !== Role.AccountOwner && handleOnDelete}
        className={`${baseClass}__delete-button`}
        disabled={memberRole === Role.AccountOwner}
      >
        {t('deleteUser')}
      </Button>
      {memberRole === Role.Reviewer && (
        <Button
          variant="outline"
          onClick={() => {
            dispatch(
              paramsActions.initializeParams(
                'schoolGroupVacancies',
                vacanciesParams,
              ),
            );
            selectVacanciesModalRef.current.open();
          }}
        >
          {t('manageVacancies')}
        </Button>
      )}
      <RolesSelect currentRole={memberRole} userId={memberId} />
      <ValueContextProvider isMultiple initialIds={member?.vacancyIds}>
        <SelectVacanciesModal
          ref={selectVacanciesModalRef}
          onSubmit={(selectedIds) => {
            handleManageReviewerVacancies(selectedIds);
          }}
          onClose={() => {
            selectVacanciesModalRef.current.close();
          }}
          onCancel={() => {
            selectVacanciesModalRef.current.close();
          }}
          initialParams={vacanciesParams}
        />
      </ValueContextProvider>
    </div>
  );
};

export default MembersActions;
