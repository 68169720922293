import { useMemo } from 'react';
import { SchoolGroupStructureType } from 'searchality-data';
import { School } from 'models/School';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

export const useSchoolPrices = (
  structureType: SchoolGroupStructureType,
  schools: School[],
  isOldPricing: boolean,
) => {
  const {
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    GROUP_INTITIAL_PRICE,
    STANDALONE_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    WEBSITE_INTEGRATION_PRICE,
    MATCHING_STANDALONE_PRICE,
    MATCHING_SCHOOL_GROUP_PRICE,
  } = useSearchalityPricing(isOldPricing);

  const schoolPrice = useMemo(() => {
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      return STANDALONE_PRICE;
    }

    let price = GROUP_INTITIAL_PRICE;

    if (schools?.length > 2) {
      return (price += (schools?.length - 2) * GROUP_ADDITIONAL_SCHOOL_PRICE);
    }

    return price;
  }, [
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    GROUP_INTITIAL_PRICE,
    STANDALONE_PRICE,
    schools?.length,
    structureType,
  ]);

  const websiteAdditionalPrice = useMemo(() => {
    if (
      structureType === SchoolGroupStructureType.STANDALONE ||
      schools?.length <= 2
    ) {
      return 0;
    }

    return (schools?.length - 2) * WEBSITE_ADDITIONAL_INTEGRATION_PRICE;
  }, [WEBSITE_ADDITIONAL_INTEGRATION_PRICE, schools?.length, structureType]);

  const matchingPrice = useMemo(() => {
    const price = schools?.reduce(
      (prev, curr) =>
        curr?.matchingInformation?.hasMatchingSubscription &&
        !curr?.matchingInformation?.isSubscriptionCanceled
          ? prev + MATCHING_SCHOOL_GROUP_PRICE
          : prev,
      0,
    );

    if (structureType === SchoolGroupStructureType.STANDALONE && price > 0) {
      return MATCHING_STANDALONE_PRICE;
    }

    return price;
  }, [
    MATCHING_SCHOOL_GROUP_PRICE,
    MATCHING_STANDALONE_PRICE,
    schools,
    structureType,
  ]);

  return {
    schoolPrice,
    websiteAdditionalPrice,
    matchingPrice,
    totalPrice:
      schoolPrice + websiteAdditionalPrice + WEBSITE_INTEGRATION_PRICE,
  };
};
