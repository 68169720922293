import { useGetTeacherQuery } from 'api/teachers.api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const useLoginDetailsList = () => {
  const { teacherId } = useParams();
  const { data } = useGetTeacherQuery(teacherId);
  const { t } = useTranslation();
  const { email } = data || {};

  return [
    {
      value: `${email}`,
      label: t('email'),
    },
  ];
};

export default useLoginDetailsList;
