import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './CandidateQualification.styles.scss';

type CandidateQualificationProps = {
  qualificationsNumber: number;
  candidateQualifications: number;
  variant: 'green' | 'blue' | 'red';
  className?: string;
  message?: string;
  showTitle?: boolean;
};

const CandidateQualification: React.FC<CandidateQualificationProps> = (
  props,
) => {
  const {
    className,
    qualificationsNumber,
    candidateQualifications,
    message,
    variant,
    showTitle = true,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'candidate-qualification';
  const classes = classNames(baseClass, className);

  const mainContentClass = `${baseClass}__main-content`;
  const mainContentClasses = classNames(
    mainContentClass,
    `${mainContentClass}--${variant}`,
  );

  return (
    <div className={classes}>
      {showTitle && (
        <p className={`${baseClass}__title`}>{t('qualifyingRate')}</p>
      )}
      <p
        className={mainContentClasses}
      >{`${candidateQualifications} / ${qualificationsNumber}`}</p>
      {message && <p className={`${baseClass}__message`}>{message}</p>}
    </div>
  );
};

export default CandidateQualification;
