import adminStore from 'store';
import { Params } from 'types';

export enum ParamsActionTypes {
  INITIALIZE = 'initialize',
  RESET = 'reset',
  PAGE_CHANGE = 'pageChange',
  SEARCH_STRING_CHANGE = 'searchStringChange',
  SORT_COLUMN = 'sortColumn',
  ON_FILTER = 'onFilter',
  RESET_FILTERS = 'resetFilters',
  SORT = 'sort',
  RESET_SORT = 'resetSort',
}

export type AdminStoreKeys = keyof ReturnType<typeof adminStore.getState>;

export default {
  initializeParams(reducerPath: AdminStoreKeys, params: Params) {
    return {
      type: ParamsActionTypes.INITIALIZE,
      reducerPath,
      payload: params,
    };
  },
  changeCurrentPage(reducerPath: AdminStoreKeys, pageNumber: number) {
    return {
      type: ParamsActionTypes.PAGE_CHANGE,
      reducerPath,
      payload: pageNumber,
    };
  },
  applySearchString(reducerPath: AdminStoreKeys, searchString?: string) {
    return {
      type: ParamsActionTypes.SEARCH_STRING_CHANGE,
      reducerPath,
      payload: searchString,
    };
  },
  sortColumn(reducerPath: AdminStoreKeys, sortValue?: string) {
    return {
      type: ParamsActionTypes.SORT_COLUMN,
      reducerPath,
      payload: sortValue,
    };
  },
  onFilter(reducerPath: AdminStoreKeys, filterValue?: Record<string, any>) {
    return {
      type: ParamsActionTypes.ON_FILTER,
      reducerPath,
      payload: filterValue,
    };
  },
  resetFilters(reducerPath: AdminStoreKeys) {
    return {
      type: ParamsActionTypes.RESET_FILTERS,
      reducerPath,
    };
  },
  sort(reducerPath: AdminStoreKeys, sortValue: string) {
    return {
      type: ParamsActionTypes.SORT,
      reducerPath,
      payload: sortValue,
    };
  },
  resetSort(reducerPath: AdminStoreKeys) {
    return {
      type: ParamsActionTypes.RESET_FILTERS,
      reducerPath,
    };
  },
};
