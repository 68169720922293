import React, { useCallback } from 'react';
import Select from 'components/Select';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType } from 'searchality-data';
import { Button } from 'ncoded-component-library';
import useRolesSelectOptions from 'router/subrouters/Dashboard/hooks/useRolesSelectOptions';
import useSchoolFromYourGroup from '../../../SchoolGroupVacancies/hooks/useSchoolFromYourGroup';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import { useParams } from 'react-router-dom';
import {
  useGetSchoolAccountQuery,
  useGetSchoolAccountSchoolsQuery,
} from 'api/schoolGroups.api';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';

import './MembersFilters.styles.scss';

const MembersFilters: React.FC<{ className: string }> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { schoolGroupId } = useParams();

  const params = useAppSelector(({ schoolGroupMembers }) => schoolGroupMembers);

  const { data: { structureType } = {} } =
    useGetSchoolAccountQuery(schoolGroupId);

  const { data: { items: schools } = {} } =
    useGetSchoolAccountSchoolsQuery(schoolGroupId);

  const permissionOptions = useRolesSelectOptions();

  const schoolOptions = useSchoolFromYourGroup(schools);

  const onReset = useCallback(() => {
    dispatch(paramsActions.resetFilters('schoolGroupMembers'));
  }, [dispatch]);

  const onFilter = useCallback(
    (value: string, key: string) => {
      const filterValue = {
        [key]: value,
      };
      dispatch(paramsActions.onFilter('schoolGroupMembers', filterValue));
    },
    [dispatch],
  );

  return (
    <div className={classNames('members-filters', className)}>
      <div className="members-filters__select-wrapper">
        <Select
          options={permissionOptions}
          innerLabel={t('Members.teamPermisson')}
          multiple={false}
          value={params?.$where?.role}
          onChange={({ value }) => onFilter(value, 'role')}
        />
        {structureType !== SchoolGroupStructureType.STANDALONE &&
          schoolOptions?.length > 1 && (
            <Select
              options={schoolOptions}
              innerLabel={t('Members.schoolWithinGroup')}
              multiple={false}
              value={params?.$where?.schoolIds}
              onChange={({ value }) => onFilter(value, 'schoolIds')}
            />
          )}
      </div>
      <Button
        variant="link"
        onClick={onReset}
        className={'members-filters__reset'}
      >
        {t('resetFilters')}
      </Button>
    </div>
  );
};

export default MembersFilters;
