import React, { useEffect, useRef } from 'react';
import { TextArea } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import Anim from 'components/animations';
import classNames from 'classnames';

import './TextAreaField.styles.scss';

export type TextAreaFieldProps = FieldRenderProps<string, HTMLElement>;

const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const {
    className,
    required,
    label,
    maxLength,
    showLengthCount = false,
    input,
    meta: { error, touched },
    ...rest
  } = props;

  const divRef = useRef(null);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      const textArea = divRef.current?.querySelector('label');
      const wrapper = textArea?.parentElement;

      if (wrapper && textArea) {
        wrapper.style.paddingTop = `${textArea?.clientHeight + 20}px`;
      }
    }
    firstRender.current = false;
  }, []);

  return (
    <div
      className={classNames(className, {
        'text-area-field--asterix': required,
      })}
      ref={divRef}
    >
      <TextArea
        maxLength={maxLength}
        spellCheck="false"
        showLengthCount={showLengthCount}
        resize={false}
        innerLabel={label}
        hasError={error && touched}
        {...rest}
        {...input}
      />
      <Anim.Collapse active={error && touched}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
    </div>
  );
};

export default TextAreaField;
