import React from 'react';
import AcademicContractTerms, {
  AcademicContractTermsProps,
} from '../AcademicContractTerms/AcademicContractTerms.component';
import NonAcademicContractTerms, {
  NonAcademicContractTermsProps,
} from '../NonAcademicContractTerms/NonAcademicContractTerms.component';
import { VacancyType } from 'searchality-data';
import { useTranslation } from 'react-i18next';

type ContractTermsProps = {
  vacancyType: VacancyType;
} & AcademicContractTermsProps &
  NonAcademicContractTermsProps;

const ContractTerms: React.FC<ContractTermsProps> = (props) => {
  const { t } = useTranslation();

  const {
    vacancyType,
    studentContractDays,
    studentNonContractDays,
    contractDays,
    contractDaysDetails,
  } = props;

  return (
    <li>
      <b>{t('jobPreview.ContractTerms')}</b>
      {vacancyType === VacancyType.ACADEMIC ? (
        <AcademicContractTerms
          studentContractDays={studentContractDays}
          studentNonContractDays={studentNonContractDays}
        />
      ) : (
        <NonAcademicContractTerms
          contractDays={contractDays}
          contractDaysDetails={contractDaysDetails}
        />
      )}
    </li>
  );
};

export default ContractTerms;
