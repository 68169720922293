import React from 'react';
import { ReviewCardItemList } from 'types';
import ReviewCardItem from '../ReviewCard/components/ReviewCardItem';
import ReviewCard, {
  ReviewCardProps,
} from '../ReviewCard/ReviewCard.component';

type ReviewCardWrapperProps = ReviewCardProps & {
  list: ReviewCardItemList;
};

const ReviewCardWrapper: React.FC<ReviewCardWrapperProps> = (props) => {
  const { className, title, list, children, onEdit } = props;

  return (
    <ReviewCard className={className} title={title} onEdit={onEdit}>
      {list?.map(({ label, value, description, className }, index) => (
        <ReviewCardItem
          key={index}
          label={label}
          description={description}
          className={className}
        >
          {value ? value : 'N/A'}
        </ReviewCardItem>
      ))}
      {children}
    </ReviewCard>
  );
};

export default ReviewCardWrapper;
