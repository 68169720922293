import { useTranslation } from 'react-i18next';

const useTeamPermissionsFilter = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('admin'),
      value: 'Admin',
    },
    {
      label: t('superAdmin'),
      value: 'Super admin',
    },
  ];
};

export default useTeamPermissionsFilter;
