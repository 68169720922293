import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  SchoolGroupStructureType,
  WidgetIntegrationStatus,
} from 'searchality-data';
import { Button } from 'ncoded-component-library';
import {
  useCompleteSchoolGroupWidgetStatusMutation,
  useCompleteSchoolWidgetStatusMutation,
  useGetSchoolAccountQuery,
} from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';

import './WidgetSettings.styles.scss';

type WidgetSettingsProps = {
  className?: string;
};

const WidgetSettings: React.FC<WidgetSettingsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { schoolGroupId } = useParams();

  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);
  const { schools, widgetIntegrationStatus, structureType } = schoolGroup || {};

  const [completeSchoolGroupWidget] =
    useCompleteSchoolGroupWidgetStatusMutation();
  const [completeSchoolWidget] = useCompleteSchoolWidgetStatusMutation();

  const getWidgetStatusIntegration = useCallback(
    ({
      _id,
      name,
      widgetIntegrationStatus,
      isUpdatingSchoolStatus,
    }: {
      _id?: string;
      name: string;
      widgetIntegrationStatus: WidgetIntegrationStatus;
      isUpdatingSchoolStatus: boolean;
    }) => (
      <div key={_id ?? undefined}>
        <strong>{name}</strong>
        <span
          className={
            widgetIntegrationStatus === WidgetIntegrationStatus.COMPLETED
              ? 'blue'
              : 'orange'
          }
        >
          {t(widgetIntegrationStatus)}
        </span>
        {widgetIntegrationStatus === WidgetIntegrationStatus.COMPLETED ? (
          <span className="empty" />
        ) : (
          <Button
            onClick={() =>
              isUpdatingSchoolStatus
                ? completeSchoolWidget({ schoolId: _id })
                : completeSchoolGroupWidget({ schoolGroupId })
            }
          >
            {t('complete')}
          </Button>
        )}
      </div>
    ),
    [completeSchoolGroupWidget, completeSchoolWidget, schoolGroupId, t],
  );

  const isGroup = structureType === SchoolGroupStructureType.GROUP;

  const classes = classNames('widget-settings', className);

  return (
    <div className={classes}>
      <section className="widget-settings__widget-status">
        <h1>{t('WidgetSettings.widgetIntegration.title')}</h1>
        {getWidgetStatusIntegration({
          widgetIntegrationStatus,
          isUpdatingSchoolStatus: false,
          name: isGroup ? t('schoolGroup') : schools?.[0]?.name,
        })}
        {isGroup &&
          schools?.map(({ _id, widgetIntegrationStatus, name }) =>
            getWidgetStatusIntegration({
              _id,
              name,
              widgetIntegrationStatus,
              isUpdatingSchoolStatus: true,
            }),
          )}
      </section>
    </div>
  );
};

export default WidgetSettings;
