import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolGroupMembersRouter from './subrouters/SchoolGroupMembers/SchoolGroupMembers.router';
import schoolGroupMembersRoutes from './subrouters/SchoolGroupMembers/SchoolGroupMembers.routes';
import SchoolGroupSettings from './subrouters/SchoolGroupSettings/SchoolGroupSettings.router';
import schoolGroupSettingsRoutes from './subrouters/SchoolGroupSettings/SchoolGroupSettings.routes';
import SchoolGroupVacancies from './subrouters/SchoolGroupVacancies/SchoolGroupVacancies.router';
import schoolGroupVacanciesRoutes from './subrouters/SchoolGroupVacancies/SchoolGroupVacancies.routes';

export default [
  {
    path: 'vacancies',
    element: SchoolGroupVacancies,
    routes: schoolGroupVacanciesRoutes,
  },
  {
    path: 'members',
    element: SchoolGroupMembersRouter,
    routes: schoolGroupMembersRoutes,
  },
  {
    path: 'account',
    element: SchoolGroupSettings,
    routes: schoolGroupSettingsRoutes,
  },
] as Array<ComplexRoute>;
