import React from 'react';
import useCard from './hooks/useCard';
import { PaymentMethod } from 'types';
import { useTranslation } from 'react-i18next';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';

type PaymentMethodCardProps = {
  paymentMethod: PaymentMethod;
};

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = (props) => {
  const { paymentMethod } = props;

  const { t } = useTranslation();

  const list = useCard(paymentMethod);

  return <ReviewCardWrapper list={list} title={t('paymentDetails')} />;
};

export default PaymentMethodCard;
