import GoBackButton from 'components/GoBackButton';
import Heading from 'components/Heading';
import { Button } from 'ncoded-component-library';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import TabsNavigation from 'components/TabsNavigation';
import { useDeleteTeacherMutation, useGetTeacherQuery } from 'api/teachers.api';
import useTeacherProfileTabs from './hooks/useTeacherProfileTabs';

const TeacherProfileHeader: React.FC = () => {
  const { teacherId } = useParams();
  const { data: teacher } = useGetTeacherQuery(teacherId);

  const [deleteTeacher] = useDeleteTeacherMutation();

  const tabs = useTeacherProfileTabs();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { firstName, lastName, status, email } = teacher || {};

  const handleDeleteRequest = useCallback(async () => {
    deleteTeacher(teacherId)
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('DeleteTeacherModal.success')));
        paramsActions.initializeParams('teachersParams', {
          $limit: 20,
        });
        return navigate('/teachers');
      })
      .catch(() => {
        dispatch(popError(t('DeleteTeacherModal.fail')));
      });
  }, [deleteTeacher, dispatch, navigate, t, teacherId]);

  const handleSchoolDelete = useCallback(async () => {
    await confirm({
      title: t('DeleteTeacherModal.title'),
      content: t('DeleteTeacherModal.content', {
        name: `${firstName} ${lastName}`,
      }),
      confirmBtnText: t('DeleteTeacherModal.confirmButton'),
      onSubmit: handleDeleteRequest,
    });
  }, [firstName, handleDeleteRequest, lastName, t]);

  return (
    <>
      <GoBackButton to="../" />
      <Heading title={`${firstName} ${lastName}`}>
        <div className="heading-buttons-group">
          <p className={`vacancy-status`}>
            {t('status')}:&nbsp; <span>{status}</span>
          </p>
          <Button
            variant="outline"
            onClick={() => (window.location.href = `mailto:${email}`)}
          >
            {t('contactUser')}
          </Button>
          <Button onClick={handleSchoolDelete} variant="outline">
            {t('deleteUser')}
          </Button>
        </div>
      </Heading>
      <TabsNavigation tabs={tabs} />
    </>
  );
};

export default TeacherProfileHeader;
