import React, { Fragment } from 'react';
import classNames from 'classnames';
import { ProfileCardType } from 'models/ProfileCard.type';

import './ProfileReference.styles.scss';

type ProfileReferenceProps = {
  className?: string;
} & Pick<ProfileCardType, 'references'>;

const ProfileReference: React.FC<ProfileReferenceProps> = (props) => {
  const { className, references } = props;

  const baseClass = 'searchality-profile-reference';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      {references?.map((el, index: number) => (
        <Fragment key={el.firstName + el.lastName + el.jobTitle}>
          <div className="profile-section">
            <div className={`${baseClass}__section`}>
              <div>
                <p className="title">{`${el.firstName} ${el.lastName}`}</p>
                {el?.contactPhone?.phoneNumber && (
                  <p className="description">
                    {`${el.contactPhone.countryCode} ${el.contactPhone.phoneNumber}`}
                  </p>
                )}
                {el.contactEmail && (
                  <p className="description">{el.contactEmail}</p>
                )}
              </div>
              <div>
                <p className="subtitle">{el.jobTitle}</p>
                <p className="description">{`${el.schoolName}, ${el.schoolCity}`}</p>
              </div>
              <div>
                <p className="subtitle">{el.relationship}</p>
              </div>
            </div>
          </div>
          {index < references.length - 1 && <hr />}
        </Fragment>
      ))}
    </div>
  );
};

export default ProfileReference;
