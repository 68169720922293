import React, { useCallback, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import PaginationInfo from 'components/PaginationInfo';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MemberVacancyCard from './components/MemberVacancyCard';
import { useGetSchoolMemberVacanciesQuery } from 'api/schoolGroups.api';
import { Vacancy } from 'models/Vacancy';
import { PaginatedResponse } from 'types';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import useAppSelector from 'hooks/useAppSelector';

import './MemberVacancies.styles.scss';

const MemberVacancies: React.FC = () => {
  const { memberId, schoolGroupId } = useParams();
  const dispatch = useAppDispatch();
  const params = useAppSelector(
    ({ schoolGroupVacancies }) => schoolGroupVacancies,
  );
  const { data, isLoading } = useGetSchoolMemberVacanciesQuery({
    memberId,
    schoolGroupId,
    params,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { items: vacancies, ...pagination } =
    data || ({} as PaginatedResponse<Vacancy>);

  const initializeParams = useCallback(() => {
    dispatch(
      paramsActions.initializeParams('schoolGroupVacancies', { $limit: 6 }),
    );
  }, [dispatch]);

  useEffect(() => {
    initializeParams();
    return () => {
      initializeParams();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="member-vacancies">
      <PaginationInfo
        name={t('SchoolGroupVacancies.vacancy', {
          count: pagination?.totalItems,
        })}
        {...pagination}
        limit={6}
      />
      <ul>
        {vacancies?.map((vacancy) => (
          <MemberVacancyCard
            key={vacancy._id}
            {...vacancy}
            onClick={() =>
              navigate(`../../vacancies/${vacancy._id}/candidates`)
            }
          />
        ))}
      </ul>

      {pagination?.totalPages > 1 && (
        <Pagination
          {...pagination}
          onChange={(pageNumber: number) => {
            dispatch(
              paramsActions.changeCurrentPage(
                'schoolGroupVacancies',
                pageNumber,
              ),
            );
          }}
        />
      )}
    </div>
  );
};

export default MemberVacancies;
