import { createApi } from '@reduxjs/toolkit/query/react';
import { Admin } from 'models/Admin';
import { PaginatedResponse, Params, TransformedPaginatedResponse } from 'types';
import baseQuery from './baseQuery';

export const adminsApi = createApi({
  reducerPath: 'admin/admins',
  baseQuery: baseQuery,
  tagTypes: ['Admins'],
  endpoints: (builder) => ({
    getAdmins: builder.query<TransformedPaginatedResponse<Admin>, Params>({
      query: (params) => {
        return {
          url: `users`,
          method: 'get',
          params,
        };
      },
      transformResponse: (response: PaginatedResponse<Admin>) => {
        const { items, ...pagination } = response;
        return { items, pagination } as TransformedPaginatedResponse<Admin>;
      },
      keepUnusedDataFor: 10,
      providesTags: ['Admins'],
    }),

    inviteAdmin: builder.mutation({
      query: (body: { firstName: string; lastName: string; email: string }) => {
        return {
          url: 'users/invite',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Admins'],
    }),
    deleteAdmin: builder.mutation({
      query: (adminId: string) => {
        return {
          url: `users/${adminId}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Admins'],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useInviteAdminMutation,
  useDeleteAdminMutation,
} = adminsApi;
