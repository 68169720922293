import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import env from 'env';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { AuthBody } from 'types';
import utils from 'utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${env.SERVER_ENDPOINT}/api/admins/`,
  paramsSerializer: (params) => {
    return utils
      .getPairs(params, [])
      .map(
        ([[key0, ...keysRest], value]) =>
          `${key0}${keysRest.map((a: any) => `[${a}]`).join('')}=${value}`,
      )
      .join('&');
  },
  prepareHeaders: (headers) => {
    const token = credentialsService.token;

    if (token) headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      {
        url: 'auth/login/refresh',
        method: 'POST',
        body: {
          refreshToken: credentialsService?.refreshToken,
        },
      },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      credentialsService.saveAuthBody(refreshResult.data as AuthBody);

      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      authService.logout();
      window.location.replace('/auth/login');
    }
  }
  return result;
};

export default baseQueryWithReauth;
