import { useMemo } from 'react';

export default (availableDivisions: string[]) => {
  const options = useMemo(
    () =>
      availableDivisions?.map((value) => {
        return {
          label: value,
          value,
        };
      }),
    [availableDivisions],
  );

  return options;
};
