import { AuthBody } from '../models/AuthBody';
import storageService from './storageService';

export default {
  get token(): string {
    return storageService.getItem<string>('token');
  },

  get refreshToken(): string {
    return storageService.getItem<string>('refreshToken');
  },

  saveAuthBody({ token, refreshToken }: AuthBody) {
    storageService.setItem('token', token);
    storageService.setItem('refreshToken', refreshToken);
  },

  removeAuthBody() {
    storageService.removeItem('token');
    storageService.removeItem('refreshToken');
  },
};
