import React from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';
import { Teacher } from 'models/Teacher';
import { ReactComponent as DuplicateIcon } from 'icons/Duplicate.icon.svg';
import DateService from 'services/Date.service';

const useTeachersTableData = (teachers: Teacher[]) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return teachers?.map(
    ({
      _id,
      firstName,
      lastName,
      email,
      englishLevel,
      hasRightToWork,
      lastLogin,
      matchingInformation,
      createdAt,
    }) => ({
      id: _id,
      data: {
        name: `${firstName} ${lastName}`,
        createdAt: DateService.getUSADateFormatLong(createdAt),
        lastLogin: lastLogin
          ? DateService.getUSADateFormatLong(lastLogin)
          : t('noData'),
        _id: (
          <button
            className="svg-button-wrapper"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(_id);
              dispatch(popSuccess(t('copyToClipboardSuccess')));
            }}
          >
            <DuplicateIcon />
            {_id}
          </button>
        ),
        email,
        accountType: matchingInformation?.isForTesting ? t('testing') : '',
        englishLevel,
        hasRightToWork: hasRightToWork ? t('yes') : t('no'),
      },
    }),
  );
};

export default useTeachersTableData;
