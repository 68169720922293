import React, { useMemo } from 'react';
import Anim from 'components/animations';
import classNames from 'classnames';
import { Checkbox } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import { ExtractPropTypes } from 'types';

import './CheckboxField.styles.scss';

type CheckboxProps = ExtractPropTypes<typeof Checkbox>;

export type CheckboxFieldProps = FieldRenderProps<string, HTMLElement> & {
  isStyledDefault?: boolean;
} & CheckboxProps;

const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const { input, className, isStyledDefault = false, meta, ...rest } = props;

  const classes = classNames('checkbox-field', className, {
    'ncoded-styled-checkbox': !isStyledDefault,
  });

  const checkboxClasses = classNames({
    'ncoded-checkbox--checked': input.checked,
  });
  const { touched, error } = meta;

  const hasError = useMemo(() => !!error && touched, [error, touched]);

  return (
    <div className={classes}>
      <Checkbox className={checkboxClasses} {...input} {...rest} />
      <Anim.Collapse active={hasError}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
    </div>
  );
};

export default CheckboxField;
