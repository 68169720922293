import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Button, Checkbox, Modal } from 'ncoded-component-library';
import Pagination from 'components/Pagination';
import { PaginatedResponse, Params } from 'types';
import { Vacancy } from 'models/Vacancy';
import useCallbackRef from 'hooks/useCallbackRef';
import ValueContext from 'providers/ValueContext/Value.context';
import VacancyCardWrapper from '../VacancyCard/components/VacancyCardWrapper';
import VacanciesFilters from '../../subrouters/SchoolGroups/subrouters/SchoolGroupProfile/subrouters/SchoolGroupVacancies/components/VacanciesFilters';
import useAppSelector from 'hooks/useAppSelector';
import paramsActions from 'store/actions/params.actions';
import { useGetSchoolAccountVacanciesQuery } from 'api/schoolGroups.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { useParams } from 'react-router-dom';

import './SelectVacanciesModal.styles.scss';
import { batch } from 'react-redux';

type SelectVacanciesModalProps = {
  className?: string;
  onCancel?: () => void;
  onSubmit: (selectedIds: string[]) => void;
  initialParams: Partial<Params>;
} & OverlayProps;

const SelectVacanciesModal: ForwardRefRenderFunction<
  ModalRef,
  SelectVacanciesModalProps
> = (props, ref) => {
  const { className, onSubmit, onCancel, initialParams } = props;
  const { schoolGroupId } = useParams();

  const { selectedIds, manageId, clearIds } = useContext(ValueContext);
  const params = useAppSelector(
    ({ schoolGroupVacancies }) => schoolGroupVacancies,
  );

  const dispatch = useAppDispatch();

  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const baseClass = 'select-vacancies-modal';
  const classes = classNames(baseClass, className);

  const { data, isLoading } = useGetSchoolAccountVacanciesQuery(
    {
      ...params,
      schoolGroupId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { t } = useTranslation();

  const { items: vacancies, ...pagination } =
    data || ({} as PaginatedResponse<Vacancy>);

  const checkboxGroup = useMemo(
    () =>
      vacancies?.map((vacancy) => (
        <VacancyCardWrapper
          {...vacancy}
          noActions={true}
          key={vacancy._id}
          onClick={() => manageId(vacancy._id)}
          isSelected={selectedIds?.includes(vacancy._id)}
          additionalContent={
            <Checkbox
              onChange={() => manageId(vacancy._id)}
              checked={selectedIds?.includes(vacancy._id)}
            />
          }
        />
      )),
    [manageId, selectedIds, vacancies],
  );

  const handleOnSubmit = useCallback(() => {
    onSubmit(selectedIds);
  }, [onSubmit, selectedIds]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(paramsActions.resetFilters('schoolGroupVacancies'));
        dispatch(paramsActions.resetSort('schoolGroupVacancies'));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return undefined;
  }

  return (
    <Modal
      className={classes}
      ref={modalRef}
      onX={() => {
        props?.onClose?.();
      }}
      onClose={clearIds}
      title={t('Members.selectVacancyIdsTitle')}
      footer={
        <div>
          <Button
            variant="outline"
            onClick={() => {
              onCancel?.();
            }}
          >
            {t('cancel')}
          </Button>
          <Button disabled={selectedIds?.length < 1} onClick={handleOnSubmit}>
            {t('changeRole')}
          </Button>
        </div>
      }
    >
      {pagination.totalItems > 0 ? (
        <div className="vacancies">
          <VacanciesFilters
            reducerPath="schoolGroupVacancies"
            initialParams={initialParams}
          />
          <ul className={`checkbox-group`}>{checkboxGroup}</ul>
          {pagination?.totalPages > 1 && (
            <Pagination
              onChange={(pageNumber) => {
                dispatch(
                  paramsActions.changeCurrentPage(
                    'schoolGroupVacancies',
                    pageNumber,
                  ),
                );
              }}
              {...pagination}
            />
          )}
        </div>
      ) : (
        <div>{t('Members.noVacanciesError')}</div>
      )}
    </Modal>
  );
};

export default forwardRef(SelectVacanciesModal);
