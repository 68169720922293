import React, { useCallback } from 'react';
import classNames from 'classnames';
import Heading from 'components/Heading';
import { useTranslation } from 'react-i18next';

import './UsersFeedback.styles.scss';
import { useGetTeachersFeedbackCountQuery } from 'api/teachers.api';
import env from 'env';
import { Button } from 'ncoded-component-library';
import credentialsService from 'services/credentialsService';

type UsersFeedbackProps = {
  className?: string;
};

const UsersFeedback: React.FC<UsersFeedbackProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('users-feedback', className);

  const { data: resCount } = useGetTeachersFeedbackCountQuery();

  const { positive, negative } = resCount || {};

  const handleDownload = useCallback(() => {
    const token = credentialsService.token;

    fetch(`${env.SERVER_ENDPOINT}/api/admins/teachers/matching-feedback/csv`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.href = url;
        link.download = 'FeedbackReport';

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);
      });
  }, []);

  return (
    <div className={classes}>
      <Heading title={t('usersFeedback')}>
        <Button onClick={handleDownload} disabled={positive + negative === 0}>
          {t('exportInCsv')}
        </Button>
      </Heading>
      <div className="users-feedback__count">
        <p>{t('positiveCount', { count: positive })}</p>
        <p>{t('negativeCount', { count: negative })}</p>
      </div>
    </div>
  );
};

export default UsersFeedback;
