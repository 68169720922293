import React from 'react';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { TwoFactorAuthenticationMethod } from 'types';
import { useTranslation } from 'react-i18next';

const useMethodOptions = () => {
  const { t } = useTranslation();

  return Object.entries(TwoFactorAuthenticationMethod)?.map(([key, value]) => ({
    label: <RadioButtonLabel label={t(key)} />,
    value,
  }));
};

export default useMethodOptions;
