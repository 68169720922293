import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import InputField from 'components/InputField';
import { required } from 'validations';
import { useTranslation } from 'react-i18next';

import './CreateAssociationInformationFields.styles.scss';

type CreateAssociationInformationFieldsProps = {
  className?: string;
};

const CreateAssociationInformationFields: React.FC<
  CreateAssociationInformationFieldsProps
> = (props) => {
  const { className } = props;

  const baseClass = 'create-association-information-fields';
  const classes = classNames(baseClass, className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <Field
        name="name"
        component={InputField}
        validate={required()}
        label={t('Associations.CreateAssociation.nameLabel')}
        required
      />
      <Field
        name="acronym"
        component={InputField}
        validate={required()}
        label={t('Associations.CreateAssociation.acronym')}
        required
      />
      <Field
        name="website"
        component={InputField}
        validate={required()}
        label={t('Associations.CreateAssociation.websiteLabel')}
        required
      />
    </div>
  );
};

export default CreateAssociationInformationFields;
