import { useTranslation } from 'react-i18next';

const useAdminsTableHeader = () => {
  const { t } = useTranslation();

  return [
    {
      text: t('name'),
      value: 'firstName',
      sortable: true,
    },
    {
      text: t('teamPermissions'),
      value: 'role',
      sortable: true,
    },
    {
      text: t('lastLogin'),
      value: 'lastLogin',
      sortable: true,
    },
    {
      text: t('action'),
      value: 'action',
    },
  ];
};

export default useAdminsTableHeader;
