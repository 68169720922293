export enum CREATE_SCHOOL_WIZARD {
  SCHOOL_TYPE = 0,
  SCHOOL_INFORMATION = 1,
  PRICING_INFORMATION = 2,
  REVIEW = 3,
}

export const ALL_COUNTRIES = [
  { code: 'US', call: '+1', name: 'United States' },
  { code: 'AF', call: '+93', name: 'Afghanistan' },
  { code: 'AL', call: '+355', name: 'Albania' },
  { code: 'DZ', call: '+213', name: 'Algeria' },
  { code: 'AS', call: '+1', name: 'American Samoa' },
  { code: 'AD', call: '+376', name: 'Andorra' },
  { code: 'AO', call: '+244', name: 'Angola' },
  { code: 'AI', call: '+1', name: 'Anguilla' },
  { code: 'AG', call: '+1', name: 'Antigua and Barbuda' },
  { code: 'AR', call: '+54', name: 'Argentina' },
  { code: 'AM', call: '+374', name: 'Armenia' },
  { code: 'AW', call: '+297', name: 'Aruba' },
  { code: 'AU', call: '+61', name: 'Australia' },
  { code: 'AT', call: '+43', name: 'Austria' },
  { code: 'AZ', call: '+994', name: 'Azerbaijan' },
  { code: 'BH', call: '+973', name: 'Bahrain' },
  { code: 'BS', call: '+1', name: 'Bahamas' },
  { code: 'BD', call: '+880', name: 'Bangladesh' },
  { code: 'BB', call: '+1', name: 'Barbados' },
  { code: 'BY', call: '+375', name: 'Belarus' },
  { code: 'BE', call: '+32', name: 'Belgium' },
  { code: 'BZ', call: '+501', name: 'Belize' },
  { code: 'BJ', call: '+229', name: 'Benin' },
  { code: 'BM', call: '+1', name: 'Bermuda' },
  { code: 'BT', call: '+975', name: 'Bhutan' },
  { code: 'BO', call: '+591', name: 'Bolivia, Plurinational State of' },
  { code: 'BQ', call: '+599', name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA', call: '+387', name: 'Bosnia and Herzegovina' },
  { code: 'BW', call: '+267', name: 'Botswana' },
  { code: 'BR', call: '+55', name: 'Brazil' },
  { code: 'BN', call: '+673', name: 'Brunei Darussalam' },
  { code: 'BG', call: '+359', name: 'Bulgaria' },
  { code: 'BF', call: '+226', name: 'Burkina Faso' },
  { code: 'BI', call: '+257', name: 'Burundi' },
  { code: 'KH', call: '+855', name: 'Cambodia' },
  { code: 'CM', call: '+237', name: 'Cameroon' },
  { code: 'CA', call: '+1', name: 'Canada' },
  { code: 'CV', call: '+238', name: 'Cape Verde' },
  { code: 'KY', call: '+1', name: 'Cayman Islands' },
  { code: 'CF', call: '+236', name: 'Central African Republic' },
  { code: 'TD', call: '+235', name: 'Chad' },
  { code: 'CL', call: '+56', name: 'Chile' },
  { code: 'CN', call: '+86', name: 'China' },
  { code: 'CO', call: '+57', name: 'Colombia' },
  { code: 'KM', call: '+269', name: 'Comoros' },
  { code: 'CG', call: '+242', name: 'Congo' },
  { code: 'CD', call: '+243', name: 'Congo, the Democratic Republic of the' },
  { code: 'CK', call: '+682', name: 'Cook Islands' },
  { code: 'CR', call: '+506', name: 'Costa Rica' },
  { code: 'CI', call: '+225', name: "Côte d'Ivoire" },
  { code: 'HR', call: '+385', name: 'Croatia' },
  { code: 'CU', call: '+53', name: 'Cuba' },
  { code: 'CW', call: '+599', name: 'Curaçao' },
  { code: 'CY', call: '+357', name: 'Cyprus' },
  { code: 'CZ', call: '+420', name: 'Czech Republic' },
  { code: 'DK', call: '+45', name: 'Denmark' },
  { code: 'DJ', call: '+253', name: 'Djibouti' },
  { code: 'DM', call: '+1', name: 'Dominica' },
  { code: 'DO', call: '+1', name: 'Dominican Republic' },
  { code: 'EC', call: '+593', name: 'Ecuador' },
  { code: 'EG', call: '+20', name: 'Egypt' },
  { code: 'SV', call: '+503', name: 'El Salvador' },
  { code: 'GQ', call: '+240', name: 'Equatorial Guinea' },
  { code: 'ER', call: '+291', name: 'Eritrea' },
  { code: 'EE', call: '+372', name: 'Estonia' },
  { code: 'ET', call: '+251', name: 'Ethiopia' },
  { code: 'FK', call: '+500', name: 'Falkland Islands (Malvinas)' },
  { code: 'FO', call: '+298', name: 'Faroe Islands' },
  { code: 'FJ', call: '+679', name: 'Fiji' },
  { code: 'FI', call: '+358', name: 'Finland' },
  { code: 'FR', call: '+33', name: 'France' },
  { code: 'GF', call: '+594', name: 'French Guiana' },
  { code: 'PF', call: '+689', name: 'French Polynesia' },
  { code: 'TF', call: '+262', name: 'French Southern Territories' },
  { code: 'GA', call: '+241', name: 'Gabon' },
  { code: 'GM', call: '+220', name: 'Gambia' },
  { code: 'GE', call: '+995', name: 'Georgia' },
  { code: 'DE', call: '+49', name: 'Germany' },
  { code: 'GH', call: '+233', name: 'Ghana' },
  { code: 'GI', call: '+350', name: 'Gibraltar' },
  { code: 'GR', call: '+30', name: 'Greece' },
  { code: 'GL', call: '+299', name: 'Greenland' },
  { code: 'GD', call: '+1', name: 'Grenada' },
  { code: 'GP', call: '+590', name: 'Guadeloupe' },
  { code: 'GU', call: '+1', name: 'Guam' },
  { code: 'GT', call: '+502', name: 'Guatemala' },
  { code: 'GN', call: '+224', name: 'Guinea' },
  { code: 'GW', call: '+245', name: 'Guinea-Bissau' },
  { code: 'GY', call: '+592', name: 'Guyana' },
  { code: 'HT', call: '+509', name: 'Haiti' },
  { code: 'HN', call: '+504', name: 'Honduras' },
  { code: 'HK', call: '+852', name: 'Hong Kong' },
  { code: 'HU', call: '+36', name: 'Hungary' },
  { code: 'IS', call: '+354', name: 'Iceland' },
  { code: 'IN', call: '+91', name: 'India' },
  { code: 'ID', call: '+62', name: 'Indonesia' },
  { code: 'IR', call: '+98', name: 'Iran, Islamic Republic of' },
  { code: 'IQ', call: '+964', name: 'Iraq' },
  { code: 'IE', call: '+353', name: 'Ireland' },
  { code: 'IL', call: '+972', name: 'Israel' },
  { code: 'IT', call: '+39', name: 'Italy' },
  { code: 'JM', call: '+1', name: 'Jamaica' },
  { code: 'JP', call: '+81', name: 'Japan' },
  { code: 'JO', call: '+962', name: 'Jordan' },
  { code: 'KZ', call: '+7', name: 'Kazakhstan' },
  { code: 'KE', call: '+254', name: 'Kenya' },
  { code: 'KI', call: '+686', name: 'Kiribati' },
  { code: 'KP', call: '+850', name: "Korea, Democratic People's Republic of" },
  { code: 'KR', call: '+82', name: 'Korea, Republic of' },
  { code: 'KW', call: '+965', name: 'Kuwait' },
  { code: 'KG', call: '+996', name: 'Kyrgyzstan' },
  { code: 'LA', call: '+856', name: "Lao People's Democratic Republic" },
  { code: 'LV', call: '+371', name: 'Latvia' },
  { code: 'LB', call: '+961', name: 'Lebanon' },
  { code: 'LS', call: '+266', name: 'Lesotho' },
  { code: 'LR', call: '+231', name: 'Liberia' },
  { code: 'LY', call: '+218', name: 'Libya' },
  { code: 'LI', call: '+423', name: 'Liechtenstein' },
  { code: 'LT', call: '+370', name: 'Lithuania' },
  { code: 'LU', call: '+352', name: 'Luxembourg' },
  { code: 'MO', call: '+853', name: 'Macao' },
  { code: 'MK', call: '+389', name: 'North Macedonia' },
  { code: 'MG', call: '+261', name: 'Madagascar' },
  { code: 'MW', call: '+265', name: 'Malawi' },
  { code: 'MY', call: '+60', name: 'Malaysia' },
  { code: 'MV', call: '+960', name: 'Maldives' },
  { code: 'ML', call: '+223', name: 'Mali' },
  { code: 'MT', call: '+356', name: 'Malta' },
  { code: 'MH', call: '+692', name: 'Marshall Islands' },
  { code: 'MQ', call: '+596', name: 'Martinique' },
  { code: 'MR', call: '+222', name: 'Mauritania' },
  { code: 'MU', call: '+230', name: 'Mauritius' },
  { code: 'MX', call: '+52', name: 'Mexico' },
  { code: 'FM', call: '+691', name: 'Micronesia, Federated States of' },
  { code: 'MD', call: '+373', name: 'Moldova, Republic of' },
  { code: 'MC', call: '+377', name: 'Monaco' },
  { code: 'MN', call: '+976', name: 'Mongolia' },
  { code: 'ME', call: '+382', name: 'Montenegro' },
  { code: 'MS', call: '+1', name: 'Montserrat' },
  { code: 'MA', call: '+212', name: 'Morocco' },
  { code: 'MZ', call: '+258', name: 'Mozambique' },
  { code: 'MM', call: '+95', name: 'Myanmar' },
  { code: 'NA', call: '+264', name: 'Namibia' },
  { code: 'NR', call: '+674', name: 'Nauru' },
  { code: 'NP', call: '+977', name: 'Nepal' },
  { code: 'NL', call: '+31', name: 'Netherlands' },
  { code: 'NC', call: '+687', name: 'New Caledonia' },
  { code: 'NZ', call: '+64', name: 'New Zealand' },
  { code: 'NI', call: '+505', name: 'Nicaragua' },
  { code: 'NE', call: '+227', name: 'Niger' },
  { code: 'NG', call: '+234', name: 'Nigeria' },
  { code: 'NU', call: '+683', name: 'Niue' },
  { code: 'MP', call: '+1', name: 'Northern Mariana Islands' },
  { code: 'NO', call: '+47', name: 'Norway' },
  { code: 'OM', call: '+968', name: 'Oman' },
  { code: 'PK', call: '+92', name: 'Pakistan' },
  { code: 'PW', call: '+680', name: 'Palau' },
  { code: 'PS', call: '+970', name: 'Palestine, State of' },
  { code: 'PA', call: '+507', name: 'Panama' },
  { code: 'PG', call: '+675', name: 'Papua New Guinea' },
  { code: 'PY', call: '+595', name: 'Paraguay' },
  { code: 'PE', call: '+51', name: 'Peru' },
  { code: 'PH', call: '+63', name: 'Philippines' },
  { code: 'PL', call: '+48', name: 'Poland' },
  { code: 'PT', call: '+351', name: 'Portugal' },
  { code: 'PR', call: '+1', name: 'Puerto Rico' },
  { code: 'QA', call: '+974', name: 'Qatar' },
  { code: 'RO', call: '+40', name: 'Romania' },
  { code: 'RU', call: '+7', name: 'Russian Federation' },
  { code: 'RW', call: '+250', name: 'Rwanda' },
  {
    code: 'SH',
    call: '+247',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { code: 'KN', call: '+1', name: 'Saint Kitts and Nevis' },
  { code: 'LC', call: '+1', name: 'Saint Lucia' },
  { code: 'PM', call: '+508', name: 'Saint Pierre and Miquelon' },
  { code: 'VC', call: '+1', name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', call: '+685', name: 'Samoa' },
  { code: 'SM', call: '+378', name: 'San Marino' },
  { code: 'ST', call: '+239', name: 'Sao Tome and Principe' },
  { code: 'SA', call: '+966', name: 'Saudi Arabia' },
  { code: 'SN', call: '+221', name: 'Senegal' },
  { code: 'RS', call: '+381', name: 'Serbia' },
  { code: 'SC', call: '+248', name: 'Seychelles' },
  { code: 'SL', call: '+232', name: 'Sierra Leone' },
  { code: 'SG', call: '+65', name: 'Singapore' },
  { code: 'SX', call: '+1', name: 'Sint Maarten (Dutch part)' },
  { code: 'SK', call: '+421', name: 'Slovakia' },
  { code: 'SI', call: '+386', name: 'Slovenia' },
  { code: 'SB', call: '+677', name: 'Solomon Islands' },
  { code: 'SO', call: '+252', name: 'Somalia' },
  { code: 'ZA', call: '+27', name: 'South Africa' },
  { code: 'SS', call: '+211', name: 'South Sudan' },
  { code: 'ES', call: '+34', name: 'Spain' },
  { code: 'LK', call: '+94', name: 'Sri Lanka' },
  { code: 'SD', call: '+249', name: 'Sudan' },
  { code: 'SR', call: '+597', name: 'Suriname' },
  { code: 'SZ', call: '+268', name: 'Swaziland' },
  { code: 'SE', call: '+46', name: 'Sweden' },
  { code: 'CH', call: '+41', name: 'Switzerland' },
  { code: 'SY', call: '+963', name: 'Syrian Arab Republic' },
  { code: 'TW', call: '+886', name: 'Taiwan, Province of China' },
  { code: 'TJ', call: '+992', name: 'Tajikistan' },
  { code: 'TZ', call: '+255', name: 'Tanzania, United Republic of' },
  { code: 'TH', call: '+66', name: 'Thailand' },
  { code: 'TL', call: '+670', name: 'Timor-Leste' },
  { code: 'TG', call: '+228', name: 'Togo' },
  { code: 'TK', call: '+690', name: 'Tokelau' },
  { code: 'TO', call: '+676', name: 'Tonga' },
  { code: 'TT', call: '+1', name: 'Trinidad and Tobago' },
  { code: 'TN', call: '+216', name: 'Tunisia' },
  { code: 'TR', call: '+90', name: 'Turkey' },
  { code: 'TM', call: '+993', name: 'Turkmenistan' },
  { code: 'TC', call: '+1', name: 'Turks and Caicos Islands' },
  { code: 'TV', call: '+688', name: 'Tuvalu' },
  { code: 'UG', call: '+256', name: 'Uganda' },
  { code: 'UA', call: '+380', name: 'Ukraine' },
  { code: 'AE', call: '+971', name: 'United Arab Emirates' },
  { code: 'GB', call: '+44', name: 'United Kingdom' },
  { code: 'UY', call: '+598', name: 'Uruguay' },
  { code: 'UZ', call: '+998', name: 'Uzbekistan' },
  { code: 'VU', call: '+678', name: 'Vanuatu' },
  { code: 'VE', call: '+58', name: 'Venezuela, Bolivarian Republic of' },
  { code: 'VN', call: '+84', name: 'Viet Nam' },
  { code: 'WF', call: '+681', name: 'Wallis and Futuna' },
  { code: 'YE', call: '+967', name: 'Yemen' },
  { code: 'ZM', call: '+260', name: 'Zambia' },
  { code: 'ZW', call: '+263', name: 'Zimbabwe' },
];
