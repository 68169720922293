import React from 'react';
import { RadioGroup } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import Anim from 'components/animations';
import classNames from 'classnames';

import './RadioGroupField.styles.scss';

type RadioGroupFieldProps = FieldRenderProps<string, HTMLElement>;

const RadioGroupField: React.FC<RadioGroupFieldProps> = (props) => {
  const {
    options,
    input,
    wrapperClassName,
    meta: { error, touched },
    ...rest
  } = props;

  const classes = classNames('radio-group-wrapper', wrapperClassName);

  return (
    <div className={classes}>
      <RadioGroup options={options} {...rest} {...input} />
      <Anim.Collapse active={!!error && touched}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
    </div>
  );
};

export default RadioGroupField;
