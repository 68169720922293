import { ParamsActionTypes } from 'store/actions/params.actions';
import { Params } from 'types';
import utils from 'utils';

export function createParamsReducer(
  reducerPath: string,
  initialState?: Params,
) {
  return function (state: Params = initialState, action: any) {
    switch (action.type) {
      case `${reducerPath}/${ParamsActionTypes.INITIALIZE}`:
        return action.payload;
      case `${reducerPath}/${ParamsActionTypes.PAGE_CHANGE}`: {
        return { ...state, $page: action.payload };
      }
      case `${reducerPath}/${ParamsActionTypes.SEARCH_STRING_CHANGE}`: {
        return { ...state, $searchString: action.payload };
      }
      case `${reducerPath}/${ParamsActionTypes.SORT_COLUMN}`: {
        return {
          ...state,
          $sort: utils.calculateSortValue(state?.$sort, action.payload),
          $page: 1,
        };
      }
      case `${reducerPath}/${ParamsActionTypes.ON_FILTER}`: {
        return {
          ...state,
          $where: { ...state.$where, ...action.payload },
          $page: 1,
        };
      }
      case `${reducerPath}/${ParamsActionTypes.SORT}`: {
        return {
          ...state,
          $sort: action.payload,
        };
      }
      case `${reducerPath}/${ParamsActionTypes.RESET_FILTERS}`: {
        const { $where, ...rest } = state;
        return rest;
      }
      case `${reducerPath}/${ParamsActionTypes.RESET_SORT}`: {
        const { $sort, ...rest } = state;
        return rest;
      }
      case `${reducerPath}/${ParamsActionTypes.RESET}`: {
        return initialState;
      }
      default:
        return state;
    }
  };
}
