import { useMemo } from 'react';

export default (subjects: string[]) => {
  const options = useMemo(() => {
    return subjects?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [subjects]);

  return options;
};
