import React, { useCallback } from 'react';
import classNames from 'classnames';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';
import usePersonalInformationList from './hooks/usePersonalInformationList';
import {
  useGetTeacherQuery,
  useTeacherTestFlagMutation,
} from 'api/teachers.api';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import useLoginDetailsList from './hooks/useLoginDetailsList';
import useAccountDetailsList from './hooks/useAccountDetailsList';

import './TeacherAccountDetails.styles.scss';
import { Field, Form } from 'react-final-form';
import ToggleField from 'components/ToggleField';
import { Button } from 'ncoded-component-library';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

type TeacherAccountDetailsProps = {
  className?: string;
};

const TeacherAccountDetails: React.FC<TeacherAccountDetailsProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const { teacherId } = useParams();
  const { isLoading, data: teacher } = useGetTeacherQuery(teacherId);

  const classes = classNames('teacher-account-details', className);

  const personalInfomationList = usePersonalInformationList();
  const loginDetailsList = useLoginDetailsList();
  const accountDetailsList = useAccountDetailsList();

  const [testFlagUpdate] = useTeacherTestFlagMutation();
  const dispatch = useAppDispatch();

  const { matchingInformation } = teacher || {};

  const { isForTesting } = matchingInformation || {};

  const handleisForTesting = useCallback(
    async ({ isForTesting }: { isForTesting: boolean }) => {
      await testFlagUpdate({
        teacherId,
        isForTesting,
      })
        .unwrap()
        .then(() => dispatch(popSuccess(t('changedAccTypeSuccess'))))
        .catch((e) => dispatch(popServerError(e)));
    },
    [dispatch, t, teacherId, testFlagUpdate],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <ReviewCardWrapper
        title={t('personalInformation')}
        list={personalInfomationList}
      />
      <ReviewCardWrapper
        title={t('accountDetails')}
        list={accountDetailsList}
      />
      <ReviewCardWrapper title={t('loginDetails')} list={loginDetailsList} />
      <div className="teacher-account-details__account-type">
        <h5>{t('accountType')}</h5>
        <Form
          onSubmit={handleisForTesting}
          initialValues={{ isForTesting: !!isForTesting }}
          render={({ handleSubmit, dirty, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="isForTesting"
                component={ToggleField}
                label={t('isForTesting')}
              />
              {dirty && (
                <Button type="submit" disabled={submitting}>
                  {t('changeAccountType')}
                </Button>
              )}
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default TeacherAccountDetails;
