import { adminsApi } from 'api/admins.api';
import { authApi } from 'api/auth.api';
import { schoolGroupsApi } from 'api/schoolGroups.api';
import { teachersApi } from 'api/teachers.api';
import { User } from 'models/User';
import { Role } from 'searchality-data';
import credentialsService from './credentialsService';

export default {
  checkRolesForUser(user: User, roles: Role[]) {
    return roles.includes(user.role);
  },
  logout() {
    credentialsService.removeAuthBody();
    authApi.util.resetApiState();
    schoolGroupsApi.util.resetApiState();
    adminsApi.util.resetApiState();
    teachersApi.util.resetApiState();
  },
};
