import React, { useMemo } from 'react';
import classNames from 'classnames';
import PageLoader from 'components/PageLoader';
import GoBackButton from 'components/GoBackButton';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QualificationsService from 'services/Qualifications.service';
import { useGetCandidateQuery } from 'api/schoolGroups.api';
import ProfileMetadata from 'router/subrouters/Dashboard/components/Profile/components/ProfileMetadata';
import CandidateProfileQualifications from '../VacancyCandidates/components/CandidateProfileQualifications';
import ProfileComments from './ProfileComments';
import Profile from 'router/subrouters/Dashboard/components/Profile';
import ProfileActions from 'router/subrouters/Dashboard/components/Profile/components/ProfileActions';
import ProfileContact from './ProfileContact';
import { extractProfileInfoFromCandidate } from 'router/subrouters/Dashboard/components/Profile/utils';

import './CandidateProfile.styles.scss';
import { ApplicationType } from 'searchality-data';

type CandidateProfileProps = {
  className?: string;
};

const CandidateProfile: React.FC<CandidateProfileProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const { candidateId, schoolGroupId } = useParams();

  const { data: candidate, isLoading } = useGetCandidateQuery({
    schoolGroupId,
    candidateId,
  });

  const requirementsData = useMemo(() => {
    if (!candidate?.qualifications) return [];

    const { numberOfMatchingFields, numberOfTotalFields, ...rest } =
      candidate.qualifications;

    const languagesDontMatch =
      QualificationsService.getCandidateLanguagesMatch(candidate);

    const curriculumExperienceAdditionalDescription =
      QualificationsService.getCurriculumExperienceAdditionalDescription(
        candidate,
      );

    const languagesDontMatchText = languagesDontMatch
      .map(({ language, level }) => {
        const { vacancy } = candidate;
        const { requiredLanguages } = vacancy || {};

        const requiredProficiencyLevel = requiredLanguages.find(
          ({ language: l }) => language === l,
        )?.level;

        return t('QualificationDescription.languageExplanation', {
          language,
          requiredProficiencyLevel,
          level,
        });
      })
      .join('\n\n');

    return Object.keys(rest).map((qualKey) => ({
      fitCriteria: candidate.qualifications[qualKey as keyof typeof rest],
      title: t(`QualificationDescription.title.${qualKey}`),
      requirement: t(`QualificationDescription.requirement.${qualKey}`, {
        [qualKey]: QualificationsService.getVacancyValue(
          qualKey as keyof typeof rest,
          candidate?.vacancy,
        ),
        ...(qualKey === 'yearsOfExperience' && {
          count: candidate?.vacancy?.['yearsOfExperienceRequired'],
        }),
      }),
      ...(qualKey !== 'yearsOfExperience' &&
        !(
          qualKey === 'educationLevel' &&
          candidate?.type === ApplicationType.NON_ACADEMIC
        ) && {
          candidateHave: t(
            `QualificationDescription.candidateHave.${qualKey}`,
            {
              [qualKey]: QualificationsService.getCandidateValue(
                qualKey as keyof typeof rest,
                candidate,
              ),
            },
          ),
        }),
      ...(qualKey === 'languages' && {
        additionalDescription: languagesDontMatch.length
          ? languagesDontMatchText
          : '',
      }),
      ...(qualKey === 'curriculumExperience' && {
        additionalDescription: curriculumExperienceAdditionalDescription,
      }),
    }));
  }, [candidate, t]);

  if (isLoading) {
    return <PageLoader />;
  }

  const { appliedAt, statusInSchool } = candidate;

  const baseClass = 'candidate-profile';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__left`}>
        <GoBackButton />
        <ProfileMetadata
          schools={candidate?.schools}
          appliedAt={appliedAt}
          statusInSchool={statusInSchool}
          positionTitle={candidate?.vacancy?.positionTitle}
          schoolGroupInfo={`${
            candidate?.type === ApplicationType.NON_ACADEMIC
              ? candidate?.vacancy?.category
              : candidate?.vacancy?.subjectGradeLevel
          } - ${candidate?.schoolGroup?.postcode ?? ''} ${
            candidate?.schoolGroup?.state ?? candidate?.schools?.[0]?.state
          }`}
        />
        <CandidateProfileQualifications requirementsData={requirementsData} />
        <ProfileComments
          candidateId={candidateId}
          schoolGroupId={schoolGroupId}
        />
        <ProfileContact
          phoneNumber={candidate?.phoneNumber}
          email={candidate?.email}
        />
      </div>
      <div className={`${baseClass}__right`}>
        <ProfileActions
          statusInSchool={statusInSchool}
          candidateId={candidateId}
        />
        <Profile cardInfo={extractProfileInfoFromCandidate(candidate)} />
      </div>
    </div>
  );
};

export default CandidateProfile;
