import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './MembersPaginationInfo.styles.scss';

type MembersPaginationInfoProps = {
  className?: string;
  numberOfSchools: number;
  numberOfMembers: number;
  filteredRole?: string;
};

const MembersPaginationInfo: React.FC<MembersPaginationInfoProps> = (props) => {
  const { className, numberOfMembers, numberOfSchools, filteredRole } = props;

  const { t } = useTranslation();

  const classes = classNames('members-pagination-info', className);

  const memberRoleTranslation = useMemo(() => {
    if (!filteredRole) {
      return numberOfMembers === 1 ? t('Members.member') : t('Members.members');
    }

    return t(
      `MembersPaginationInfo.${filteredRole}${numberOfMembers > 1 ? 's' : ''}`,
    );
  }, [filteredRole, numberOfMembers, t]);

  return (
    <p className={classes}>
      {t('Members.paginationInfo', {
        numberOfSchools,
        numberOfMembers,
        school:
          numberOfSchools === 1 ? t('Members.school') : t('Members.schools'),
        member: memberRoleTranslation,
      })}
    </p>
  );
};

export default MembersPaginationInfo;
