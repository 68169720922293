import { useGetTeacherQuery } from 'api/teachers.api';
import PageLoader from 'components/PageLoader';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import TeacherProfileHeader from './components/TeacherProfileHeader';

import './TeacherProfile.styles.scss';

const TeacherProfile: React.FC = () => {
  const { teacherId } = useParams();
  const { isLoading } = useGetTeacherQuery(teacherId);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="teacher-profile">
      <TeacherProfileHeader />
      <Outlet />
    </div>
  );
};

export default TeacherProfile;
