import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Heading from 'components/Heading';
import useAppDispatch from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate } from 'react-router-dom';
import paramsActions from 'store/actions/params.actions';
import PageLoader from 'components/PageLoader';
import PaginationInfo from 'components/PaginationInfo';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import useAssociationsTableHeader from '../../hooks/useAssociationsTableHeader';
import useTeachersTableData from '../../hooks/useAssociationsTableData';
import { useGetAssociationsQuery } from 'api/associations.api';
import { Button } from 'ncoded-component-library';
import { ReactComponent as SearchIcon } from 'icons/Search.svg';
import DebouncedInput from 'components/DebouncedInput';
import { batch } from 'react-redux';

import './Associations.styles.scss';

type AssociationsProps = {
  className?: string;
};

const Associations: React.FC<AssociationsProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useAppSelector(({ associationsParams }) => associationsParams);
  const { data, isLoading } = useGetAssociationsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const associationsTableHeader = useAssociationsTableHeader();

  const teachersTableData = useTeachersTableData(data?.items || []);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onPaginationChange = useCallback(
    (pageNumber: number) => {
      dispatch(
        paramsActions.changeCurrentPage('associationsParams', pageNumber),
      );
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    },
    [dispatch],
  );

  const handleAssociationRowClick = useCallback(
    (_id?: string) => {
      if (_id) return navigate(`${_id}`);
    },
    [navigate],
  );

  const onSearchStringChange = useCallback(
    (searchString: string) => {
      batch(() => {
        dispatch(
          paramsActions.applySearchString('associationsParams', searchString),
        );
        dispatch(paramsActions.changeCurrentPage('associationsParams', 1));
      });
    },
    [dispatch],
  );

  const onTableColumnClick = useCallback(
    (value: string) => {
      dispatch(paramsActions.sortColumn('associationsParams', value));
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(paramsActions.applySearchString('associationsParams', ''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const { pagination } = data || {};

  const classes = classNames('associations', className);

  return (
    <div className={classes} ref={scrollRef}>
      <Heading title={t('Associations.title')}>
        <Button onClick={() => navigate('create-association')}>
          {t('Associations.createButton')}
        </Button>
      </Heading>
      <DebouncedInput
        onValueChange={onSearchStringChange}
        prefixNode={<SearchIcon />}
        placeholder={t('Associations.debouncedPlaceholder')}
      />
      <PaginationInfo
        name={t('associationsSmall', { count: pagination?.totalItems })}
        limit={params?.$limit}
        {...pagination}
      />
      {pagination?.totalItems > 0 && (
        <Table
          tableColumns={associationsTableHeader}
          tableRows={teachersTableData}
          handleRowClick={handleAssociationRowClick}
          params={params}
          handleHeaderClick={onTableColumnClick}
        />
      )}
      {pagination?.totalPages > 1 && (
        <Pagination onChange={onPaginationChange} {...pagination} />
      )}
    </div>
  );
};

export default Associations;
