import {
  useGetTeacherApplicationsQuery,
  useGetTeacherQuery,
} from 'api/teachers.api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DateService from 'services/Date.service';

const useAccountDetailsList = () => {
  const { teacherId } = useParams();
  const { data } = useGetTeacherQuery(teacherId);
  const { data: applications } = useGetTeacherApplicationsQuery({
    teacherId,
    params: { $limit: 6 },
  });
  const { t } = useTranslation();

  const { lastLogin, createdAt } = data || {};

  const { totalItems } = applications || {};

  return [
    {
      value: DateService.getUSADateFormatLongWithTime(createdAt),
      label: t('accountCreatedAt'),
    },
    {
      value: lastLogin
        ? DateService.getUSADateFormatLongWithTime(lastLogin)
        : 'N/A',
      label: t('lastLogin'),
    },
    {
      value: `${totalItems}`,
      label: t('totalVacanciesAppliedTo'),
    },
  ];
};

export default useAccountDetailsList;
