import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SchoolCard from '../SchoolCard';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

import './SchoolsOfGroup.styles.scss';

const SchoolsOfGroup: React.FC = () => {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { schools } = schoolGroup || {};

  const { t } = useTranslation();

  const navigate = useNavigate();

  const onCardClick = useCallback(
    (id: string) => {
      navigate(`${id}`);
    },
    [navigate],
  );

  return (
    <div className="schools-of-group">
      <p className="schools-of-group__description">{t('schoolsListed')}</p>
      <div className="schools-of-group__list">
        {schools?.map((school) => (
          <SchoolCard key={school._id} onClick={onCardClick} {...school} />
        ))}
      </div>
    </div>
  );
};

export default SchoolsOfGroup;
