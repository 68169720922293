import React, { useCallback } from 'react';
import { useInviteAdminMutation } from 'api/admins.api';
import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import useAppDispatch from 'hooks/useAppDispatch';
import { Button, Modal } from 'ncoded-component-library';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { composeValidators, emailValidation, required } from 'validations';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

import './InviteAdminModal.styles.scss';

const InviteAdminModal: React.ForwardRefRenderFunction<
  ModalRef,
  OverlayProps
> = (props, ref) => {
  const { onClose } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [inviteAdmin] = useInviteAdminMutation();

  const onSubmit = useCallback(
    (values: { firstName: string; lastName: string; email: string }) => {
      inviteAdmin(values)
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('Admins.adminInviteSuccess')));
          onClose();
        })
        .catch((err) => {
          dispatch(popServerError(err));
        });
    },
    [dispatch, inviteAdmin, onClose, t],
  );

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset }, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            renderAsPortal={false}
            ref={ref}
            title={t('inviteAdminModal.title')}
            className="invite-admin-modal"
            onClose={() => {
              reset();
            }}
            footer={
              <div>
                <Button
                  variant="outline"
                  disabled={submitting}
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <Button variant="solid" type="submit" disabled={submitting}>
                  {t('invite')}
                </Button>
              </div>
            }
          >
            <>
              <p>{t('inviteAdminModal.description')}</p>
              <FormFieldLabel text={t('inviteAnotherMember')} />
              <div className="invite-admin-modal__fields">
                <Field
                  name="firstName"
                  component={InputField}
                  placeholder={t('firstName')}
                  required
                  validate={required()}
                />
                <Field
                  name="lastName"
                  component={InputField}
                  placeholder={t('lastName')}
                  required
                  validate={required()}
                />
              </div>
              <div className="invite-admin-modal__fields">
                <Field
                  name="email"
                  component={InputField}
                  placeholder={t('email')}
                  required
                  validate={composeValidators(required(), emailValidation())}
                />
              </div>
            </>
          </Modal>
        </form>
      )}
    </Form>
  );
};

export default React.forwardRef(InviteAdminModal);
