import React, { useMemo } from 'react';
import classNames from 'classnames';
import { NavLink, useNavigate } from 'react-router-dom';
import useNavOptions from './hooks/useNavOptions';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoutIcon } from 'icons/Logout.icon.svg';
import authService from 'services/authService';

import './HeaderNavigation.styles.scss';

type HeaderNavigationProps = {
  className?: string;
};

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {
  const { className } = props;
  const navigate = useNavigate();

  const classes = classNames('header-navigation', className);

  const NAV_OPTIONS = useNavOptions();

  const { t } = useTranslation();

  const navigationItems = useMemo(
    () =>
      NAV_OPTIONS?.map(({ to, label, Icon }) => (
        <NavLink
          to={to}
          key={to}
          className={({ isActive }) =>
            classNames('header-navigation__nav-link', {
              active: isActive,
            })
          }
        >
          <>
            {Icon}
            <span>{t(label)}</span>
          </>
        </NavLink>
      )),
    [NAV_OPTIONS, t],
  );

  return (
    <nav className={classes}>
      {navigationItems}
      <button
        onClick={() => {
          authService.logout();
          navigate('auth/login');
        }}
        className="header-navigation__nav-link"
      >
        <LogoutIcon />
        <span>{t('logout')}</span>
      </button>
    </nav>
  );
};

export default HeaderNavigation;
