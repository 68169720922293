import { School } from 'models/School';
import { User } from 'models/User';
import { ReactNode } from 'react';
import {
  ApplicationSchoolStatus,
  SchoolGroupType,
  SubscriptionCancellationReason,
  VacancyType,
} from 'searchality-data';

export type ExtractPropTypes<Type> = Type extends React.FC<infer P> ? P : never;

export type StructureType = 'standalone' | 'district' | 'group';

export type CreateSchoolValues = {
  type: SchoolGroupType;
  structureType: StructureType;
  name?: string;
  website?: string;
};

export type ReviewCardItemList = {
  label: string;
  value: string;
  description?: string;
  className?: string;
}[];

export type TableHeaderElement = {
  id: string;
  label: string;
};

export type MongoWhere = {
  $all?: (string | boolean | number)[];
  $in?: (string | boolean | number)[];
};

export type PhoneNumber = {
  countryCode: string;
  phoneNumber: string;
};

export type Params = {
  $page?: number;
  $limit?: number;
  $sort?: string;
  $where?: {
    [name: string]: MongoWhere | any;
  };
  $projection?: string[];
  $populate?: string[];
  status?: string;
  $searchString?: string;
};

export type PaginationProps = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
};

export type PaginatedResponse<T> = {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: T[];
};

export type TransformedPaginatedResponse<T> = {
  pagination: PaginationProps;
  items: T[];
};

export type RegisterData = LoginData & {
  firstName: string;
  lastName: string;
  isSubscribed?: boolean;
};

export type LoginData = {
  email: string;
  password: string;
};

export type AuthBody = {
  token: string;
  refreshToken: string;
};

export type GlobalFilters = {
  subjects: string[];
  divisions: string[];
  schools: string[];
};

export type ApplicationsFilters = {
  subjects: string[];
  divisions: string[];
  statuses: string[];
};

export type MapboxTypes =
  | 'place'
  | 'district'
  | 'country'
  | 'region'
  | 'address';

export type PositionRequirementsType = {
  startDate: string;
  requiredLanguages: {
    language: string;
    level: string;
  }[];
  curriculumExperience: string;
};

export type AddressObject = {
  city: string;
  country: string;
  postcode: string;
  countryShortCode: string;
  state: string;
};
export type VacancyFilters = {
  subjects: Record<string, string>;
  divisions?: Record<string, string>;
  schools?: School[];
  categories?: string[];
  types?: VacancyType[];
};

export type GetCommentsType = {
  $sort: string;
  $populate: string | string[];
  $limit: number;
  $page: number;
};

export type BillingAddress = {
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  postal_code: string;
  state: string;
};

export type PaymentCard = {
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  last4: number;
};

export type PaymentUSBA = {
  bank_name: string;
  last4: string;
};

export type PaymentMethodUSBA = {
  type: 'us_bank_account';
  usBankAccount: PaymentUSBA;
};

export type PaymentMethodCard = {
  type: 'card';
  card: PaymentCard;
};

export type PaymentMethod = {
  created: number;
  fullName?: string;
  billingAddress?: BillingAddress;
  isDefault?: boolean;
} & (PaymentMethodUSBA | PaymentMethodCard);

export type ApplicationSchoolStatusWithFavourites =
  | ApplicationSchoolStatus
  | 'Favourite';

export type InvoiceItem = {
  amount?: number;
  description?: string;
  quantity?: number;
};

export type Invoice = {
  id: string;
  amountDue: number;
  amountRemaining: number;
  status: string;
  createdAt: string;
  hostedInvoiceUrl: string;
  items: InvoiceItem[];
};

export type UnsubscribeValues = {
  reasons: SubscriptionCancellationReason[];
  otherReason?: string;
};

export type TableColumn = {
  value: string;
  text: string;
  sortable?: boolean;
};

type TableData = {
  [name: string]: ReactNode;
};

export type TableRow = {
  id: string;
  data: TableData;
};

export type Comment = {
  content: string;
  author: Partial<User>;
  isAdding: boolean;
};

export enum TwoFactorAuthenticationMethod {
  AUTHENTICATOR_APP = 'AUTHENTICATOR_APP',
  EMAIL = 'EMAIL',
}
