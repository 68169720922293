import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Header from '../Header';

import './MainLayout.styles.scss';

type MainProps = {
  children: ReactNode;
  className?: string;
  headerShadow?: boolean;
  isAuth?: boolean;
};

export const MainLayout: React.FC<MainProps> = ({
  className,
  children,
  headerShadow = false,
  isAuth = false,
}) => (
  <div className={classNames('main-layout', { auth: isAuth }, className)}>
    <Header shadow={headerShadow} />
    <main>
      <article>{children}</article>
    </main>
  </div>
);

export default MainLayout;
