import React, { useCallback, useEffect, useState } from 'react';
import ValueContext from './Value.context';

type ValueContextProviderProps = {
  children: React.ReactNode;
  isMultiple?: boolean;
  initialIds?: string[];
};

const ValueContextProvider: React.FC<ValueContextProviderProps> = (props) => {
  const { children, isMultiple, initialIds } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const manageId = useCallback(
    (selectedId: string) => {
      if (isMultiple) {
        setSelectedIds((prev) =>
          prev.includes(selectedId)
            ? prev.filter((id) => id !== selectedId)
            : [...prev, selectedId],
        );
      } else {
        setSelectedIds([selectedId]);
      }
    },
    [isMultiple],
  );

  const clearIds = useCallback(
    () => setSelectedIds(initialIds ?? []),
    [initialIds],
  );

  useEffect(() => {
    if (initialIds) {
      setSelectedIds(initialIds);
    }
  }, [initialIds]);

  return (
    <ValueContext.Provider
      value={{
        selectedIds,
        manageId,
        clearIds,
      }}
    >
      {children}
    </ValueContext.Provider>
  );
};

export default ValueContextProvider;
