import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as XIcon } from 'icons/CloseDialog.icon.svg';

import './PopNotification.styles.scss';
import './PopNotification.responsive.styles.scss';

type NotificationType = 'Success' | 'Error' | 'Info';

export type PopNotificationProps = {
  className?: string;
  notId?: string;
  side?:
    | 'top'
    | 'top-right'
    | 'top-left'
    | 'bottom'
    | 'bottom-right'
    | 'bottom-left';
  closable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onClose?: () => void;
  dissappearAfter?: number;
  children?: React.ReactNode;
  type?: NotificationType;
};

export type PopNotificationRef = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PopNotification: React.ForwardRefRenderFunction<
  PopNotificationRef,
  PopNotificationProps
> = (props, ref) => {
  const [open, setOpen] = useState(true);

  const {
    className,
    side = 'bottom-right',
    closable = true,
    onClick,
    onClose,
    dissappearAfter = 5000,
    type = 'Info',
    children,
  } = props;

  const timeoutRef = useRef<NodeJS.Timeout | number>();
  const classes = classNames(
    'pop-notification',
    { 'pop-notification--error': type === 'Error' },
    { 'pop-notification--success': type === 'Success' },
    { 'pop-notification--info': type === 'Info' },
    side.split('-').join(' '),
    className,
  );

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, dissappearAfter);

    return () => {
      clearTimeout(timeoutRef.current as number);
    };
  }, [dissappearAfter]);

  const refVal = useRef<PopNotificationRef>({ open, setOpen });

  useImperativeHandle(
    ref,
    () => {
      refVal.current.open = open;
      return refVal.current;
    },
    [open],
  );

  return (
    <CSSTransition
      timeout={250}
      in={open}
      classNames={{
        appear: 'pop-notification-transition-appear',
        appearActive: 'pop-notification-transition-appear-active',
        appearDone: 'pop-notification-transition-appear-done',
        enter: 'pop-notification-transition-enter',
        enterActive: 'pop-notification-transition-enter-active',
        enterDone: 'pop-notification-transition-enter-done',
        exit: 'pop-notification-transition-exit',
        exitActive: 'pop-notification-transition-exit-active',
        exitDone: 'pop-notification-transition-exit-done',
      }}
      exit
      appear
      unmountOnExit
      onExited={() => {
        clearTimeout(timeoutRef.current as number);
        onClose?.();
      }}
    >
      <div
        className={classes}
        onClick={(ev) => {
          onClick?.(ev);
          setOpen(false);
        }}
      >
        {closable && (
          <button
            className="pop-notification__close"
            onClick={() => setOpen(false)}
          >
            <XIcon />
          </button>
        )}
        {children}
      </div>
    </CSSTransition>
  );
};

export default forwardRef(PopNotification);
