import React from 'react';
import classNames from 'classnames';
import { School } from 'models/School';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { ReactComponent as LinkIcon } from 'icons/Link.icon.svg';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';

import './AssociationSchoolCard.styles.scss';

type AssociationSchoolCardProps = {
  className?: string;
  onClick?: () => void;
  onDelete?: (schoolId: string) => void;
} & Partial<School>;

const AssociationSchoolCard: React.FC<AssociationSchoolCardProps> = (props) => {
  const {
    className,
    state,
    _id,
    name,
    schoolGroup,
    associationContactDetails,
    numberOfOpenVacancies,
    onClick,
    onDelete,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'association-school-card';
  const classes = classNames(baseClass, className);

  return (
    <li
      className={classes}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <div className={`${baseClass}__top`}>
        <p className={`${baseClass}__top__state`}>{`${state}`}</p>
        <p className={`${baseClass}__top__name`}>{name}</p>
        <p className={`${baseClass}__top__type`}>
          {t(`SchoolCardSchoolType.${schoolGroup?.type}`)}
        </p>
      </div>
      <div className={`${baseClass}__bottom`}>
        <div className={`${baseClass}__bottom__contact-information`}>
          <p className={`${baseClass}__bottom__contact-information__name`}>
            {associationContactDetails?.fullName}
          </p>
          <p className={`${baseClass}__bottom__contact-information__email`}>
            {associationContactDetails?.email}
          </p>
        </div>
        <div className={`${baseClass}__bottom__total-vacancies`}>
          <p className={`${baseClass}__bottom__total-vacancies__number`}>
            {numberOfOpenVacancies || '0'}
          </p>
          <label className={`${baseClass}__bottom__total-vacancies__total`}>
            {t('totalVacancies')}
          </label>
        </div>
        <div className={`${baseClass}__bottom__view-on-website`}>
          {t('viewOnSchoolsWebiste')}
        </div>
        <div className={`${baseClass}__bottom__actions`}>
          <label className={`${baseClass}__bottom__actions__label`}>
            {t('actions')}
          </label>
          <div className={`${baseClass}__bottom__actions__buttons`}>
            <Button
              className="svg-button-wrapper"
              icon={<LinkIcon />}
              onClick={onClick}
            />
            <Button
              className="svg-button-wrapper"
              icon={<CloseIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(_id);
              }}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default AssociationSchoolCard;
