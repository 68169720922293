import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ApplicationSchoolStatus } from 'searchality-data';
import { useGetCandidatesInfoQuery } from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';

import './VacancyCandidatesBoxFilters.styles.scss';

const VacancyCandidatesBoxFilters: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { schoolGroupId, vacancyId } = useParams();

  const params = useAppSelector((state) => state.schoolGroupVacancyCandidates);
  const { data: boxFilters, isLoading } = useGetCandidatesInfoQuery({
    schoolGroupId,
    vacancyId,
  });

  const currentStatus = useMemo(
    () => params?.$where?.statusInSchool?.$in?.[0],
    [params?.$where?.statusInSchool?.$in],
  );

  const getFilterCount = useCallback(
    (status: string) =>
      boxFilters?.find(({ statusInSchool }) => statusInSchool === status)
        ?.count || 0,
    [boxFilters],
  );

  const totalCount = useMemo(
    () =>
      boxFilters
        ?.filter(({ statusInSchool }) => statusInSchool !== 'Favourite')
        ?.reduce((acc, curr) => acc + curr.count, 0) || 0,
    [boxFilters],
  );

  const rejectCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.REJECTED),
    [getFilterCount],
  );

  const newCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.NEW),
    [getFilterCount],
  );

  const selectedCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.IN_PROCESS),
    [getFilterCount],
  );

  const favouriteCandidatesCount = useMemo(
    () => getFilterCount('Favourite'),
    [getFilterCount],
  );

  const handleVacancyCandiatesFilter = useCallback(
    (val: string | string[], count: number) => {
      if (count === 0) return;
      dispatch(
        paramsActions.onFilter('schoolGroupVacancyCandidates', {
          statusInSchool: {
            $in: Array.isArray(val) ? val : [val],
          },
        }),
      );
    },
    [dispatch],
  );

  const handleFavouriteCandidatesFilter = useCallback(
    (count: number) => {
      if (count === 0) return;
      dispatch(
        paramsActions.onFilter('schoolGroupVacancyCandidates', {
          isFavourite: true,
        }),
      );
    },
    [dispatch],
  );

  const classes = classNames('box-filters', {
    'box-filters--loading': isLoading,
  });

  return (
    <div className={classes}>
      <div
        className={classNames('box', {
          'box--active': currentStatus === undefined,
          'box--clickable': totalCount > 0,
        })}
        onClick={() =>
          dispatch(paramsActions.resetFilters('schoolGroupVacancyCandidates'))
        }
      >
        <h2>{totalCount}</h2>
        <p>{t('Candidates.total')}</p>
      </div>
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.NEW,
          'box--clickable': newCandidatesCount > 0,
        })}
        onClick={() =>
          handleVacancyCandiatesFilter(
            ApplicationSchoolStatus.NEW,
            newCandidatesCount,
          )
        }
      >
        <h2>{newCandidatesCount}</h2>
        <p>{t('Candidates.new')}</p>
      </div>
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.REJECTED,
          'box--clickable': rejectCandidatesCount > 0,
        })}
        onClick={() =>
          handleVacancyCandiatesFilter(
            ApplicationSchoolStatus.REJECTED,
            rejectCandidatesCount,
          )
        }
      >
        <h2>{rejectCandidatesCount}</h2>
        <p>{t('Candidates.rejected')}</p>
      </div>
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.IN_PROCESS,
          'box--clickable': selectedCandidatesCount > 0,
        })}
        onClick={() =>
          handleVacancyCandiatesFilter(
            ApplicationSchoolStatus.IN_PROCESS,
            selectedCandidatesCount,
          )
        }
      >
        <h2>{selectedCandidatesCount}</h2>
        <p>{t('Candidates.qualified')}</p>
      </div>
      <div
        className={classNames('box', {
          'box--clickable': favouriteCandidatesCount > 0,
          'box--active': currentStatus === 'Favourite',
        })}
        onClick={() => {
          handleFavouriteCandidatesFilter(favouriteCandidatesCount);
        }}
      >
        <h2>{favouriteCandidatesCount}</h2>
        <p>{t('Candidates.favorites')}</p>
      </div>
    </div>
  );
};

export default VacancyCandidatesBoxFilters;
