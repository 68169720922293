import {
  useDeactivateAssociationMutation,
  useGetAssociationQuery,
} from 'api/associations.api';
import GoBackButton from 'components/GoBackButton';
import Heading from 'components/Heading';
import PageLoader from 'components/PageLoader';
import TabsNavigation from 'components/TabsNavigation';
import { Button } from 'ncoded-component-library';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAssociationProfileTabs from './hooks/useAssociationProfileTabs';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import confirm from 'modules/confirm';
import classNames from 'classnames';

import './AssociationProfile.styles.scss';

const AssociationProfile: React.FC = () => {
  const { t } = useTranslation();

  const { associationId } = useParams();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetAssociationQuery(associationId);
  const navigate = useNavigate();
  const [deactivateAssociation] = useDeactivateAssociationMutation();

  const tabs = useAssociationProfileTabs();

  const handleAssociationDelete = useCallback(async () => {
    await confirm({
      title: t('DeactivateAssociation.title'),
      content: (
        <p>{t('DeactivateAssociation.description', { name: data?.name })}</p>
      ),
      onSubmit: () => {
        deactivateAssociation(associationId)
          .unwrap()
          .then(() => {
            dispatch(popSuccess(t('DeactivateAssociation.success')));
          })
          .catch((e) => dispatch(popServerError(e)));
      },
    });
  }, [associationId, data?.name, deactivateAssociation, dispatch, t]);

  if (isLoading) return <PageLoader />;

  const { name, isActive } = data;

  const isEdit = pathname.includes('edit-association');

  return (
    <div className="association-profile">
      <GoBackButton to="../" />
      {!isEdit && (
        <>
          <Heading title={name}>
            <div className="heading-buttons-group">
              <p
                className={classNames(`vacancy-status`, {
                  'vacancy-status--inactive': !isActive,
                })}
              >
                {t('status')}:&nbsp;{' '}
                <span>{isActive ? t('active') : t('inactive')}</span>
              </p>
              <Button
                variant="outline"
                onClick={() => navigate('edit-association')}
              >
                {t('editInformation')}
              </Button>
              {isActive && (
                <Button onClick={handleAssociationDelete} variant="outline">
                  {t('deactivateAssociation')}
                </Button>
              )}
            </div>
          </Heading>
          <TabsNavigation tabs={tabs} />
        </>
      )}
      <Outlet />
    </div>
  );
};

export default AssociationProfile;
