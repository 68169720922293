import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Heading from 'components/Heading';
import { useTranslation } from 'react-i18next';
import DebouncedInput from 'components/DebouncedInput';
import { ReactComponent as SearchIcon } from 'icons/Search.svg';
import { batch } from 'react-redux';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import PaginationInfo from 'components/PaginationInfo';
import { useGetTeachersQuery } from 'api/teachers.api';
import useAppSelector from 'hooks/useAppSelector';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import useTeachersTableHeader from '../../hooks/useTeachersTableHeader';
import useTeachersTableData from '../../hooks/useTeachersTableData';
import { useNavigate } from 'react-router-dom';

import './Teachers.styles.scss';

type TeachersProps = {
  className?: string;
};

const Teachers: React.FC<TeachersProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const params = useAppSelector(({ teachersParams }) => teachersParams);
  const { data, isLoading } = useGetTeachersQuery(params);
  const navigate = useNavigate();

  const teachersTableHeader = useTeachersTableHeader();

  const teachersTableData = useTeachersTableData(data?.items || []);

  const classes = classNames('teachers', className);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onSearchStringChange = useCallback(
    (searchString: string) => {
      batch(() => {
        dispatch(
          paramsActions.applySearchString('teachersParams', searchString),
        );
        dispatch(paramsActions.changeCurrentPage('teachersParams', 1));
      });
    },
    [dispatch],
  );

  const onPaginationChange = useCallback(
    (pageNumber: number) => {
      dispatch(paramsActions.changeCurrentPage('teachersParams', pageNumber));
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    },
    [dispatch],
  );

  const handleTeachersRowClick = useCallback(
    (_id?: string) => {
      if (_id) return navigate(`${_id}/profile`);
    },
    [navigate],
  );

  const handleTeachersHeaderClick = useCallback(
    (value: string) => {
      dispatch(paramsActions.sortColumn('teachersParams', value));
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(paramsActions.applySearchString('teachersParams', ''));
    };
  }, [dispatch]);

  if (isLoading) {
    return <PageLoader />;
  }

  const { pagination } = data;

  return (
    <div className={classes} ref={scrollRef}>
      <Heading title={t('Teachers.title')} />
      <div>
        <DebouncedInput
          onValueChange={onSearchStringChange}
          prefixNode={<SearchIcon />}
          placeholder={t('SchoolGroupsPage.debouncedPlaceholder')}
        />
      </div>
      <PaginationInfo
        name={t('teachersSmall', { count: pagination?.totalItems })}
        limit={params?.$limit}
        {...pagination}
      />
      {pagination?.totalItems > 0 && (
        <Table
          tableColumns={teachersTableHeader}
          tableRows={teachersTableData}
          handleHeaderClick={handleTeachersHeaderClick}
          handleRowClick={handleTeachersRowClick}
          params={params}
        />
      )}
      {pagination?.totalPages > 1 && (
        <Pagination onChange={onPaginationChange} {...pagination} />
      )}
    </div>
  );
};

export default Teachers;
