import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useSortAzOptions(value: string) {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t('AZ'),
        value,
      },
      {
        label: t('ZA'),
        value: `-${value}`,
      },
    ],
    [t, value],
  );
}
