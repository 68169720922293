import { useParams } from 'react-router-dom';

export default () => {
  const { schoolGroupId } = useParams();
  return [
    {
      to: `/school-groups/${schoolGroupId}/vacancies`,
      label: 'vacancies',
    },
    {
      to: `/school-groups/${schoolGroupId}/members`,
      label: 'members',
    },
    {
      to: `/school-groups/${schoolGroupId}/account`,
      label: 'account',
    },
  ];
};
