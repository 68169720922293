import React from 'react';
import { useTranslation } from 'react-i18next';

export type AcademicContractTermsProps = {
  studentContractDays: number;
  studentNonContractDays: number;
};

const AcademicContractTerms: React.FC<AcademicContractTermsProps> = (props) => {
  const { studentNonContractDays, studentContractDays } = props;
  const { t } = useTranslation();
  return (
    <ul>
      <li>
        {t('jobPreview.contractDays', {
          days: studentContractDays,
        })}
      </li>
      <li>
        {t('jobPreview.nonContractDays', {
          days: studentNonContractDays,
        })}
      </li>
    </ul>
  );
};

export default AcademicContractTerms;
