import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NonAcademicContractType } from 'searchality-data';

export type NonAcademicContractTermsProps = {
  contractDays: NonAcademicContractType;
  contractDaysDetails: string;
};

const NonAcademicContractTerms: React.FC<NonAcademicContractTermsProps> = (
  props,
) => {
  const { t } = useTranslation();

  const { contractDays, contractDaysDetails } = props;

  const shouldRender = useMemo(
    () => contractDays || contractDaysDetails,
    [contractDays, contractDaysDetails],
  );

  const shouldRenderContractDays = useMemo(
    () => contractDays && contractDays !== NonAcademicContractType.OTHER,
    [contractDays],
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <ul>
      {shouldRenderContractDays && (
        <li>
          {t('jobPreview.nonAcademicContractDays', {
            contractDays,
          })}
        </li>
      )}
      {contractDaysDetails && (
        <li>
          {t('jobPreview.nonAcademicContractDaysDetails', {
            details: contractDaysDetails,
          })}
        </li>
      )}
    </ul>
  );
};

export default NonAcademicContractTerms;
