import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import DateService from 'services/Date.service';
import Anim from 'components/animations';
import ValueContext from 'providers/ValueContext/Value.context';
import { useGetInvoicesQuery } from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';

import './InvoiceTable.styles.scss';
import './InvoiceTable.styles.responsive.scss';

type InvoiceTableProps = {
  className?: string;
};

const InvoiceTable: React.FC<InvoiceTableProps> = (props) => {
  const { className } = props;

  const { schoolGroupId } = useParams();
  const { selectedIds, manageId } = useContext(ValueContext);
  const { data: invoices } = useGetInvoicesQuery(schoolGroupId);

  const { t } = useTranslation();

  const classes = classNames('invoice', className);

  if (invoices?.length === 0) return null;

  return (
    <div className={classes}>
      <h5>{t('invoices')}</h5>
      <div className="invoice__table">
        <div className="invoice__table__header">
          <p>{t('InvoiceTable.title.amountDue')}</p>
          <p>{t('InvoiceTable.title.amountRemaining')}</p>
          <p>{t('InvoiceTable.title.status')}</p>
          <p>{t('InvoiceTable.title.createdAt')}</p>
          <p>{t('InvoiceTable.title.invoiceUrl')}</p>
        </div>
        <div className="invoice__table__content">
          {invoices?.map(
            ({
              id,
              status,
              amountDue,
              amountRemaining,
              createdAt,
              hostedInvoiceUrl,
              items,
            }) => {
              const statusClasses = classNames('status', {
                'status--paid': status === 'paid',
                'status--open': status === 'open',
              });

              return (
                <div
                  key={id}
                  className="invoice__table__row"
                  onClick={() => manageId(id)}
                >
                  <div className="invoice__table__row__main">
                    <p>{numberPriceToCurrencyString(amountDue)}</p>
                    <p>{numberPriceToCurrencyString(amountRemaining)}</p>
                    <p className={statusClasses}>{status}</p>
                    <p>{DateService.getDayMonthYear(createdAt)}</p>
                    <a href={hostedInvoiceUrl} target="_blank" rel="noreferrer">
                      {t('InvoiceTable.preview')}
                    </a>
                  </div>
                  <Anim.Collapse active={selectedIds.includes(id)}>
                    <div className="invoice__table__row__items">
                      {items.map(({ amount, description, quantity }, index) => (
                        <div key={index}>
                          <p className="descriptive">{`${description}, Quantity: ${quantity}, Amount: ${numberPriceToCurrencyString(
                            amount,
                          )}`}</p>
                        </div>
                      ))}
                    </div>
                  </Anim.Collapse>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
