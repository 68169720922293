import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PhoneNumber } from 'types';
import { ReactComponent as MailIcon } from 'icons/Mail.icon.svg';
import { ReactComponent as PhoneIcon } from 'icons/Contact.icon.svg';

import './ProfileContact.styles.scss';

type ProfileContactProps = {
  className?: string;
  email?: string;
  phoneNumber?: PhoneNumber;
};

const ProfileContact: React.FC<ProfileContactProps> = (props) => {
  const { className, email, phoneNumber } = props;

  const phone = useMemo(() => {
    if (!phoneNumber?.countryCode || !phoneNumber?.phoneNumber) return null;
    const { countryCode, phoneNumber: number } = phoneNumber;

    return `${countryCode}${number.replaceAll(/[^\d]/g, '')}`;
  }, [phoneNumber]);

  const { t } = useTranslation();

  const classes = classNames('searchality-profile-contact', className);

  return (
    <div className={classes}>
      <p className="title">{t('contactCandidate')}</p>
      {email && (
        <div>
          <MailIcon />
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
      {phone && (
        <div>
          <PhoneIcon />
          <a
            href={`tel:${phone}`}
          >{`${phoneNumber.countryCode} ${phoneNumber.phoneNumber}`}</a>
        </div>
      )}
    </div>
  );
};

export default ProfileContact;
