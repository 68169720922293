import React from 'react';
import utils from 'utils';
import classNames from 'classnames';

import './PhotoPreview.styles.scss';

type PhotoPreviewProps = {
  src?: string;
  placeHolderText?: string;
  backgroundColor?: string;
  className?: string;
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl';
};

const PhotoPreview: React.FC<PhotoPreviewProps> = (props) => {
  const {
    src,
    placeHolderText,
    className,
    backgroundColor,
    size = 'l',
  } = props;

  const classes = classNames(
    'photo-preview',
    `photo-preview--${size}`,
    className,
  );

  return (
    <div
      className={classes}
      style={{
        backgroundColor: !src ? backgroundColor || '#bdd8d9' : undefined,
        color: utils.lightOrDark(backgroundColor) === 'dark' ? '#fff' : '#000',
      }}
    >
      {src ? <img src={src} alt="preview" /> : <p>{placeHolderText}</p>}
    </div>
  );
};

export default PhotoPreview;
