import React from 'react';
import classNames from 'classnames';
import PaginationInfo from 'components/PaginationInfo';
import { useGetAssociationMembersQuery } from 'api/associations.api';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { useTranslation } from 'react-i18next';
import MembersTable from 'router/subrouters/Dashboard/subrouters/SchoolGroups/subrouters/SchoolGroupProfile/subrouters/SchoolGroupMembers/components/MembersTable';

import './AssociationMembers.styles.scss';

type AssociationMembersProps = {
  className?: string;
};

const AssociationMembers: React.FC<AssociationMembersProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { associationId } = useParams();
  const { data, isLoading } = useGetAssociationMembersQuery(associationId);

  const classes = classNames('association-members', className);

  if (isLoading) return <PageLoader />;

  const { items: members, ...pagination } = data;

  return (
    <div className={classes}>
      {members?.length ? (
        <>
          <PaginationInfo
            name={t('AssociationMembers.member', {
              count: pagination?.totalItems,
            })}
            {...pagination}
          />
          <MembersTable
            className="members-page__table"
            members={members}
            isAssociation
            isReadonly
          />
        </>
      ) : (
        t('currentlyNoMembersForAssociation')
      )}
    </div>
  );
};

export default AssociationMembers;
