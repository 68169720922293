import React from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import { popSuccess } from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DuplicateIcon } from 'icons/Duplicate.icon.svg';
import { Association } from 'models/Association';
import classNames from 'classnames';

const useTeachersTableData = (associations: Association[]) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return associations?.map(
    ({ _id, name, website, isActive, type, acronym }) => ({
      id: _id,
      data: {
        name,
        acronym,
        type,
        _id: (
          <button
            className="svg-button-wrapper"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(_id);
              dispatch(popSuccess(t('copyToClipboardSuccess')));
            }}
          >
            <DuplicateIcon />
            {_id}
          </button>
        ),
        website,
        accountStatus: (
          <p
            className={classNames('associations__table__account-status', {
              'associations__table__account-status--active': isActive,
            })}
          >
            {isActive ? t('active') : t('inactive')}
          </p>
        ),
      },
    }),
  );
};

export default useTeachersTableData;
