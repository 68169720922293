import React, { ReactNode } from 'react';
import classNames from 'classnames';
import TemplateIllustration from 'components/TemplateIllustration';

import './EmptyScreen.styles.scss';

type EmptyScreenProps = {
  className?: string;
  children?: ReactNode;
  img?: ReactNode;
  title: string;
  description?: string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
};

const EmptyScreen: React.FC<EmptyScreenProps> = (props) => {
  const { children, img, title, description, headingLevel, className } = props;

  const classes = classNames('empty-screen', className);

  return (
    <TemplateIllustration
      img={img}
      title={title}
      description={description}
      headingLevel={headingLevel}
      className={classes}
    >
      {children}
    </TemplateIllustration>
  );
};

export default EmptyScreen;
