import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Role, SchoolGroupStructureType } from 'searchality-data';

const useRolesSelectOptions = () => {
  const { t } = useTranslation();
  const { schoolGroupId } = useParams();

  const { data: { structureType } = {} } =
    useGetSchoolAccountQuery(schoolGroupId);

  const { Teacher, AssociationOwner, AssociationMember, ...rest } = Role;

  const options = useMemo(() => {
    let rolesOptions = Object.keys(rest).map((key) => ({
      label: t(`UserRoles.${key}`),
      value: Role[key as keyof typeof Role],
    }));
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      rolesOptions = rolesOptions.filter(
        (option) => option.value !== Role.AccountManager,
      );
    }
    return rolesOptions;
  }, [rest, structureType, t]);

  return options;
};

export default useRolesSelectOptions;
