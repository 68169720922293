import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Field, useField, useForm } from 'react-final-form';
import CountryPicker from 'components/CountryPicker';
import InputField from 'components/InputField';
import { ALL_COUNTRIES } from 'enums';
import { required } from 'validations';

import './PhoneNumberField.styles.scss';

type PhoneNumberFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  initialCountry?: string;
  className?: string;
  suffixNode?: React.ReactNode;
  disabled?: boolean;
  shouldValidate?: boolean;
  renderAsPortal?: boolean;
};

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = (props) => {
  const {
    name,
    label,
    className,
    suffixNode,
    disabled,
    renderAsPortal,
    initialCountry = 'US',
    shouldValidate = false,
  } = props;

  const { change } = useForm();

  const {
    input: { value },
  } = useField(name);

  const countryCodeValue = useMemo(
    () =>
      value?.countryCode
        ? ALL_COUNTRIES.find(({ call }) => call === value.countryCode)?.call
        : ALL_COUNTRIES.find(({ code }) => code === initialCountry)?.call,
    [value, initialCountry],
  );

  const defaultCountry = useMemo(
    () => ALL_COUNTRIES.find(({ call }) => call === countryCodeValue),
    [countryCodeValue],
  );

  const handleChange = useCallback(
    (code: string) => {
      const countryCode = ALL_COUNTRIES.find(
        ({ code: elCode }) => code === elCode,
      )?.call;

      change(`${name}.countryCode`, countryCode);
    },
    [change, name],
  );

  const classes = classNames('field-phone-number', className);

  useEffect(() => {
    if (!value) {
      change(`${name}.countryCode`, countryCodeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes}>
      <div className="field-phone-number__container">
        <CountryPicker
          handleChange={handleChange}
          defaultCountry={defaultCountry?.code}
          disabled={disabled}
          renderAsPortal={renderAsPortal}
        />
        <Field name={`${name}.countryCode`} component="input" hidden />
        <Field
          name={`${name}.phoneNumber`}
          component={InputField}
          suffixNode={suffixNode}
          disabled={disabled}
          label={label}
          validate={shouldValidate && required()}
          isPhoneNumber
        />
      </div>
    </div>
  );
};

export default PhoneNumberField;
