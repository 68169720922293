import React from 'react';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams as useUrlParams } from 'react-router-dom';
import VacancyCandidatesBoxFilters from './components/VacancyCandidatesBoxFilters';
import CandidateCard from './components/CandidateCard';
import CandidateQualification from './components/CandidateQualification';
import QualificationsService from 'services/Qualifications.service';
import {
  useGetSchoolAccountVacanciesQuery,
  useGetVacancyCandidatesQuery,
} from 'api/schoolGroups.api';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import PaginationInfo from 'components/PaginationInfo';
import useSortByOptions from './hooks/useSortByOptions';
import Select from 'components/Select';
import Heading from 'components/Heading';
import { Button } from 'ncoded-component-library';
import useVacancyProfileTabs from './hooks/useVacancyProfileTabs';
import TabsNavigation from 'components/TabsNavigation';
import GoBackButton from 'components/GoBackButton';

import './VacancyCandidates.styles.scss';

const VacancyCandidates: React.FC = () => {
  const { schoolGroupId, vacancyId } = useUrlParams();

  const params = useAppSelector((state) => state.schoolGroupVacancyCandidates);

  const sortByOptions = useSortByOptions();

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetVacancyCandidatesQuery(
    {
      params,
      schoolGroupId,
      vacancyId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: paginatedVacancies } = useGetSchoolAccountVacanciesQuery({
    schoolGroupId,
  });
  const tabs = useVacancyProfileTabs();

  const vacancy = paginatedVacancies?.items.find((el) => el._id === vacancyId);

  const { positionTitle, status } = vacancy || {};

  if (isLoading) {
    return <PageLoader />;
  }

  const { items: candidates, ...pagination } = data;

  const baseClass = 'vacancy-candidates';

  return (
    <>
      <GoBackButton to="../../" />
      <Heading title={positionTitle}>
        <div className="heading-buttons-group">
          <p className={`vacancy-status`}>
            {t('status')}:&nbsp; <span>{status}</span>
          </p>
          <Button onClick={() => navigate('../preview')} variant="outline">
            {t('preview')}
          </Button>
        </div>
      </Heading>
      <TabsNavigation tabs={tabs} />
      <div className={baseClass}>
        <VacancyCandidatesBoxFilters />
        <section className={`${baseClass}__info-section`}>
          <div>
            <h6>{t('Candidates.allCandidates')}</h6>
            <PaginationInfo
              name={t('Candidates.candidate', { count: pagination.totalItems })}
              limit={params?.$limit}
              {...pagination}
            />
          </div>
          <Select
            className={`${baseClass}__info-section__select`}
            options={sortByOptions}
            value={params?.$sort}
            innerLabel={t('sortBy')}
            multiple={false}
            onChange={({ value }) => {
              dispatch(
                paramsActions.sort('schoolGroupVacancyCandidates', value),
              );
            }}
          />
        </section>
        <ul>
          {candidates?.map((candidate) => {
            const { qualifications } = candidate;
            const { numberOfMatchingFields = 0, numberOfTotalFields = 0 } =
              qualifications || {};

            const [variant, message] =
              QualificationsService.getCandidatesQualificationVariantAndMessage(
                numberOfMatchingFields,
                numberOfTotalFields,
              );

            return (
              <CandidateCard
                key={candidate?._id}
                {...candidate}
                onClick={() => navigate(candidate?._id)}
                suffixNode={
                  <CandidateQualification
                    qualificationsNumber={numberOfTotalFields}
                    candidateQualifications={numberOfMatchingFields}
                    variant={variant}
                    message={message}
                  />
                }
              />
            );
          })}
        </ul>
        {pagination?.totalPages > 1 && (
          <Pagination
            {...pagination}
            onChange={(pageNumber: number) =>
              dispatch(
                paramsActions.changeCurrentPage(
                  'schoolGroupVacancyCandidates',
                  pageNumber,
                ),
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default () => {
  return <VacancyCandidates />;
};
