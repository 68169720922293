import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Candidate, CandidateFilters } from 'models/Candidate';
import { School, SchoolGroup } from 'models/School';
import { Vacancy } from 'models/Vacancy';
import {
  Comment,
  Invoice,
  PaginatedResponse,
  Params,
  PaymentMethod,
} from 'types';
import { VacancyFilters } from 'models/Vacancy';
import baseQuery from './baseQuery';
import { ApplicationSchoolStatus, FillVacancyMethod } from 'searchality-data';
import { InviteMemberValues, User } from 'models/User';

export const schoolGroupsApi = createApi({
  reducerPath: 'schoolsGroups',
  baseQuery,
  tagTypes: [
    'SchoolGroups',
    'Vacancy',
    'Candidate',
    'CandidateInfo',
    'Members',
    'Member',
    'SchoolAccount',
    'SchoolGroupSchools',
  ],
  endpoints: (builder) => ({
    getSchoolGroups: builder.query<PaginatedResponse<SchoolGroup>, unknown>({
      query: (params) => {
        return {
          url: `school-accounts`,
          method: 'get',
          params,
        };
      },
      providesTags: ['SchoolGroups'],
      keepUnusedDataFor: 10,
    }),
    getSchoolAccount: builder.query<SchoolGroup, string>({
      query: (schoolGroupId: string) => {
        return {
          url: `school-accounts/${schoolGroupId}`,
          method: 'get',
          params: {
            $populate: ['owner', 'schools'],
          },
        };
      },
      providesTags: ['SchoolAccount'],
    }),
    getVacancyCandidates: builder.query<
      PaginatedResponse<Candidate>,
      { params: Params; schoolGroupId: string; vacancyId: string }
    >({
      query: ({ params, schoolGroupId, vacancyId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/vacancies/${vacancyId}/candidates`,
          method: 'get',
          params,
        };
      },
    }),
    getVacancyFilters: builder.query<VacancyFilters, { schoolGroupId: string }>(
      {
        query: ({ schoolGroupId }) => {
          return {
            url: `school-accounts/${schoolGroupId}/vacancies/filters`,
            method: 'get',
          };
        },
      },
    ),
    getCandidatesFilters: builder.query<
      CandidateFilters,
      { schoolGroupId: string }
    >({
      query: ({ schoolGroupId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/candidates/filters`,
          method: 'get',
        };
      },
    }),
    getCandidateComments: builder.query<
      PaginatedResponse<Comment>,
      { params: Params; schoolGroupId: string; candidateId: string }
    >({
      query: ({ params, schoolGroupId, candidateId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/candidates/${candidateId}/comments`,
          method: 'get',
          params,
        };
      },
    }),
    deleteSchool: builder.mutation<unknown, { schoolGroupId: string }>({
      query: ({ schoolGroupId }) => {
        return {
          url: `school-accounts/${schoolGroupId}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['SchoolGroups'],
    }),
    getSchoolAccountSchools: builder.query<PaginatedResponse<School>, string>({
      query: (schoolGroupId) => {
        return {
          url: `school-accounts/${schoolGroupId}/schools`,
          method: 'get',
        };
      },
      providesTags: ['SchoolGroupSchools'],
    }),
    getSchoolAccountVacancies: builder.query<
      PaginatedResponse<Vacancy>,
      Params & { schoolGroupId: string }
    >({
      query: (data) => {
        const { schoolGroupId, ...params } = data;

        return {
          url: `school-accounts/${schoolGroupId}/vacancies`,
          method: 'get',
          params,
        };
      },
    }),
    getSchoolAccountCandidates: builder.query({
      providesTags: ['Candidate'],
      query: (schoolGroupId: string) => {
        return {
          url: `school-accounts/${schoolGroupId}/candidates`,
          method: 'get',
        };
      },
    }),
    getCandidatesInfo: builder.query<
      { statusInSchool: string; count: number }[],
      { schoolGroupId: string; vacancyId: string }
    >({
      providesTags: ['Candidate', 'CandidateInfo'],
      query: ({ schoolGroupId, vacancyId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/vacancies/${vacancyId}/candidates/info`,
          method: 'get',
        };
      },
    }),
    getCandidate: builder.query<
      Candidate,
      { schoolGroupId: string; candidateId: string }
    >({
      providesTags: ['Candidate'],
      query: ({ schoolGroupId, candidateId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/candidates/${candidateId}`,
          method: 'get',
          params: {
            $populate: ['schools', 'vacancy', 'schoolGroup', 'teacher'],
          },
        };
      },
    }),
    getVacancy: builder.query<
      Vacancy,
      { schoolGroupId: string; vacancyId: string }
    >({
      providesTags: ['Vacancy'],
      query: ({ schoolGroupId, vacancyId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/vacancies/${vacancyId}`,
          method: 'get',
          params: {
            $populate: [
              'schools',
              'schoolGroup',
              'publisher',
              'creator',
              'updater',
            ],
          },
        };
      },
    }),
    fillVacancy: builder.mutation<
      unknown,
      {
        schoolGroupId: string;
        vacancyId: string;
        applicantIds?: string[];
        fillMethod: FillVacancyMethod;
      }
    >({
      invalidatesTags: ['Vacancy'],
      query: ({ schoolGroupId, vacancyId, applicantIds, fillMethod }) => {
        return {
          url: `school-accounts/${schoolGroupId}/vacancies/${vacancyId}/fill`,
          method: 'post',
          body: {
            ...(applicantIds
              ? { applicantIds, fillMethod }
              : { isInternally: true, fillMethod }),
          },
        };
      },
    }),
    updateCandidate: builder.mutation<
      unknown,
      {
        schoolGroupId: string;
        candidateId: string;
        status: ApplicationSchoolStatus;
        message: string;
        skipNotification?: boolean;
      }
    >({
      invalidatesTags: ['Candidate', 'CandidateInfo'],
      query: ({
        schoolGroupId,
        candidateId,
        status,
        message,
        skipNotification,
      }) => {
        return {
          url: `school-accounts/${schoolGroupId}/candidates/${candidateId}/status`,
          method: 'PATCH',
          body: {
            status,
            message,
            skipNotification,
          },
        };
      },
    }),
    getSchoolGroupMembers: builder.query<
      PaginatedResponse<User>,
      { params: Params; schoolGroupId: string }
    >({
      query: ({ params, schoolGroupId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/members`,
          method: 'get',
          params,
        };
      },
      providesTags: ['Members'],
      keepUnusedDataFor: 10,
    }),
    getSchoolMember: builder.query<
      User,
      { schoolGroupId: string; memberId: string }
    >({
      query: ({ schoolGroupId, memberId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/members/${memberId}`,
          method: 'get',
        };
      },
      providesTags: ['Member'],
    }),
    deleteSchoolMember: builder.mutation<
      unknown,
      { schoolGroupId: string; memberId: string }
    >({
      query: ({ schoolGroupId, memberId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/members/${memberId}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Members'],
    }),
    getSchoolMemberVacancies: builder.query<
      PaginatedResponse<Vacancy>,
      { params: Params; schoolGroupId: string; memberId: string }
    >({
      query: ({ params, schoolGroupId, memberId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/members/${memberId}/vacancies`,
          method: 'get',
          params,
        };
      },
      providesTags: ['Member'],
    }),
    updateMemberPermission: builder.mutation<
      User,
      InviteMemberValues & { memberId: string; schoolGroupId: string }
    >({
      query: (requestInfo) => {
        const { memberId, role, schoolId, vacancyIds, schoolGroupId } =
          requestInfo;

        return {
          url: `school-accounts/${schoolGroupId}/members/${memberId}/role`,
          method: 'post',
          body: {
            role,
            schoolId,
            vacancyIds,
          },
          params: { $populate: ['schools', 'vacancies'] },
        };
      },
      invalidatesTags: ['Members', 'Member', 'SchoolAccount'],
    }),
    getPaymentInfo: builder.query<{ paymentMethods: PaymentMethod[] }, string>({
      query: (schoolGroupId) => {
        return {
          url: `school-accounts/${schoolGroupId}/payment-info`,
          method: 'get',
        };
      },
    }),
    getInvoices: builder.query<Invoice[], string>({
      query: (schoolGroupId) => {
        return {
          url: `school-accounts/${schoolGroupId}/invoices`,
          method: 'get',
        };
      },
    }),
    deleteSchoolGroupSchool: builder.mutation<unknown, { schoolId: string }>({
      query: ({ schoolId }) => {
        return {
          url: `school-accounts/schools/${schoolId}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['SchoolAccount', 'SchoolGroupSchools'],
    }),
    updateIsWidgetEnabled: builder.mutation<
      unknown,
      { schoolGroupId: string; isWidgetEnabled: boolean }
    >({
      query: ({ schoolGroupId, isWidgetEnabled }) => {
        return {
          url: `school-accounts/${schoolGroupId}/widget`,
          method: 'PATCH',
          body: {
            isWidgetEnabled,
          },
        };
      },
      invalidatesTags: ['SchoolAccount'],
    }),
    completeSchoolGroupWidgetStatus: builder.mutation<
      unknown,
      { schoolGroupId: string }
    >({
      query: ({ schoolGroupId }) => {
        return {
          url: `school-accounts/school-groups/${schoolGroupId}/widget-integration-status`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['SchoolAccount'],
    }),
    completeSchoolWidgetStatus: builder.mutation<unknown, { schoolId: string }>(
      {
        query: ({ schoolId }) => {
          return {
            url: `school-accounts/schools/${schoolId}/widget-integration-status`,
            method: 'PATCH',
          };
        },
        invalidatesTags: ['SchoolAccount'],
      },
    ),
    schoolGroupTestFlag: builder.mutation<
      unknown,
      { schoolGroupId: string; isForTesting: boolean }
    >({
      query: ({ schoolGroupId, isForTesting }) => {
        return {
          url: `school-accounts/${schoolGroupId}/test-flag`,
          method: 'PATCH',
          body: {
            isForTesting,
          },
        };
      },
      invalidatesTags: ['SchoolAccount', 'SchoolGroups'],
    }),
    updateCSVMap: builder.mutation<
      unknown,
      {
        schoolGroupId: string;
        candidateCsvPropertyMap: {
          property: string;
          exportProperty: string;
        }[];
      }
    >({
      query: ({ schoolGroupId, candidateCsvPropertyMap }) => {
        return {
          url: `school-accounts/${schoolGroupId}/candidate-csv-export-map`,
          method: 'PATCH',
          body: {
            candidateCsvPropertyMap,
          },
        };
      },
      invalidatesTags: ['SchoolAccount', 'SchoolGroups'],
    }),
    resendMemberInvite: builder.mutation<
      User,
      { schoolGroupId: string; memberId: string }
    >({
      query: ({ schoolGroupId, memberId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/members/${memberId}/resend-invitation-email`,
          method: 'post',
        };
      },
    }),
    cancelMemberInvite: builder.mutation<
      User,
      { schoolGroupId: string; memberId: string }
    >({
      query: ({ schoolGroupId, memberId }) => {
        return {
          url: `school-accounts/${schoolGroupId}/members/${memberId}/cancel-invitation-email`,
          method: 'post',
        };
      },
      invalidatesTags: ['Members'],
    }),
  }),
});

export const {
  useGetSchoolGroupsQuery,
  useGetSchoolAccountQuery,
  useLazyGetSchoolAccountQuery,
  useGetSchoolAccountSchoolsQuery,
  useGetSchoolAccountVacanciesQuery,
  useDeleteSchoolMutation,
  useGetVacancyCandidatesQuery,
  useGetVacancyFiltersQuery,
  useGetCandidateCommentsQuery,
  useGetCandidatesInfoQuery,
  useGetCandidateQuery,
  useGetVacancyQuery,
  useFillVacancyMutation,
  useUpdateCandidateMutation,
  useGetCandidatesFiltersQuery,
  useGetSchoolGroupMembersQuery,
  useUpdateMemberPermissionMutation,
  useGetSchoolMemberQuery,
  useDeleteSchoolMemberMutation,
  useGetSchoolMemberVacanciesQuery,
  useGetPaymentInfoQuery,
  useGetInvoicesQuery,
  useDeleteSchoolGroupSchoolMutation,
  useUpdateIsWidgetEnabledMutation,
  useCompleteSchoolGroupWidgetStatusMutation,
  useCompleteSchoolWidgetStatusMutation,
  useSchoolGroupTestFlagMutation,
  useUpdateCSVMapMutation,
  useCancelMemberInviteMutation,
  useResendMemberInviteMutation,
} = schoolGroupsApi;
