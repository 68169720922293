import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './Error404.styles.scss';

type Error404Props = {
  className?: string;
};

const Error404: React.FC<Error404Props> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('error-404', className);

  return (
    <div className={classes}>
      <h1>{t('Error404.title')}</h1>
      <p>{t('Error404.description')}</p>
    </div>
  );
};

export default Error404;
