import { ComplexRoute } from 'router/components/Routes/Routes';
import TeacherAccountDetails from './pages/TeacherAccountDetails';
import TeacherApplications from './pages/TeacherApplications';
import TeacherPublicProfile from './pages/TeacherPublicProfile';

export default [
  {
    path: 'profile',
    element: TeacherPublicProfile,
  },
  {
    path: 'applications',
    element: TeacherApplications,
  },
  {
    path: 'details',
    element: TeacherAccountDetails,
  },
] as Array<ComplexRoute>;
