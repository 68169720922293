import React from 'react';

import { useTranslation } from 'react-i18next';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';
import usePricingPlan from '../../../../hooks/usePricingPlan';

const SchoolPricingPlan: React.FC = () => {
  const list = usePricingPlan();

  const { t } = useTranslation();

  return <ReviewCardWrapper list={list} title={t('pricingPlan')} />;
};

export default SchoolPricingPlan;
