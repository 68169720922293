import React from 'react';
import VacancyCard, { VacancyCardProps } from '../../VacancyCard.component';

import './VacancyCardWrapper.styles.scss';

type VacancyCardWrapperProps = {
  additionalContent?: React.ReactNode;
} & VacancyCardProps;

const VacancyCardWrapper: React.FC<VacancyCardWrapperProps> = ({
  additionalContent,
  ...cardProps
}) => {
  const baseClassname = 'searchality-vacancy-card';

  return (
    <div className={baseClassname + '__wrapper'}>
      {additionalContent}
      <VacancyCard {...cardProps} />
    </div>
  );
};

export default VacancyCardWrapper;
