import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import { AssociationType } from 'searchality-data';
import { useTranslation } from 'react-i18next';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { required } from 'validations';
import MultipleSelectField from 'components/MultipleSelectField';
import { statesOptions } from 'constants/';

import './CreateAssociationTypeField.styles.scss';
import SelectField from 'components/SelectField';

type CreateAssociationTypeFieldProps = {
  className?: string;
};

const CreateAssociationTypeField: React.FC<CreateAssociationTypeFieldProps> = (
  props,
) => {
  const { className } = props;

  const classes = classNames('create-association-type-field', className);

  const { t } = useTranslation();

  const {
    values: { type, states, state },
  } = useFormState();

  const { change } = useForm();

  const options = useMemo(
    () =>
      Object.entries(AssociationType).map(([key, value]) => ({
        label: <RadioButtonLabel label={t(`AssociationTypes.${key}`)} />,
        value,
      })),
    [t],
  );

  useEffect(() => {
    if (type !== AssociationType.REGIONAL && states?.length) {
      change('states', undefined);
    }
    if (type !== AssociationType.STATE && state) {
      change('state', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, type]);

  return (
    <div className={classes}>
      <Field
        name="type"
        component={RadioGroupField}
        options={options}
        validate={required()}
      />
      {type === AssociationType.REGIONAL && (
        <div className="create-association-type-field__states">
          <p>{t('inWitchStatesAssociationWorks')}</p>
          <Field
            component={MultipleSelectField}
            name={'states'}
            options={statesOptions}
            validate={required()}
            label={t('selectStates')}
          />
        </div>
      )}
      {type === AssociationType.STATE && (
        <div className="create-association-type-field__states">
          <p>{t('inWitchStateAssociationWorks')}</p>
          <Field
            component={SelectField}
            name={'state'}
            options={statesOptions}
            validate={required()}
            label={t('selectState')}
          />
        </div>
      )}
    </div>
  );
};

export default CreateAssociationTypeField;
