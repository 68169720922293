import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolGroupVacancies from './pages/SchoolGroupVacancies';
import vacancyCandidatesRoutes from './subrouters/VacancyCandidates/VacancyCandidates.routes';
import VacancyCandidatesRouter from './subrouters/VacancyCandidates/VacancyCandidates.router';

export default [
  {
    path: ':vacancyId',
    element: VacancyCandidatesRouter,
    routes: vacancyCandidatesRoutes,
  },
  {
    index: true,
    element: SchoolGroupVacancies,
  },
] as unknown as Array<ComplexRoute>;
