import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Dropdown, Input } from 'ncoded-component-library';
import ReactCountryFlag from 'react-country-flag';
import { ALL_COUNTRIES } from 'enums';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';

import './CountryPicker.styles.scss';

type CountryPickerProps = {
  defaultCountry?: string;
  className?: string;
  disabled?: boolean;
  searchable?: true;
  renderAsPortal?: boolean;
  handleChange?: (code: string) => void;
} & Omit<ComponentProps<typeof Dropdown>, 'trigger'>;

const CountryPicker: React.FC<CountryPickerProps> = (props) => {
  const {
    defaultCountry = 'US',
    handleChange,
    className,
    disabled,
    searchable = true,
    renderAsPortal = false,
    ...rest
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hideInput, setHideInput] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [showValue, setShowValue] = useState<{
    code: string;
    call: string;
    name: string;
  }>();

  const refArray = useRef<HTMLLIElement[]>([]);
  const ulRef = useRef<HTMLUListElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<DropdownRef>(null);

  const handleCoutryClick = useCallback(
    (code: string, call: string, index: number, name: string) => {
      setShowValue({ code, call, name });
      setCurrentIndex(index);
      dropdownRef?.current?.setIsOpen(false);
      handleChange(code);
    },
    [handleChange],
  );

  const coutryOptions = useMemo(() => {
    return ALL_COUNTRIES.filter(
      (entry) =>
        entry.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        entry.call.includes(inputValue),
    ).map(({ code, call, name }, index) => (
      <li
        key={code}
        ref={(el) => (refArray.current[index] = el)}
        onClick={() => handleCoutryClick(code, call, index, name)}
        className={classNames('searchality-country', {
          'searchality-country--selected': index === currentIndex,
        })}
      >
        {ReactCountryFlag({
          countryCode: code,
          svg: true,
        })}{' '}
        {` ${name} (${call})`}
      </li>
    ));
  }, [currentIndex, handleCoutryClick, inputValue]);

  const classes = classNames(
    'searchality-country-picker',
    disabled ? 'searchality-country-picker--disabled' : '',
    className,
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown' && currentIndex < coutryOptions?.length - 1) {
        setCurrentIndex((prevState) => prevState + 1);

        ulRef.current.scrollTo({
          top:
            refArray.current[currentIndex]?.offsetHeight * currentIndex +
            refArray.current[currentIndex]?.offsetHeight,
        });
      }
      if (e.key === 'ArrowUp' && currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1);

        ulRef.current.scrollTo({
          top:
            refArray.current[currentIndex]?.offsetHeight * currentIndex -
            refArray.current[currentIndex]?.offsetHeight,
        });
      }
      if (e.key === 'Enter') {
        const { code, call, name } = ALL_COUNTRIES.filter(
          (entry) =>
            entry.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
            entry.call.includes(inputValue),
        )[currentIndex];
        setShowValue({ code, call, name });
        handleChange(code);
        inputRef.current.blur();
        dropdownRef.current.setIsOpen(false);
      }
    },
    [currentIndex, coutryOptions?.length, handleChange, inputValue],
  );

  useEffect(() => {
    const { code, call, name } = ALL_COUNTRIES.find(
      (el) => el.code === defaultCountry,
    );
    setShowValue({ code, call, name });
  }, [defaultCountry]);

  useEffect(() => {
    if (!hideInput) {
      inputRef?.current?.focus();
    }
  }, [hideInput]);

  return (
    <Dropdown
      className={classes}
      ref={dropdownRef}
      renderAsPortal={renderAsPortal}
      onOpen={() => {
        setHideInput(false);
        ulRef.current.scrollTo({
          top:
            refArray.current[currentIndex]?.offsetHeight * currentIndex -
            refArray.current[currentIndex]?.offsetHeight,
        });
        dropdownRef?.current?.setIsOpen(true);
      }}
      onClose={() => {
        setHideInput(true);
        dropdownRef?.current?.setIsOpen(false);
      }}
      trigger={
        <div className={'dropdown__trigger__label'}>
          {showValue && (
            <>
              {ReactCountryFlag({
                countryCode: showValue.code,
                svg: true,
              })}{' '}
              {` ${showValue.name} (${showValue.call})`}
            </>
          )}
          {searchable && (
            <Input
              ref={(r: any) => (inputRef.current = r)}
              className={classNames('dropdown__trigger__label__input', {
                'dropdown__trigger__label__input--hide': hideInput,
              })}
              value={inputValue}
              onChange={(e) => setInputValue(e.currentTarget.value)}
              onKeyDown={handleKeyPress}
            />
          )}
        </div>
      }
      {...rest}
    >
      <ul className="country-list" ref={ulRef}>
        {coutryOptions}
      </ul>
    </Dropdown>
  );
};

export default CountryPicker;
