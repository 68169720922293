import { createAction, createSlice } from '@reduxjs/toolkit';
import { PopNotificationProps } from 'modules/PopNotifications/components/PopNotification/PopNotification.component';
import utils from 'utils';
import { AxiosError } from 'axios';
import { t } from 'i18next';

export type Notification = Omit<PopNotificationProps, 'notId'> & {
  content: React.ReactNode;
};

type PopNotificationWithId = Notification & { notId: string };

type PopNotificationState = Array<PopNotificationWithId>;

const initialState: PopNotificationState = [];

export const popSuccess = createAction(
  'popNotifications/Success',
  (message: string) => ({
    payload: {
      type: 'Success',
      content: message,
    } as PopNotificationWithId,
  }),
);

export const popError = createAction(
  'popNotifications/Error',
  (message: string) => ({
    payload: {
      type: 'Error',
      content: message,
    } as PopNotificationWithId,
  }),
);

export const popServerError = createAction(
  'popNotifications/ServerError',
  (error) => ({
    payload: {
      type: 'Error',
      content: t(utils.getStringError(error as AxiosError)),
    } as PopNotificationWithId,
  }),
);

export const closeNotification = createAction<PopNotificationWithId>(
  'popNotifications/CloseNotification',
);
export const closeAll = createAction('popNotifications/CloseAll');

const popNotificationSlice = createSlice({
  name: 'popNotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(popSuccess, (state, action) => {
      return [...state, { ...action.payload, notId: `${Date.now()}` }];
    });
    builder.addCase(popError, (state, action) => {
      return [...state, { ...action.payload, notId: `${Date.now()}` }];
    });
    builder.addCase(popServerError, (state, action) => {
      return [...state, { ...action.payload, notId: `${Date.now()}` }];
    });
    builder.addCase(closeNotification, (state, action) => {
      const { payload: notification } = action;

      return state.filter((not) => not.notId !== notification.notId);
    });
    builder.addCase(closeAll, () => {
      return initialState;
    });
  },
});

export default popNotificationSlice.reducer;
