import { createApi } from '@reduxjs/toolkit/query/react';
import { Application } from 'models/Candidate';
import { Teacher } from 'models/Teacher';
import { PaginatedResponse, Params, TransformedPaginatedResponse } from 'types';
import baseQuery from './baseQuery';

export const teachersApi = createApi({
  reducerPath: 'admin/teachers',
  baseQuery: baseQuery,
  tagTypes: ['Teachers', 'Teacher'],
  endpoints: (builder) => ({
    getTeachers: builder.query<TransformedPaginatedResponse<Teacher>, Params>({
      query: (params) => {
        return {
          url: `teachers`,
          method: 'get',
          params,
        };
      },
      transformResponse: (response: PaginatedResponse<Teacher>) => {
        const { items, ...pagination } = response;
        return { items, pagination } as TransformedPaginatedResponse<Teacher>;
      },
      keepUnusedDataFor: 10,
      providesTags: ['Teachers'],
    }),
    getTeacher: builder.query<Teacher, string>({
      query: (teacherId: string) => {
        return {
          url: `teachers/${teacherId}`,
          method: 'get',
          params: {
            $populate: ['applications'],
          },
        };
      },
      providesTags: ['Teacher'],
    }),
    getTeacherApplications: builder.query<
      PaginatedResponse<Application>,
      { params: Params; teacherId: string }
    >({
      query: ({ teacherId, params }) => {
        return {
          url: `teachers/${teacherId}/applications`,
          method: 'get',
          params,
        };
      },
    }),
    deleteTeacher: builder.mutation<Teacher, string>({
      query: (teacherId: string) => {
        return {
          url: `teachers/${teacherId}`,
          method: 'delete',
        };
      },
      invalidatesTags: ['Teachers'],
    }),
    teacherTestFlag: builder.mutation<
      unknown,
      { teacherId: string; isForTesting: boolean }
    >({
      query: ({ teacherId, isForTesting }) => {
        return {
          url: `teachers/${teacherId}/test-flag`,
          method: 'PATCH',
          body: {
            isForTesting,
          },
        };
      },
      invalidatesTags: ['Teachers', 'Teacher'],
    }),
    getTeachersFeedbackCount: builder.query<
      {
        positive: number;
        negative: number;
      },
      void
    >({
      query: () => {
        return {
          url: `teachers/matching-feedback/count`,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useGetTeachersQuery,
  useGetTeacherQuery,
  useDeleteTeacherMutation,
  useGetTeacherApplicationsQuery,
  useTeacherTestFlagMutation,
  useGetTeachersFeedbackCountQuery,
} = teachersApi;
