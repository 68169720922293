import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import React from 'react';
import MemberInformation from './components/MemberInformation';
import MemberVacancies from './components/MemberVacancies';

import './MembersProfile.styles.scss';

const MembersProfilePage: React.FC = () => {
  const classess = classNames('members-profile');

  return (
    <div className={classess}>
      <GoBackButton />
      <MemberInformation />
      <MemberVacancies />
    </div>
  );
};

export default MembersProfilePage;
