import React from 'react';
import { useTranslation } from 'react-i18next';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';
import useSchoolGroupInformation from '../../../../hooks/useSchoolGroupInformation';

const SchoolGroupInformation: React.FC = () => {
  const list = useSchoolGroupInformation();

  const { t } = useTranslation();

  return <ReviewCardWrapper list={list} title={t('schoolGroupInformation')} />;
};

export default SchoolGroupInformation;
