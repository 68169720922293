import React from 'react';
import Loading from 'components/Loading';

type PageLoaderProps = {
  size?: number;
  style?: Record<string, string | number>;
};

const PageLoader: React.FC<PageLoaderProps> = (props) => {
  const { size = 240, style = { margin: 'auto' } } = props;

  return <Loading size={size} style={style} />;
};

export default PageLoader;
