import React, { useCallback } from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { Field, Form } from 'react-final-form';
import PasswordField from 'components/PasswordField';
import { passwordValidation, required, composeValidators } from 'validations';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import Heading from 'components/Heading';
import { useChangePasswordMutation } from 'api/auth.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

import './ChangePassword.styles.scss';

type ChangePasswordProps = {
  className?: string;
};

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const { className } = props;

  const classes = classNames('change-password', className);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [changePassword] = useChangePasswordMutation();

  const handlePasswordChange = useCallback(
    async (values: FormValues) => {
      const { currentPassword, newPassword } = values;
      changePassword({ currentPassword, newPassword })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('passwordChangeSuccess')));
          navigate('..');
        })
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [changePassword, dispatch, navigate, t],
  );

  return (
    <div className={classes}>
      <GoBackButton />
      <Heading title={t('changePassword')} />
      <Form
        onSubmit={handlePasswordChange}
        validate={(values) =>
          values?.confirmNewPassword !== values?.newPassword
            ? {
                confirmNewPassword: t('passwordMustMatch'),
              }
            : {}
        }
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="currentPassword"
              component={PasswordField}
              label={t('typeCurrentPassword')}
              validate={composeValidators(required(), passwordValidation())}
              required
            />
            <Field
              name="newPassword"
              component={PasswordField}
              label={t('newPassword')}
              validate={composeValidators(required(), passwordValidation())}
              required
            />
            <Field
              name="confirmNewPassword"
              component={PasswordField}
              label={t('repeatNewPassoword')}
              required
            />
            <Button type="submit" disabled={submitting || invalid}>
              {t('confirmNewPassoword')}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default ChangePassword;
