import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';

import './FormFieldLabel.styles.scss';
import './FormFieldLabel.styles.responsive.scss';

type FormFieldLabelProps = {
  className?: string;
  isOptional?: boolean;
  text: string;
  withHint?: boolean;
  hint?: React.ReactNode;
};

const FormFieldLabel: React.FC<FormFieldLabelProps> = (props) => {
  const { className, isOptional = false, text, withHint = false, hint } = props;

  const classes = classNames('form-field-label', className);
  const { t } = useTranslation();

  return (
    <h5 className={classes}>
      {text}
      {isOptional && <span>{t('optional')}</span>}
      {withHint && (
        <Tippy
          className="tooltip-information video-tippy"
          content={hint}
          placement="bottom"
        >
          <InformationIcon />
        </Tippy>
      )}
    </h5>
  );
};

export default FormFieldLabel;
