import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ProfileTabs from '../ProfileTabs';
import { getNameInitials } from 'utils';
import PhotoPreview from 'components/PhotoPreview';
import DateService from 'services/Date.service';
import { ProfileCardType } from 'models/ProfileCard.type';
import useProfileTabs from './hooks/useProfileTabs.hook';

import './ProfileMain.styles.scss';

type ProfileMainProps = {
  className?: string;
  cardInfo: ProfileCardType;
};

const ProfileMain: React.FC<ProfileMainProps> = (props) => {
  const { className, cardInfo } = props;

  const { firstName, lastName, startDate, imageUrl, systemColor } = cardInfo;

  const { activeTab, tabs, currentSection } = useProfileTabs(cardInfo);

  const { t } = useTranslation();

  const baseClass = 'searchality-profile-main';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <PhotoPreview
        placeHolderText={getNameInitials(firstName, lastName)}
        backgroundColor={systemColor}
        src={imageUrl}
        size="xxl"
        className={`${baseClass}__avatar`}
      />
      <div className={`${baseClass}__personal-info`}>
        <p
          className={`${baseClass}__personal-info__name`}
        >{`${firstName} ${lastName}`}</p>
        <div className={`${baseClass}__personal-info__info`}>
          <p className={`${baseClass}__personal-info__info__main`}>
            {t('Profile.Main.startDate')}
          </p>
          <p>
            {startDate
              ? DateService.getDayMonthYear(startDate)
              : startDate === null
              ? t('immediately')
              : t('noData')}
          </p>
        </div>
      </div>
      <ProfileTabs tabs={tabs} activeTab={activeTab} />
      <hr />
      {currentSection}
    </div>
  );
};

export default ProfileMain;
