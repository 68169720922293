import React from 'react';

import Lottie from 'react-lottie';
import * as animationData from './loading.json';

type LoadingProps = {
  size?: number;
  style?: Record<string, string | number>;
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Loading: React.FC<LoadingProps> = ({ size = 64, style }) => {
  return (
    <Lottie options={defaultOptions} height={size} width={size} style={style} />
  );
};

export default Loading;
