import classNames from 'classnames';
import React from 'react';
import './BasicTag.styles.scss';

export type BasicTagVariants = 'blue' | 'orange' | 'lightGrey' | 'outline';

type BasicTagProps = {
  className?: string;
  variant?: BasicTagVariants;
  text: string;
};

const BasicTag: React.FC<BasicTagProps> = (props) => {
  const { className, variant = 'blue', text } = props;

  const classes = classNames(
    'searchality-basic-tag',
    className,
    `searchality-basic-tag--${variant}`,
  );
  return <div className={classes}>{text}</div>;
};

export default BasicTag;
