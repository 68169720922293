import React from 'react';
import classNames from 'classnames';
import './CreateAssociationSection.styles.scss';

type CreateAssociationSectionProps = {
  className?: string;
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
};

const CreateAssociationSection: React.FC<CreateAssociationSectionProps> = (
  props,
) => {
  const { children, className, subtitle, title } = props;

  const baseClass = 'create-association-section';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>{title}</p>
      {subtitle && <p className={`${baseClass}__subtitle`}>{subtitle}</p>}
      {children}
    </div>
  );
};

export default CreateAssociationSection;
