import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import ReviewCardWrapper from 'router/subrouters/Dashboard/components/ReviewCardWrapper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMyAccountDetailsList from './hooks/useMyAccountDetailsList';
import { useLoadCurrentUserQuery, useTurnOff2FAMutation } from 'api/auth.api';
import { Button } from 'ncoded-component-library';
import Enable2FaModal from './components/Enable2FaModal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import confirm from 'modules/confirm';

import './MyAccount.styles.scss';

type MyAccountProps = {
  className?: string;
};

const MyAccount: React.FC<MyAccountProps> = (props) => {
  const { className } = props;
  const { data: admin } = useLoadCurrentUserQuery();
  const { t } = useTranslation();
  const myAccountDetailsList = useMyAccountDetailsList();
  const navigate = useNavigate();
  const enableModalRef = useRef<ModalRef>(null);
  const dispatch = useAppDispatch();

  const { isTwoFactorAuthenticationEnabled } = admin || {};
  const [turnOff2fa] = useTurnOff2FAMutation();

  const classes = classNames('my-account', className);

  const handleTurnOff2fa = useCallback(async () => {
    await confirm({
      title: t('TurnOff2fa.title'),
      content: <p>{t('TurnOff2fa.description')}</p>,
      confirmBtnText: t('TurnOff2fa.buttonText'),
      onSubmit: () => {
        turnOff2fa()
          .unwrap()
          .then((res) => {
            dispatch(popSuccess(t('successTurnOff2fa')));
          })
          .catch((e) => dispatch(popServerError(e)));
      },
    });
  }, [dispatch, t, turnOff2fa]);

  return (
    <div className={classes}>
      <ReviewCardWrapper
        title={t('accountDetails')}
        list={myAccountDetailsList}
        onEdit={() => navigate('/my-account/change-password')}
      />
      {isTwoFactorAuthenticationEnabled ? (
        <Button onClick={handleTurnOff2fa} variant="outline">
          {t('disable2FA')}
        </Button>
      ) : (
        <Button onClick={() => enableModalRef?.current?.open()}>
          {t('enable2FA')}
        </Button>
      )}
      <Enable2FaModal ref={enableModalRef} />
    </div>
  );
};

export default MyAccount;
