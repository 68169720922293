import React, { useRef } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import { ReactComponent as MemberIcon } from 'icons/MemberNew.icon.svg';
import { useTranslation } from 'react-i18next';
import AcceptCandidateModal from '../AcceptCandidateModal';
import RejectCondidateModal from '../RejectCondidateModal';
import { ApplicationSchoolStatus } from 'searchality-data';

import './ProfileActions.styles.scss';

type ProfileActionsProps = {
  className?: string;
  statusInSchool: ApplicationSchoolStatus;
  candidateId: string;
};

const ProfileActions: React.FC<ProfileActionsProps> = (props) => {
  const { className, statusInSchool } = props;

  const { t } = useTranslation();

  const acceptModal = useRef(null);
  const rejectModal = useRef(null);

  const classes = classNames('seachality-profile-actions', className);

  return (
    <div className={classes}>
      {(statusInSchool === ApplicationSchoolStatus.NEW ||
        statusInSchool === ApplicationSchoolStatus.REJECTED) && (
        <Button
          iconPosition="right"
          icon={<MemberIcon />}
          onClick={() => acceptModal.current.open()}
        >
          {t('proceedWithCandidate')}
        </Button>
      )}
      {(statusInSchool === ApplicationSchoolStatus.NEW ||
        statusInSchool === ApplicationSchoolStatus.IN_PROCESS) && (
        <Button
          variant="outline"
          iconPosition="right"
          icon={<CloseIcon />}
          onClick={() => rejectModal.current.open()}
        >
          {t('rejectCandidate')}
        </Button>
      )}
      <AcceptCandidateModal ref={acceptModal} />
      <RejectCondidateModal ref={rejectModal} />
    </div>
  );
};

export default ProfileActions;
