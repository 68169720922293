import useAppDispatch from 'hooks/useAppDispatch';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import paramsActions from 'store/actions/params.actions';

const SchoolGroupsRouter: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        paramsActions.initializeParams('schoolGroupsParams', {
          $limit: 20,
          $populate: ['schools'],
        }),
      );
      dispatch(
        paramsActions.initializeParams('schoolGroupVacancies', {
          $limit: 6,
        }),
      );
      dispatch(
        paramsActions.initializeParams('schoolGroupMembers', {
          $limit: 6,
        }),
      );
      dispatch(
        paramsActions.initializeParams('schoolGroupVacancyCandidates', {
          $limit: 6,
        }),
      );
    };
  }, [dispatch]);

  return <Outlet />;
};

export default SchoolGroupsRouter;
