import MainLayout from 'components/layout/MainLayout';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Dashboard: React.FC = () => (
  <MainLayout headerShadow>
    <Outlet />
  </MainLayout>
);

export default Dashboard;
