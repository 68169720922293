import { ComplexRoute } from 'router/components/Routes/Routes';
import Associations from './pages/Associations';
import CreateAssociation from './pages/CreateAssociation';
import AssociationProfile from './subrouters/AssociationProfile/AssociationProfile.router';
import AssociationProfileRoutes from './subrouters/AssociationProfile/AssociationProfile.routes';

export default [
  {
    path: ':associationId',
    element: AssociationProfile,
    routes: AssociationProfileRoutes,
  },
  {
    path: 'create-association',
    element: CreateAssociation,
  },
  {
    index: true,
    element: Associations,
  },
] as Array<ComplexRoute>;
