import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'ncoded-component-library';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import {
  useCancelMemberInviteMutation,
  useResendMemberInviteMutation,
} from 'api/schoolGroups.api';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';

import './PendingInvite.styles.scss';

type PendingInviteProps = {
  memberId: string;
  schoolGroupId: string;
};

const PendingInvite: React.FC<PendingInviteProps> = ({
  memberId,
  schoolGroupId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const dropdownRef = useRef<DropdownRef>();

  const [cancelInv] = useCancelMemberInviteMutation();
  const [resendInv] = useResendMemberInviteMutation();

  return (
    <div className="pending-invite">
      <p>{t('pendingInvite')}</p>
      <Dropdown
        trigger={
          <div
            className="pending-invite__dots"
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className="pending-invite__dots__dot"
              onClick={(e) => e.stopPropagation()}
            />
            <span className="pending-invite__dots__dot" />
            <span className="pending-invite__dots__dot" />
          </div>
        }
        ref={dropdownRef}
      >
        <div
          className="pending-invite__actions"
          onClick={(e) => e.stopPropagation()}
        >
          <span
            className="pending-invite__actions__action"
            onClick={(e) => {
              e.stopPropagation();
              dropdownRef.current.setIsOpen(false);
              cancelInv({ schoolGroupId, memberId })
                .unwrap()
                .then(() => dispatch(popSuccess(t('cancelInviteSuccess'))))
                .catch((e) => dispatch(popServerError(e)));
            }}
          >
            <p>{t('deleteInvite')}</p>
          </span>
          <span
            className="pending-invite__actions__action"
            onClick={(e) => {
              e.stopPropagation();
              dropdownRef.current.setIsOpen(false);
              resendInv({ schoolGroupId, memberId })
                .unwrap()
                .then(() => dispatch(popSuccess(t('resendInviteSuccess'))))
                .catch((e) => dispatch(popServerError(e)));
            }}
          >
            <p>{t('resendInvite')}</p>
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

export default PendingInvite;
