import React from 'react';
import Routes from 'router/components/Routes/Routes';
import PopNotifications from 'modules/PopNotifications';
import ErrorBoundary from 'components/ErrorBoundary';
import routes from 'router/routes';

function AdminApp() {
  return (
    <>
      <PopNotifications />
      <ErrorBoundary>
        <Routes routes={routes} />
      </ErrorBoundary>
    </>
  );
}

export default AdminApp;
