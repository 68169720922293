import React, { useMemo } from 'react';
import classNames from 'classnames';
import './TeacherPublicProfile.styles.scss';
import { useParams } from 'react-router-dom';
import { useGetTeacherQuery } from 'api/teachers.api';
import PageLoader from 'components/PageLoader';
import Profile from 'router/subrouters/Dashboard/components/Profile';
import { extractProfileInfoFromUser } from 'router/subrouters/Dashboard/components/Profile/utils';
import { User } from 'models/User';

type TeacherPublicProfileProps = {
  className?: string;
};

const TeacherPublicProfile: React.FC<TeacherPublicProfileProps> = (props) => {
  const { className } = props;

  const { teacherId } = useParams();

  const { data, isLoading } = useGetTeacherQuery(teacherId);

  const classes = classNames('teacher-public-profile', className);

  const cardInfo = useMemo(() => {
    if (data) {
      return extractProfileInfoFromUser(data as User);
    }
  }, [data]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <Profile cardInfo={cardInfo} />
    </div>
  );
};

export default TeacherPublicProfile;
