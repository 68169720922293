import { School } from 'models/School';
import { useMemo } from 'react';

const useSchoolFromYourGroup = (schools: Pick<School, '_id' | 'name'>[]) => {
  const schoolsFromYourGroupOptions = useMemo(
    () =>
      schools?.map(({ _id, name }) => ({
        label: name,
        value: _id,
      })),
    [schools],
  );

  return schoolsFromYourGroupOptions;
};

export default useSchoolFromYourGroup;
