import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ncoded-component-library';
import { ReactComponent as ArrowIcon } from 'icons/Arrow.icon.svg';
import classNames from 'classnames';

import './GoBackButton.styles.scss';

type GoBackButtonProps = {
  className?: string;
  to?: string;
  onClick?: () => void;
};

const GoBackButton: React.FC<GoBackButtonProps> = (props) => {
  const { className, to, onClick } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const classes = classNames('go-back-button', className);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      return onClick();
    }

    if (to) {
      return navigate(to);
    }

    return navigate(-1);
  }, [navigate, onClick, to]);

  return (
    <Button variant="link" className={classes} onClick={handleOnClick}>
      <ArrowIcon />
      {t('goBack')}
    </Button>
  );
};

export default GoBackButton;
