import { useParams } from 'react-router-dom';

export default () => {
  const { schoolGroupId, vacancyId } = useParams();

  return [
    {
      to: `/school-groups/${schoolGroupId}/vacancies/${vacancyId}/candidates`,
      label: 'Candidates.candidates',
    },
    {
      to: `/school-groups/${schoolGroupId}/vacancies/${vacancyId}/vacancy-information`,
      label: 'Candidates.vacancyInformation',
    },
  ];
};
