import React, { useState } from 'react';
import ReactDOM, { render } from 'react-dom';
import { Button, Modal } from 'ncoded-component-library';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './ConfirmModal.styles.scss';

type ConfirmConfig = {
  type?: 'confirm' | 'delete';
  className?: string;
  title?: string;
  content?: React.ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showConfirm?: boolean;
  showCancel?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  disabledSubmit?: boolean;
};

export type ConfirmObject = {
  simpleConfirm: (conf: ConfirmConfig) => Promise<boolean>;
};

const ConfirmModal: React.FC<ConfirmConfig> = (props) => {
  const { t } = useTranslation();

  const {
    className,
    title,
    content,
    type = 'confirm',
    confirmBtnText = t('confirm'),
    cancelBtnText = t('cancel'),
    showConfirm = true,
    showCancel = true,
    onClose,
    onSubmit,
    ...rest
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const classes = classNames('searchality-confirm-modal', type, className);

  return (
    <Modal
      className={classes}
      open
      onClose={onClose}
      title={title}
      footer={
        <>
          {showCancel && (
            <Button variant="outline" onClick={onClose}>
              {cancelBtnText}
            </Button>
          )}
          {showConfirm && (
            <Button
              disabled={isSubmitting}
              className={classNames({ delete: type === 'delete' })}
              onClick={async () => {
                setIsSubmitting(true);
                await onSubmit();
                setIsSubmitting(false);
              }}
            >
              {confirmBtnText}
            </Button>
          )}
        </>
      }
      {...rest}
    >
      {content}
    </Modal>
  );
};

export default async (config: ConfirmConfig) => {
  return new Promise((resolve) => {
    const { title, content, onSubmit, ...rest } = config;

    const div = document.createElement('div');
    document.body.appendChild(div);

    const cleanup = () => {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    };

    return render(
      <ConfirmModal
        {...rest}
        title={title}
        content={content}
        onSubmit={async () => {
          try {
            await onSubmit();
            resolve(true);
            cleanup();
          } catch (err) {
            //ERR
          }
        }}
        onClose={() => {
          resolve(false);
          cleanup();
        }}
      />,
      div,
    );
  });
};
