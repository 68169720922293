import React, { useCallback, useRef } from 'react';
import { useGetAdminsQuery } from 'api/admins.api';
import Heading from 'components/Heading';
import Pagination from 'components/Pagination';
import { Button } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import InviteAdminModal from '../../components/InviteAdminModal';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import PaginationInfo from 'components/PaginationInfo';
import useAppSelector from 'hooks/useAppSelector';
import Table from 'components/Table';
import useAdminsTableHeader from './hooks/useAdminsTableHeader';
import useAdminsTableData from './hooks/useAdminsTableData';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';
import useTeamPermissionsFilter from './hooks/useTeamPermissionsFilter';
import { Select } from 'ncoded-component-library';
import { batch } from 'react-redux';
import PageLoader from 'components/PageLoader';

import './Admins.styles.scss';

const AdminsPage: React.FC = () => {
  const { t } = useTranslation();

  const params = useAppSelector(({ adminsParams }) => adminsParams);

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetAdminsQuery(params);

  const inviteAdminModalRef = useRef<ModalRef>(null);
  const scrollRef = useRef<HTMLDivElement>();

  const { items, pagination } = data || {};

  const adminsColumns = useAdminsTableHeader();

  const adminsData = useAdminsTableData(items);

  const teamPermissionsFilter = useTeamPermissionsFilter();

  const openInviteModalRef = useCallback(() => {
    inviteAdminModalRef.current.open();
  }, [inviteAdminModalRef]);

  const onPaginationChange = useCallback(
    (pageNumber: number) => {
      dispatch(paramsActions.changeCurrentPage('adminsParams', pageNumber));
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [dispatch],
  );

  const onSchoolGroupTableHeaderClick = useCallback(
    (value: string) => {
      dispatch(paramsActions.sortColumn('adminsParams', value));
    },
    [dispatch],
  );

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={'searchality-admins-page'} ref={scrollRef}>
      <Heading title={t('Admins.title')}>
        <Button
          onClick={openInviteModalRef}
          icon={<AddIcon />}
          iconPosition="right"
        >
          {t('Admins.inviteNewAdmin')}
        </Button>
        <InviteAdminModal
          ref={inviteAdminModalRef}
          onClose={() => inviteAdminModalRef.current.close()}
        />
      </Heading>
      <div className="searchality-admins-page__filters">
        <Select
          options={teamPermissionsFilter}
          multiple={false}
          onChange={({ value }) => {
            batch(() => {
              dispatch(paramsActions.onFilter('adminsParams', { role: value }));
              dispatch(paramsActions.changeCurrentPage('adminsParams', 1));
            });
          }}
          innerLabel={t('teamPermissions')}
          value={params?.$where?.role}
        />
        <Button
          variant="link"
          onClick={() => {
            batch(() => {
              dispatch(paramsActions.resetFilters('adminsParams'));
              dispatch(paramsActions.resetSort('adminsParams'));
              dispatch(paramsActions.changeCurrentPage('adminsParams', 1));
            });
          }}
        >
          {t('resetFilters')}
        </Button>
      </div>
      <PaginationInfo
        name={t('adminsSmall', { count: pagination?.totalItems })}
        limit={params?.$limit}
        {...pagination}
      />
      {pagination?.totalItems > 0 && (
        <Table
          tableColumns={adminsColumns}
          tableRows={adminsData}
          handleHeaderClick={onSchoolGroupTableHeaderClick}
          params={params}
        />
      )}
      {pagination?.totalPages > 1 && (
        <Pagination onChange={onPaginationChange} {...pagination} />
      )}
    </div>
  );
};

export default AdminsPage;
