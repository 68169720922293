import React, { useCallback } from 'react';
import AuthSection from 'router/subrouters/Auth/components/AuthSection';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import PasswordField from 'components/PasswordField';
import { composeValidators, required, passwordValidation } from 'validations';
import { Button } from 'ncoded-component-library';
import useQParamsActions from 'hooks/useQueryParams';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'components/layout/MainLayout';
import { useResetPasswordMutation } from 'api/auth.api';

type ResetPasswordProps = {
  className?: string;
};

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { t } = useTranslation();

  const [resetPassword] = useResetPasswordMutation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    params: { token },
  } = useQParamsActions();

  const handleSubmit = useCallback(
    async ({ password }: { password: string; confirmPassword: string }) => {
      resetPassword({ password, token })
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('ResetPassword.success')));
          navigate('../login');
        })
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [dispatch, navigate, resetPassword, t, token],
  );

  return (
    <MainLayout className="reset-password" isAuth>
      <AuthSection className="auth-form">
        {!token ? (
          <>
            <h1 style={{ textAlign: 'center' }}>
              {t('ResetPassword.noToken')}
            </h1>
          </>
        ) : (
          <>
            <h1>{t('resetPassword')}</h1>
            <Form
              onSubmit={handleSubmit}
              validate={({ confirmPassword, password }) =>
                confirmPassword !== password
                  ? { confirmPassword: t('ValidationError.passwordMustMatch') }
                  : {}
              }
              render={({ handleSubmit, invalid, submitting }) => (
                <form onSubmit={handleSubmit} className="auth-section__form">
                  <Field
                    name="password"
                    component={PasswordField}
                    label={t('password')}
                    required
                    validate={composeValidators(
                      required(),
                      passwordValidation(),
                    )}
                    hint={t('passwordHint')}
                  />
                  <Field
                    name="confirmPassword"
                    component={PasswordField}
                    label={t('confirmPassword')}
                    required
                  />
                  <Button
                    type="submit"
                    disabled={invalid || submitting}
                    className="forgot-password__submit"
                  >
                    {t('resetPassword')}
                  </Button>
                </form>
              )}
            />
          </>
        )}
      </AuthSection>
    </MainLayout>
  );
};

export default ResetPassword;
