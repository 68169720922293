import {
  useGetSchoolAccountQuery,
  useUpdateMemberPermissionMutation,
} from 'api/schoolGroups.api';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import confirm from 'modules/confirm';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SelectSchoolModal from 'router/subrouters/Dashboard/components/SelectSchoolModal';
import SelectVacanciesModal from 'router/subrouters/Dashboard/components/SelectVacanciesModal';
import useRolesSelectOptions from 'router/subrouters/Dashboard/hooks/useRolesSelectOptions';
import { Role, SchoolGroupStructureType } from 'searchality-data';
import paramsActions from 'store/actions/params.actions';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';

import './RolesSelect.styles.scss';

type RolesSelectProps = {
  isReadonly?: boolean;
  currentRole: Role;
  userId: string;
};
const RolesSelect: React.FC<RolesSelectProps> = ({
  isReadonly,
  currentRole,
  userId,
}) => {
  const { t } = useTranslation();
  const { schoolGroupId } = useParams();
  const { data: { structureType, schools } = {} } =
    useGetSchoolAccountQuery(schoolGroupId);

  const dispatch = useAppDispatch();

  const permissionOptions = useRolesSelectOptions();
  const [updateMemberPermission] = useUpdateMemberPermissionMutation();

  const selectSchoolModalRef = useRef<ModalRef>(null);
  const vacanciesModalRef = useRef<ModalRef>(null);

  const vacanciesParams = useMemo(
    () => ({
      $where: {
        status: { $in: ['Published', 'Ongoing'] },
      },
      $populate: ['schools', 'schoolGroup'],
    }),
    [],
  );

  const updateRole = useCallback(
    (selectedRole: Role, schoolId?: string) => {
      updateMemberPermission({
        memberId: userId,
        schoolGroupId,
        role: selectedRole,
        schoolId,
      })
        .unwrap()
        .then(() => {
          selectSchoolModalRef.current.close();
          dispatch(popSuccess(t('roleSuccessfullyChanged')));
        })
        .catch(() => dispatch(popError(t('roleChangeFail'))));
    },
    [dispatch, schoolGroupId, t, updateMemberPermission, userId],
  );

  const updateRoleToHiringManager = useCallback(() => {
    if (structureType === SchoolGroupStructureType.GROUP) {
      return selectSchoolModalRef.current.open();
    }

    return updateRole(Role.HiringManager, schools?.[0]?._id);
  }, [schools, structureType, updateRole]);

  const updateRoleToAccountManager = useCallback(async () => {
    await updateRole(Role.AccountManager);
  }, [updateRole]);

  const updateRoleToAccountOwner = useCallback(async () => {
    await confirm({
      title: t('Members.changeOwnerRoleTitle'),
      className: 'change-owner-modal',
      content: (
        <p className="change-owner-role-description">
          {t('Members.changeOwnerRoleTitleDesc')}
        </p>
      ),
      confirmBtnText: t('Members.changeOwnerRoleTitleConfirm'),
      onSubmit: () => {
        updateRole(Role.AccountOwner);
      },
    });
  }, [t, updateRole]);

  const updateRoleToReviewer = useCallback(
    (selectedIds: string[]) => {
      updateMemberPermission({
        memberId: userId,
        schoolGroupId,
        role: Role.Reviewer,
        vacancyIds: selectedIds,
      })
        .unwrap()
        .then(() => {
          vacanciesModalRef.current.close();
          dispatch(popSuccess(t('roleSuccessfullyChanged')));
        })
        .catch(() => dispatch(popError(t('roleChangeFail'))));
    },
    [dispatch, schoolGroupId, t, updateMemberPermission, userId],
  );

  const onMemberRoleChange = useCallback(
    ({ value: selectedRole }: any) => {
      if (currentRole === Role.AccountOwner) {
        if (selectedRole !== Role.AccountOwner) {
          dispatch(popError(t('pickOwnerFirst')));
        }
        return;
      }
      if (selectedRole === Role.HiringManager) {
        updateRoleToHiringManager();
      }
      if (selectedRole === Role.AccountOwner) {
        updateRoleToAccountOwner();
      }
      if (selectedRole === Role.AccountManager) {
        updateRoleToAccountManager();
      }
      if (selectedRole === Role.Reviewer) {
        dispatch(
          paramsActions.initializeParams(
            'schoolGroupVacancies',
            vacanciesParams,
          ),
        );

        vacanciesModalRef.current.open();
      }
    },
    [
      currentRole,
      dispatch,
      t,
      updateRoleToAccountManager,
      updateRoleToAccountOwner,
      updateRoleToHiringManager,
      vacanciesParams,
    ],
  );

  if (isReadonly) {
    return <strong>{currentRole}</strong>;
  }
  return (
    <>
      <Select
        options={permissionOptions}
        value={currentRole}
        onChange={onMemberRoleChange}
        renderAsPortal
        className="member-role-select"
      />
      <ValueContextProvider>
        <SelectSchoolModal
          ref={selectSchoolModalRef}
          onSubmit={(selectedSchoolId) =>
            updateRole(Role.HiringManager, selectedSchoolId)
          }
          onCancel={() => selectSchoolModalRef.current.close()}
        />
      </ValueContextProvider>
      <ValueContextProvider isMultiple>
        <SelectVacanciesModal
          ref={vacanciesModalRef}
          onSubmit={updateRoleToReviewer}
          onCancel={() => vacanciesModalRef.current.close()}
          initialParams={vacanciesParams}
        />
      </ValueContextProvider>
    </>
  );
};

export default RolesSelect;
