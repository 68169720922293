import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Params, TableColumn, TableRow } from 'types';
import useTableColumns from './hooks/useTableColumns';
import useTableRows from './hooks/useTableRows';

import './Table.styles.scss';

type TableProps = {
  className?: string;
  tableColumns: TableColumn[];
  tableRows: TableRow[];
  handleRowClick?: (_id?: string) => void;
  handleHeaderClick?: (value?: string) => void;
  params?: Params;
};

const Table: React.FC<TableProps> = (props) => {
  const {
    className,
    tableColumns,
    tableRows,
    handleRowClick,
    handleHeaderClick,
    params,
  } = props;

  const columns = useTableColumns(tableColumns, params, handleHeaderClick);
  const rows = useTableRows(tableRows, handleRowClick);

  const classes = classNames('searchality-table', className);

  useEffect(() => {
    const scrollTop = () => {
      const topScroll = document.querySelector('.top-scroll');
      const tableWrapper = document.querySelector('.searchality-table');
      const table = tableWrapper.querySelector('table');
      const scrollContent = document.querySelector('.scroll-content');

      // Set the width of the scroll-content to match the table's scroll width
      (scrollContent as any).style.width = `${table.scrollWidth}px`;

      topScroll.addEventListener('scroll', function () {
        tableWrapper.scrollLeft = topScroll.scrollLeft;
      });

      tableWrapper.addEventListener('scroll', function () {
        topScroll.scrollLeft = tableWrapper.scrollLeft;
      });
    };
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', scrollTop);
    } else {
      scrollTop();
    }
  }, []);

  return (
    <div className="scroll-container">
      <div className="top-scroll">
        <div className="scroll-content"></div>
      </div>
      <div className={classes}>
        <table>
          <thead>
            <tr>{columns}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
