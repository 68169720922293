import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Association,
  CreateAssociationFrom,
  CreateOwnerForm,
} from 'models/Association';
import { School } from 'models/School';
import { User } from 'models/User';
import { PaginatedResponse, Params, TransformedPaginatedResponse } from 'types';
import baseQuery from './baseQuery';

export const associationsApi = createApi({
  reducerPath: 'admin/associations',
  baseQuery: baseQuery,
  tagTypes: [
    'Associations',
    'Association',
    'Members',
    'AssociationSchools',
    'Owner',
  ],
  endpoints: (builder) => ({
    getAssociations: builder.query<
      TransformedPaginatedResponse<Association>,
      Params
    >({
      query: (params) => {
        return {
          url: `associations`,
          method: 'get',
          params,
        };
      },
      transformResponse: (response: PaginatedResponse<Association>) => {
        const { items, ...pagination } = response;
        return {
          items,
          pagination,
        } as TransformedPaginatedResponse<Association>;
      },
      keepUnusedDataFor: 10,
      providesTags: ['Associations'],
    }),
    getAssociation: builder.query<Association, string>({
      query: (id) => {
        return {
          url: `associations/${id}`,
          method: 'get',
        };
      },
      providesTags: ['Association'],
    }),
    createAssociation: builder.mutation<
      Association,
      Omit<CreateAssociationFrom, 'state'>
    >({
      query: (body) => {
        return {
          url: `associations`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Associations'],
    }),
    updateAssociation: builder.mutation<
      Association,
      { associationId: string; body: Omit<CreateAssociationFrom, 'state'> }
    >({
      query: ({ associationId, body }) => {
        return {
          url: `associations/${associationId}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Association'],
    }),
    createAssociationOwner: builder.mutation<
      User,
      { body: CreateOwnerForm; associationId: string }
    >({
      query: ({ body, associationId }) => {
        return {
          url: `associations/${associationId}/association-user`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Association', 'Members'],
    }),
    getAssociationSchools: builder.query<
      PaginatedResponse<School>,
      { params: Params; associationId: string }
    >({
      query: ({ params, associationId }) => {
        return {
          url: `associations/${associationId}/schools`,
          method: 'get',
          params,
        };
      },
      providesTags: ['AssociationSchools'],
    }),
    getAssociationMembers: builder.query<PaginatedResponse<User>, string>({
      query: (associationId) => {
        return {
          url: `associations/${associationId}/current-members`,
          method: 'get',
        };
      },
      providesTags: ['Members'],
    }),
    deleteAssociationSchool: builder.mutation<
      void,
      { associationId: string; schoolId: string }
    >({
      query: ({ associationId, schoolId }) => {
        return {
          url: `associations/${associationId}/schools`,
          method: 'PATCH',
          body: {
            schoolIds: [schoolId],
          },
        };
      },
      invalidatesTags: ['AssociationSchools'],
    }),
    deactivateAssociation: builder.mutation<void, string>({
      query: (associationId) => {
        return {
          url: `associations/${associationId}/deactivate`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Associations', 'Association'],
    }),
    getAssociationOwner: builder.query<User, string>({
      query: (associationId) => {
        return {
          url: `associations/${associationId}/owner`,
          method: 'get',
        };
      },
      providesTags: ['Owner'],
    }),
    updateAssociationOwner: builder.mutation<
      User,
      { userId: string; body: CreateOwnerForm }
    >({
      query: ({ userId, body }) => {
        return {
          url: `associations/${userId}/association-user`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Owner'],
    }),
    sendInvitationLink: builder.mutation<void, { associationId: string }>({
      query: ({ associationId }) => {
        return {
          url: `associations/${associationId}/registration-email`,
          method: 'post',
        };
      },
      invalidatesTags: ['Owner'],
    }),
    associationTestFlag: builder.mutation<
      unknown,
      { associationId: string; isForTesting: boolean }
    >({
      query: ({ associationId, isForTesting }) => {
        return {
          url: `associations/${associationId}/test-flag`,
          method: 'PATCH',
          body: {
            isForTesting,
          },
        };
      },
      invalidatesTags: ['Association'],
    }),
  }),
});

export const {
  useGetAssociationsQuery,
  useCreateAssociationMutation,
  useGetAssociationQuery,
  useGetAssociationSchoolsQuery,
  useGetAssociationMembersQuery,
  useCreateAssociationOwnerMutation,
  useDeleteAssociationSchoolMutation,
  useDeactivateAssociationMutation,
  useUpdateAssociationMutation,
  useGetAssociationOwnerQuery,
  useSendInvitationLinkMutation,
  useUpdateAssociationOwnerMutation,
  useAssociationTestFlagMutation,
} = associationsApi;
