import React  from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { Form } from 'react-final-form';
import CreateAssociationSection from '../../components/CreateAssociationSection';
import Heading from 'components/Heading';
import { ReactComponent as RemoveIcon } from 'icons/Delete.icon.svg';
import CreateAssociationTypeField from '../../components/CreateAssociationTypeField';
import CreateAssociationInformationFields from '../../components/CreateAssociationInformationFields';
import { CreateAssociationFrom } from 'models/Association';

import './AssociationInformationForm.styles.scss';

type AssociationInformationFormProps = {
  className?: string;
  onFormSubmit: (values: CreateAssociationFrom) => void;
  initialValues?: CreateAssociationFrom;
  isEdit?: boolean;
};

const AssociationInformationForm: React.FC<AssociationInformationFormProps> = (
  props,
) => {
  const { className, onFormSubmit, initialValues, isEdit } = props;

  const { t } = useTranslation();

  const classes = classNames('association-information-form', className);

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form: { reset },
        invalid,
        pristine,
        submitting,
      }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <Heading title={t('Associations.createButton')}>
            <Button
              onClick={() => reset()}
              icon={<RemoveIcon />}
              iconPosition="right"
              className="clear-form-button"
            >
              {t('clearForm')}
            </Button>
          </Heading>
          <CreateAssociationSection
            title={t('Associations.CreateAssociation.typeTitle')}
            subtitle={t('Associations.CreateAssociation.typeSubtitle')}
          >
            <CreateAssociationTypeField />
          </CreateAssociationSection>
          <CreateAssociationSection
            title={t(
              'Associations.CreateAssociation.associationInformationTitle',
            )}
          >
            <CreateAssociationInformationFields />
          </CreateAssociationSection>
          <Button type="submit" disabled={invalid || pristine || submitting}>
            {t(
              !isEdit ? 'Associations.createButton' : 'Associations.editButton',
            )}
          </Button>
        </form>
      )}
    />
  );
};

export default AssociationInformationForm;
