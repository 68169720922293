import { useTranslation } from 'react-i18next';

const useAssociationsTableHeader = () => {
  const { t } = useTranslation();

  return [
    {
      text: t('name'),
      value: 'name',
      sortable: true,
    },
    {
      text: t('acronym'),
      value: 'acronym',
    },
    {
      text: t('type'),
      value: 'type',
    },
    {
      text: t('id'),
      value: '_id',
    },
    {
      text: t('website'),
      value: 'website',
    },
    {
      text: t('accountStatus'),
      value: 'isActive',
      sortable: true,
    },
  ];
};

export default useAssociationsTableHeader;
