import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import searchalityLogo from 'assets/images/searchality-logo.webp';
import HeaderNavigation from '../HeaderNavigation';
import credentialsService from 'services/credentialsService';

import './Header.styles.scss';
import './Header.styles.responsive.scss';

type HeaderProps = {
  shadow?: boolean;
  indent?: boolean;
  className?: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { className, shadow, indent } = props;

  const classes = classNames(
    'searchality-header',
    { shadow, indent },
    className,
  );

  return (
    <header className={classes}>
      <Link to="/">
        <img
          src={searchalityLogo}
          alt="Logo"
          className="searchality-header__logo"
        />
      </Link>
      {credentialsService.token && <HeaderNavigation />}
    </header>
  );
};

export default Header;
