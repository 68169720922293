import { Middleware } from '@reduxjs/toolkit';

export const authMiddleware: Middleware = (api) => (next) => (action) => {
  // if (action.type === logout.type) {
  //   next(action);
  //   authApi.endpoints.loginAdmin.dispatch({ type: '@@redux/INIT' });
  //   return;
  // }

  return next(action);
};

export default authMiddleware;
