import { ComplexRoute } from 'router/components/Routes/Routes';
import CandidateProfile from './pages/CandidateProfile';
import VacancyCandidatesPages from './pages/VacancyCandidates/VacancyCandidates.pages';
import VacancyInformation from './pages/VacancyInformation';
import VacancyPreview from './pages/VacancyPreview';

export default [
  {
    path: 'candidates/:candidateId',
    element: CandidateProfile,
  },
  {
    path: 'vacancy-information',
    element: VacancyInformation,
  },
  {
    path: 'candidates',
    element: VacancyCandidatesPages,
  },
  {
    path: 'preview',
    element: VacancyPreview,
  },
] as Array<ComplexRoute>;
