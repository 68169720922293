import React, { useCallback, useRef, useEffect } from 'react';
import { useGetSchoolGroupsQuery } from 'api/schoolGroups.api';
import DebouncedInput from 'components/DebouncedInput';
import Heading from 'components/Heading';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import PaginationInfo from 'components/PaginationInfo';
import Table from 'components/Table';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import paramsActions from 'store/actions/params.actions';
import useSchoolsGroupTableData from './hooks/useSchoolsGroupTableData';
import useTableHeader from './hooks/useTableHeader';
import { ReactComponent as SearchIcon } from 'icons/Search.svg';
import { batch } from 'react-redux';

import './SchoolGroups.styles.scss';

const SchoolGroupsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const params = useAppSelector(({ schoolGroupsParams }) => schoolGroupsParams);

  const tableHeader = useTableHeader();

  const { data, isLoading } = useGetSchoolGroupsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const schoolGroupsTableData = useSchoolsGroupTableData(data?.items || []);

  const onSearchStringChange = useCallback(
    (searchString: string) => {
      batch(() => {
        dispatch(
          paramsActions.applySearchString('schoolGroupsParams', searchString),
        );
        dispatch(paramsActions.changeCurrentPage('schoolGroupsParams', 1));
      });
    },
    [dispatch],
  );

  const handleSchoolGroupRowClick = useCallback(
    (_id?: string) => {
      if (_id) return navigate(`${_id}/vacancies`);
    },
    [navigate],
  );

  const onSchoolGroupTableHeaderClick = useCallback(
    (value: string) => {
      dispatch(paramsActions.sortColumn('schoolGroupsParams', value));
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(paramsActions.applySearchString('schoolGroupsParams', ''));
    };
  }, [dispatch]);

  if (isLoading) {
    return <PageLoader />;
  }

  const { items, ...pagination } = data;

  const { totalItems, totalPages } = pagination || {};

  return (
    <div className="searchality-admin-schools" ref={scrollRef}>
      <Heading title={t('Schools')} />
      <div>
        <DebouncedInput
          onValueChange={onSearchStringChange}
          prefixNode={<SearchIcon />}
          placeholder={t('SchoolGroupsPage.debouncedPlaceholder')}
        />
      </div>
      <PaginationInfo
        name={t('PaginationInfo.registeredSchool', {
          count: totalItems,
        })}
        {...pagination}
        limit={params?.$limit}
      />
      {!!totalItems && (
        <Table
          tableColumns={tableHeader}
          tableRows={schoolGroupsTableData}
          handleRowClick={handleSchoolGroupRowClick}
          handleHeaderClick={onSchoolGroupTableHeaderClick}
          params={params}
        />
      )}
      {totalPages > 1 && (
        <Pagination
          onChange={(pageNumber: number) => {
            dispatch(
              paramsActions.changeCurrentPage('schoolGroupsParams', pageNumber),
            );
            scrollRef?.current?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
          {...pagination}
        />
      )}
    </div>
  );
};

export default SchoolGroupsPage;
