import React, { ReactNode } from 'react';
import { ReactComponent as EditIcon } from 'icons/Edit.icon.svg';
import classNames from 'classnames';

import './ReviewCard.styles.scss';

export type ReviewCardProps = {
  title: string;
  children?: ReactNode;
  className?: string;
  onEdit?: () => void;
};

const ReviewCard: React.FC<ReviewCardProps> = (props) => {
  const { title, children, className, onEdit } = props;

  const baseClass = 'searchality-review-card';

  const classes = classNames(baseClass, className);

  return (
    <section className={classes}>
      <div className={baseClass + '__heading'}>
        <h5>{title}</h5>
        {onEdit && (
          <button className="svg-button-wrapper" onClick={onEdit}>
            <EditIcon />
          </button>
        )}
      </div>
      <div className={`${baseClass}__content`}>{children}</div>
    </section>
  );
};

export default ReviewCard;
