import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function () {
  const { schoolGroupId } = useParams();
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);
  const { t } = useTranslation();

  if (schoolGroup) {
    return schoolGroup?.type === 'public school'
      ? t('districtSmall')
      : t('groupSmall');
  }
  return '';
}
