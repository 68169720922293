import React from 'react';

type RadioButtonLabelProps = {
  label: string;
  description?: string;
};

const RadioButtonLabel: React.FC<RadioButtonLabelProps> = (props) => {
  const { label, description } = props;

  return (
    <>
      <span className="title">{label}</span>
      {description && <span className="description">{description}</span>}
    </>
  );
};

export default RadioButtonLabel;
