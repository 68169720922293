import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import PageLoader from 'components/PageLoader';
import { useGetSchoolAccountQuery } from 'api/schoolGroups.api';

import './SchoolGroupPage.styles.scss';

const SchoolGroupProfileRouter: React.FC = () => {
  const { pathname } = useLocation();

  const { schoolGroupId } = useParams();
  const { isLoading } = useGetSchoolAccountQuery(schoolGroupId);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div
      className={classNames(
        'school-group-profile',
        {
          'school-group-profile--preview': pathname.includes('preview'),
        },
        { 'school-group-profile--preview': pathname.includes('/candidates/') },
      )}
    >
      <Outlet />
    </div>
  );
};

export default SchoolGroupProfileRouter;
