import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolGroupsPage from './pages/SchoolGroupsPage';
import SchoolGroupProfileRoutes from './subrouters/SchoolGroupProfile/SchoolGroupProfile.routes';
import SchoolGroupProfileRouter from './subrouters/SchoolGroupProfile/SchoolGroupProfile.router';

export default [
  {
    path: ':schoolGroupId',
    element: SchoolGroupProfileRouter,
    routes: SchoolGroupProfileRoutes,
  },
  { index: true, element: SchoolGroupsPage },
] as Array<ComplexRoute>;
