import { useCallback } from 'react';
import { useGetCandidateCommentsQuery } from 'api/schoolGroups.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import paramsActions from 'store/actions/params.actions';
import { Comment } from 'types';

export default function useComments(
  candidateId: string,
  schoolGroupId: string,
) {
  const [comments, setComments] = useState<Comment[]>([]);

  const params = useAppSelector(
    ({ candidateCommentsParams }) => candidateCommentsParams,
  );

  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetCandidateCommentsQuery({
    params,
    schoolGroupId,
    candidateId,
  });

  const { items, totalPages, currentPage } = data || {};

  const haveMore = totalPages > currentPage;

  const loadMore = useCallback(() => {
    dispatch(
      paramsActions.changeCurrentPage(
        'candidateCommentsParams',
        currentPage + 1,
      ),
    );
  }, [currentPage, dispatch]);

  useEffect(() => {
    if (!isLoading && items?.length) {
      setComments((prev) => [...prev, ...items]);
    }
  }, [isLoading, items]);

  useEffect(() => {
    return () => {
      dispatch(paramsActions.changeCurrentPage('candidateCommentsParams', 1));
    };
  }, [dispatch]);

  return {
    haveMore,
    comments,
    loadMore,
  };
}
