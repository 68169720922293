import React, { useCallback } from 'react';
import { Button, Select } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useDivisionOptions from '../../../../hooks/useDivisionOptions';
import useSortByOptions from '../../pages/VacancyCandidates/hooks/useSortByOptions';
import useSchoolFromYourGroup from '../../../../hooks/useSchoolFromYourGroup';
import { useGetCandidatesFiltersQuery } from 'api/schoolGroups.api';
import { useParams } from 'react-router-dom';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import paramsActions from 'store/actions/params.actions';
import { batch } from 'react-redux';

import './CandidatesFilters.styles.scss';

const CandidatesFilters: React.FC = (props) => {
  const { t } = useTranslation();
  const { schoolGroupId } = useParams();

  const params = useAppSelector((state) => state.schoolGroupVacancyCandidates);
  const dispatch = useAppDispatch();

  const { data: filters } = useGetCandidatesFiltersQuery({ schoolGroupId });

  const schoolFromYourGroup = useSchoolFromYourGroup(filters?.schools);
  const divisionOptions = useDivisionOptions(filters?.divisions);
  const sortByOptions = useSortByOptions();

  const onReset = useCallback(() => {
    batch(() => {
      dispatch(paramsActions.resetFilters('schoolGroupVacancyCandidates'));
      dispatch(paramsActions.resetSort('schoolGroupVacancyCandidates'));
    });
  }, [dispatch]);

  const onFilter = useCallback(
    (value: string, key: string) => {
      const filterValue = {
        [key]: value,
      };
      dispatch(
        paramsActions.onFilter('schoolGroupVacancyCandidates', filterValue),
      );
    },
    [dispatch],
  );

  const onSort = useCallback(
    (value: string) => {
      dispatch(paramsActions.sort('schoolGroupVacancyCandidates', value));
    },
    [dispatch],
  );

  const className = 'candidates-filters';

  return (
    <div className={`${className}__wrapper`}>
      <div className={`${className}`}>
        <div className={`${className}__left`}>
          {schoolFromYourGroup?.length > 1 && (
            <Select
              options={schoolFromYourGroup}
              innerLabel={t('schoolName')}
              className={`${className}__wide-select`}
              multiple={false}
              onChange={({ value }) => onFilter(value, 'schoolIds')}
              value={params?.$where?.schoolIds}
            />
          )}
          {divisionOptions?.length > 1 && (
            <Select
              options={divisionOptions}
              multiple={false}
              onChange={({ value }) => {
                onFilter(value, 'positionDivision');
              }}
              innerLabel={t('schoolLevel')}
              value={params?.$where?.positionDivision}
            />
          )}
        </div>
        <div className={`${className}__right`}>
          <Select
            options={sortByOptions}
            innerLabel={t('sortBy')}
            multiple={false}
            onChange={({ value }) => {
              onSort(value);
            }}
            value={params?.$sort}
          />
        </div>
      </div>
      <Button variant="link" onClick={onReset}>
        {t('resetFilters')}
      </Button>
    </div>
  );
};

export default CandidatesFilters;
