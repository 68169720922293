import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PageLoader from 'components/PageLoader';
import SubscriptionCard from './components/SubscriptionCard';
import PaymentMethodCard from './components/PaymentMethodCard';
import { useParams } from 'react-router-dom';
import InvoiceTable from './components/InvoiceTable';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import {
  useGetPaymentInfoQuery,
  useGetSchoolAccountQuery,
  useUpdateIsWidgetEnabledMutation,
} from 'api/schoolGroups.api';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import { Button } from 'ncoded-component-library';
import { Field, Form } from 'react-final-form';
import ToggleField from 'components/ToggleField';

import './PaymentSettings.styles.scss';

type PaymentProps = {
  className?: string;
};

const PaymentSettings: React.FC<PaymentProps> = (props) => {
  const { className } = props;

  const { schoolGroupId } = useParams();
  const { t } = useTranslation();

  const { data: paymentMethods, isLoading } =
    useGetPaymentInfoQuery(schoolGroupId);
  const { data: schoolGroup } = useGetSchoolAccountQuery(schoolGroupId);

  const { subscriptionId, isWidgetEnabled } = schoolGroup || {};

  const [updateIsWidgetEnabled] = useUpdateIsWidgetEnabledMutation();

  const classes = classNames('payment-settings', className);

  const defaultPaymentMethod = useMemo(
    () => paymentMethods?.paymentMethods?.find(({ isDefault }) => isDefault),
    [paymentMethods],
  );

  const handleIsWidgetEnabled = useCallback(
    async (values: { isWidgetEnabled: boolean }) => {
      const { isWidgetEnabled } = values;
      await updateIsWidgetEnabled({ schoolGroupId, isWidgetEnabled });
    },
    [schoolGroupId, updateIsWidgetEnabled],
  );

  if (isLoading) return <PageLoader />;

  return (
    <div className="payment-settings-wrapper">
      <div className="payment-settings-wrapper__actions">
        <Form
          onSubmit={handleIsWidgetEnabled}
          initialValues={{ isWidgetEnabled }}
          render={({ handleSubmit, dirty, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="isWidgetEnabled"
                component={ToggleField}
                label={t('isWidgetEnabled')}
              />
              {dirty && (
                <Button type="submit" disabled={submitting}>
                  {t('save')}
                </Button>
              )}
            </form>
          )}
        />
      </div>
      <div className={classes}>
        {subscriptionId && (
          <div className="payment__additional-info">
            <SubscriptionCard />
          </div>
        )}
        {defaultPaymentMethod && (
          <PaymentMethodCard paymentMethod={defaultPaymentMethod} />
        )}
        {!subscriptionId && (
          <p className="payment-settings__not-subscribed">
            <InformationIcon /> {t('schoolGroupNotSubscribed')}
          </p>
        )}
      </div>
      <ValueContextProvider isMultiple>
        <InvoiceTable />
      </ValueContextProvider>
    </div>
  );
};

export default PaymentSettings;
