import { useLoadCurrentUserQuery } from 'api/auth.api';
import { useTranslation } from 'react-i18next';

const useMyAccountDetailsList = () => {
  const { data: user } = useLoadCurrentUserQuery();
  const { t } = useTranslation();

  const { email, firstName, lastName } = user || {};

  return [
    { label: t('nameAndSurname'), value: `${firstName} ${lastName}` },
    { label: t('email'), value: email },
    { label: t('password'), value: 'xxxxxxxxxx' },
  ];
};

export default useMyAccountDetailsList;
