import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginData } from 'types';
import credentialsService from 'services/credentialsService';
import { useLazyLoginAdminQuery } from 'api/auth.api';
import LoginForm from '../../components/LoginForm';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { popServerError } from 'store/slices/popNotifications.slice';
import MainLayout from 'components/layout/MainLayout';

import './Login.styles.scss';
import './Login.responsive.styles.scss';

type LoginProps = Record<string, any>;

const Login: React.FC<LoginProps> = () => {
  const [loginAdmin] = useLazyLoginAdminQuery();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: LoginData) => {
      const { data, isSuccess, isError, error } = await loginAdmin(values);
      if (isSuccess) {
        const parsed = JSON.parse(window.atob(data?.token.split('.')?.[1]));

        if (!parsed?.isTwoFactorEnabled) {
          credentialsService.saveAuthBody(data);
          return navigate('/');
        } else {
          navigate(`/auth/authenticate?token=${data?.token}`);
        }
      }

      if (isError) {
        dispatch(popServerError(error));
      }
    },
    [dispatch, loginAdmin, navigate],
  );

  return (
    <MainLayout isAuth>
      <div className="admin-login">
        <div className="admin-login__titles">
          <h1>{t('adminDashboardLogin.title')}</h1>
        </div>
        <LoginForm onSubmit={handleSubmit} />
      </div>
    </MainLayout>
  );
};

export default Login;
