import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import enTranslation from 'assets/lang/en/translation.json';
import env from '../../env';

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    debug: false,
    backend: {
      loadPath: () => {
        return `${env.ADMIN_URL}${enTranslation}`;
      },
    },
    interpolation: {
      defaultVariables: {
        literal: '',
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
