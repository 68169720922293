import { User } from 'models/User';
import { useTranslation } from 'react-i18next';

export default (contactPerson: User) => {
  const { t } = useTranslation();

  const { firstName, lastName, email, streetAddress, mobilePhone } =
    contactPerson || {};

  return [
    {
      label: t('nameAndSurname'),
      value: `${firstName} ${lastName}`,
    },
    {
      label: t('email'),
      value: email,
    },
    ...(streetAddress
      ? [
          {
            label: t('address'),
            value: streetAddress,
          },
        ]
      : []),
    ...(mobilePhone?.phoneNumber
      ? [
          {
            label: t('phoneNumber'),
            value: `${mobilePhone?.countryCode} ${mobilePhone?.phoneNumber}`,
          },
        ]
      : []),
  ];
};
