import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import InputField from 'components/InputField';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import MainLayout from 'components/layout/MainLayout';
import credentialsService from 'services/credentialsService';
import { useLazyLoginAdmin2faQuery } from 'api/auth.api';
import useAppDispatch from 'hooks/useAppDispatch';
import { popServerError } from 'store/slices/popNotifications.slice';
import { useNavigate } from 'react-router-dom';
import useQParamsActionTypes from 'hooks/useQueryParams';

import './Authenticate.styles.scss';

type AuthenticateProps = {
  className?: string;
};

type AuthenticationFormValues = {
  code: string;
};

const Authenticate: React.FC<AuthenticateProps> = (props) => {
  const { className } = props;

  const [loginAdmin] = useLazyLoginAdmin2faQuery();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { params } = useQParamsActionTypes();

  const { t } = useTranslation();

  const classes = classNames('authenticate', className);

  const onSubmit = useCallback(
    async ({ code }: AuthenticationFormValues) => {
      const { token } = params;

      const { data, isSuccess, error } = await loginAdmin({
        code,
        token,
      });
      if (isSuccess) {
        credentialsService.saveAuthBody(data);
        return navigate('/');
      }
      dispatch(popServerError(error));
    },
    [dispatch, loginAdmin, navigate, params],
  );

  useEffect(() => {
    if (!params?.token) navigate('/auth/login');
  }, [navigate, params?.token]);

  return (
    <MainLayout isAuth>
      <div className={classes}>
        <div className="authenticate__titles">
          <h1>{t('authenticate')}</h1>
          <p>{t('Authenticate.description')}</p>
        </div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, invalid, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="code"
                component={InputField}
                validate={required()}
                label={t('enterCode')}
              />
              <Button type="submit" disabled={invalid || submitting}>
                {t('send')}
              </Button>
            </form>
          )}
        />
      </div>
    </MainLayout>
  );
};

export default Authenticate;
