import React, { InputHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { Input as LibInput } from 'ncoded-component-library';

import './Input.styles.scss';
import Anim from 'components/animations';

export type FormControl = {
  label?: string;
  error?: ReactNode;
  hint?: ReactNode;
  wrapperClassName?: string;
};

type InputProps = FormControl &
  InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean | undefined;
    innerLabel?: string | undefined;
    prefixNode?: React.ReactNode;
    suffixNode?: React.ReactNode;
  };

const Input: React.FC<InputProps> = (props) => {
  const { className, wrapperClassName, label, error, hint, ...rest } = props;

  const classes = classNames(
    's-input',
    { 's-input--error': !!error },
    className,
  );

  return (
    <div className={classNames('s-input-wrapper', wrapperClassName)}>
      <LibInput className={classes} innerLabel={label} {...rest} />
      <Anim.Collapse active={!!error}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
      {hint && <p className="s-input__hint">{hint}</p>}
    </div>
  );
};

export default Input;
