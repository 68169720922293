import React from 'react';
import { TableRow } from 'types';
import classNames from 'classnames';

const useTableColumns = (
  tableRows: TableRow[],
  handleRowClick?: (_id?: string) => void,
) =>
  tableRows.map(({ id, data }) => (
    <tr
      key={id}
      onClick={() => handleRowClick?.(id)}
      className={classNames({
        'searchality-table__row--clickable': handleRowClick,
      })}
    >
      {Object.keys(data).map((tableDataKey) => (
        <td key={tableDataKey}>{data[tableDataKey]}</td>
      ))}
    </tr>
  ));

export default useTableColumns;
