import { useTranslation } from 'react-i18next';

const useTableHeader = () => {
  const { t } = useTranslation();

  return [
    {
      text: t('name'),
      value: 'name',
      sortable: true,
    },
    {
      text: t('createdAt'),
      value: 'createdAt',
      sortable: true,
    },
    {
      text: t('lastLogin'),
      value: 'lastLogin',
      sortable: true,
    },
    {
      text: t('id'),
      value: '_id',
      sortable: true,
    },
    {
      text: t('structureType'),
      value: 'structureType',
      sortable: true,
    },
    {
      text: t('schoolType'),
      value: 'type',
      sortable: true,
    },
    {
      text: t('state'),
      value: 'state',
      sortable: true,
    },
    {
      text: t('county'),
      value: 'county',
      sortable: true,
    },
    {
      text: t('website'),
      value: 'website',
      sortable: true,
    },
    {
      text: t('schoolCount'),
      value: 'schoolCount',
      sortable: true,
    },
    {
      text: t('accountStatus'),
      value: 'status',
      sortable: true,
    },
    {
      text: t('accountType'),
      value: 'accountType',
    },
  ];
};

export default useTableHeader;
