import React from 'react';
import { Params, TableColumn } from 'types';
import { ReactComponent as SortIcon } from 'icons/SortArrow.icon.svg';
import classNames from 'classnames';

const useTableColumns = (
  tableColumns: TableColumn[],
  params?: Params,
  handleHeaderClick?: (value: string) => void,
) => {
  return tableColumns.map(({ value, text, sortable }) => (
    <th
      className={classNames(
        { 'searchality-table__header--sortable': sortable },
        {
          'searchality-table--active-column': [
            value,
            `-${value?.split(' ').join(' -')}`,
          ].includes(params?.$sort),
        },
      )}
      onClick={() => sortable && handleHeaderClick?.(value)}
      key={value}
    >
      {text}
      {sortable && (
        <SortIcon
          transform={
            params.$sort === `-${value?.split(' ').join(' -')}`
              ? 'rotate(180)'
              : 'rotate(0)'
          }
          className="searchality-table__sort-icon"
        />
      )}
    </th>
  ));
};

export default useTableColumns;
