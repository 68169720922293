export const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const statesOptions = Object.keys(states).map((state) => ({
  label: states[state as keyof typeof states],
  value: states[state as keyof typeof states],
}));

export const CSV_MAP = {
  _id: '_id',
  type: 'Type',
  status: 'Status',
  statusInSchool: 'Status in School',
  isFavourite: 'Is Favourite',
  isArchived: 'Is Archived',
  matchingPercentage: 'Matching Percentage',

  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  address: 'Address',
  city: 'City',
  state: 'State',
  zipcode: 'Zipcode',
  'phoneNumber.phoneNumber': 'Phone Number',
  'phoneNumber.countryCode': 'Phone Number Country Code',

  doesHaveExperienceInTheField: 'Does Have Experience In The Field',
  doesHaveYearsOfExperience: 'Does Have Years Of Experience',
  yearsOfExperienceInTheField: 'Years Of Experience In The Field',
  yearsOfExperience: 'Years Of Experience',
  'languageRequirement.language': 'Language Requirement - Language',
  'languageRequirement.doesSpeak': 'Language Requirement - Does Speak',
  'languageRequirement.level': 'Language Requirement - Level',

  englishLevel: 'English Level',
  isAbleToStartOnRequiredDate: 'Is Able To Start On Required Date',
  isAbleToWorkAsBoardingParent: 'Is Able To Work As Boarding Parent',
  earliestAvailableStartingDate: 'Earliest Available Starting Date',
  hasRightToWork: 'Has Right To Work',
  rightToWork: 'Right To Work',
  visaType: 'Visa Type',
  dateUntilVisaIsValid: 'Date Until Visa Is Valid',
  rightToWorkDescription: 'Right To Work Description',
  doesMeetMinimumEducationLevel: 'Does Meet Minimum Education Level',

  doesHaveExperinceWithFaithBasedSchools:
    'Does Have Experience With Faith Based Schools',
  yearsOfExperienceWithFaithBasedSchools:
    'Years Of Experience With Faith Based Schools',

  'references.firstName': 'First Reference - First Name',
  'references.lastName': 'First Reference - Last Name',
  'references.jobTitle': 'First Reference - Job Title',
  'references.relationship': 'First Reference - Relationship',
  'references.schoolName': 'First Reference - School Name',
  'references.schoolCity': 'First Reference - School City',
  'references.schoolWebsite': 'First Reference - School Website',
  'references.collaborationYears': 'First Reference - Collaboration Years',
  'references.contactEmail': 'First Reference - Contact Email',
  'references.contactPhone': 'First Reference - Contact Phone',

  'qualifications.startDate': 'Qualifications - Start Date',
  'qualifications.educationLevel': 'Qualifications - Education Level',
  'qualifications.yearsOfExperience': 'Qualifications - Years Of Experience',
  'qualifications.curriculumExperience':
    'Qualifications - Curriculum Experience',
  'qualifications.languages': 'Qualifications - Languages',
  'qualifications.rightToWork': 'Qualifications - Right To Work',
  'qualifications.boardingRequirements':
    'Qualifications - Boarding Requirements',
  'qualifications.numberOfMatchingFields':
    'Qualifications - Number Of Matching Fields',
  'qualifications.numberOfTotalFields':
    'Qualifications - Number Of Total Fields',

  createdAt: 'Created At',
  appliedAt: 'Applied At',
  expiredAt: 'Expired At',
  contactId: 'Contact ID',

  positionDivision: 'Position Division',
  subjectGradeLevel: 'Subject Grade Level',
  videoUrl: 'Video URL',

  'education.schoolName': 'First Education - School Name',
  'education.degreeLevel': 'First Education - Degree Level',
  'education.fieldOfStudy': 'First Education - Field Of Study',
  'education.location': 'First Education - Location',
  'education.startDate': 'First Education - Start Date',
  'education.endDate': 'First Education - End Date',
  'education.isCurrentlyStudying': 'First Education - Is Currently Studying',
  'education.activitiesAndSocieties':
    'First Education - Activities And Societies',

  'experience.positionTitle': 'First Experience - Position Title',
  'experience.positionDivision': 'First Experience - Position Division',
  'experience.isFullTimeEmployment':
    'First Experience - Is Full Time Employment',
  'experience.isPermanentContract': 'First Experience - Is Permanent Contract',
  'experience.schoolName': 'First Experience - School Name',
  'experience.location': 'First Experience - Location',
  'experience.startDate': 'First Experience - Start Date',
  'experience.endDate': 'First Experience - End Date',
  'experience.isCurrentlyWorking': 'First Experience - Is Currently Working',
  'experience.roleDescription': 'First Experience - Role Description',

  wasMatched: 'Was Matched',
  category: 'Category',
};
