import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useSortByOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t('Candidates.oldestApplications'),
        value: 'appliedAt',
      },
      {
        label: t('Candidates.newestApplications'),
        value: '-appliedAt',
      },
      {
        label: t('Candidates.lastNameAZ'),
        value: 'lastName',
      },
      {
        label: t('Candidates.lastNameZA'),
        value: '-lastName',
      },
    ],
    [t],
  );
};

export default useSortByOptions;
