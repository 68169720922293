import useAppDispatch from 'hooks/useAppDispatch';
import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import paramsActions from 'store/actions/params.actions';

const SchoolGroupVacancies: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(paramsActions.resetFilters('schoolGroupVacancies'));
        dispatch(paramsActions.resetSort('schoolGroupVacancies'));
        dispatch(paramsActions.changeCurrentPage('schoolGroupVacancies', 1));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export default SchoolGroupVacancies;
