import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PaginationInfo from 'components/PaginationInfo';
import {
  useDeleteAssociationSchoolMutation,
  useGetAssociationSchoolsQuery,
} from 'api/associations.api';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/Pagination';
import paramsActions from 'store/actions/params.actions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import AssociationSchoolCard from 'components/AssociationSchoolCard';
import confirm from 'modules/confirm';
import { ReactComponent as SearchIcon } from 'icons/Search.svg';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import SortSelect from 'components/SortSelect';
import DebouncedInput from 'components/DebouncedInput';
import { batch } from 'react-redux';
import { useSortAzOptions } from 'router/subrouters/Dashboard/hooks/useSortAzOptions';

import './AssociationSchools.styles.scss';

type AssociationSchoolsProps = {
  className?: string;
};

const AssociationSchools: React.FC<AssociationSchoolsProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const params = useAppSelector(
    ({ associationSchoolsParams }) => associationSchoolsParams,
  );

  const { associationId } = useParams();
  const dispatch = useAppDispatch();

  const [deleteSchool] = useDeleteAssociationSchoolMutation();

  const { data, isLoading, isFetching } = useGetAssociationSchoolsQuery({
    params,
    associationId,
  });

  const sortOptions = useSortAzOptions('name');

  const onSort = useCallback(
    (value: string) => {
      dispatch(paramsActions.sort('associationSchoolsParams', value));
    },
    [dispatch],
  );

  const onSearchStringChange = useCallback(
    (searchString: string) => {
      batch(() => {
        dispatch(
          paramsActions.applySearchString(
            'associationSchoolsParams',
            searchString,
          ),
        );
        dispatch(
          paramsActions.changeCurrentPage('associationSchoolsParams', 1),
        );
      });
    },
    [dispatch],
  );

  const handleDelete = useCallback(
    async (schoolId: string) => {
      await confirm({
        title: t('DeleteAssociationSchool.title'),
        content: <p>{t('DeleteAssociationSchool.description')}</p>,
        onSubmit: () => {
          deleteSchool({ associationId, schoolId })
            .unwrap()
            .then(() =>
              dispatch(popSuccess(t('DeleteAssociationSchool.success'))),
            )
            .catch((e) => dispatch(popServerError(e)));
        },
      });
    },
    [associationId, deleteSchool, dispatch, t],
  );

  if (isLoading) return <PageLoader />;

  const { items, ...pagination } = data;

  const classes = classNames('association-schools', className);

  return (
    <div className={classes}>
      {isFetching ||
      items?.length ||
      params?.$searchString ||
      params?.$where ? (
        <>
          <div className="association-schools__input-sort">
            <DebouncedInput
              onValueChange={onSearchStringChange}
              prefixNode={<SearchIcon />}
              placeholder={t('searchByName')}
            />
            <SortSelect
              options={sortOptions}
              value={params?.$sort}
              placeholder={t('sortBy')}
              multiple={false}
              icon={<ArrowIcon />}
              onChange={({ value }) => {
                onSort(value);
              }}
            />
          </div>

          <PaginationInfo
            name={t('school', {
              count: pagination?.totalItems,
            })}
            {...pagination}
            limit={params?.$limit}
          />

          <ul>
            {items?.map((school) => (
              <AssociationSchoolCard
                onClick={() =>
                  (window.location.href = school?.website?.includes('http')
                    ? school?.website
                    : `//${school?.website}`)
                }
                onDelete={handleDelete}
                key={school._id}
                {...school}
              />
            ))}
          </ul>
        </>
      ) : (
        t('currentlyNoSchoolsForAssociation')
      )}
      {pagination?.totalPages > 1 && (
        <Pagination
          onChange={(pageNumber) =>
            dispatch(
              paramsActions.changeCurrentPage(
                'associationSchoolsParams',
                pageNumber,
              ),
            )
          }
          {...pagination}
        />
      )}
    </div>
  );
};

export default AssociationSchools;
