import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  useGetAssociationQuery,
  useUpdateAssociationMutation,
} from 'api/associations.api';
import PageLoader from 'components/PageLoader';
import { CreateAssociationFrom } from 'models/Association';
import AssociationInformationForm from '../../../../components/AssociationInformationForm';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';

import './EditAssociation.styles.scss';
import { AssociationType } from 'searchality-data';

type EditAssociationProps = {
  className?: string;
};

const EditAssociation: React.FC<EditAssociationProps> = (props) => {
  const { className } = props;

  const { associationId } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [updateAssociation] = useUpdateAssociationMutation();
  const { data, isLoading } = useGetAssociationQuery(associationId);

  const { website, name, states, type, acronym } = data || {};

  const handleEditAssociation = useCallback(
    (values: CreateAssociationFrom) => {
      const { state, ...rest } = values;
      updateAssociation({
        associationId,
        body: {
          ...rest,
          states: values?.state ? [values.state] : values?.states,
        },
      })
        .unwrap()
        .then(() => dispatch(popSuccess(t('updateAssociationSuccess'))))
        .catch((e) => dispatch(popServerError(e)));
    },
    [associationId, dispatch, t, updateAssociation],
  );

  if (isLoading) return <PageLoader />;

  const classes = classNames('edit-association', className);

  return (
    <div className={classes}>
      <AssociationInformationForm
        initialValues={{
          acronym,
          website,
          name,
          states,
          type,
          state: type === AssociationType.STATE ? states?.[0] : undefined,
        }}
        onFormSubmit={handleEditAssociation}
        isEdit
      />
    </div>
  );
};

export default EditAssociation;
