import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import Authenticate from './pages/Authenticate';
import ForgotPassword from './pages/ForgotPassword';
import LoginPage from './pages/Login';
import ResetPassword from './pages/ResetPassword';

export default [
  {
    path: 'login',
    element: LoginPage,
  },
  {
    path: 'forgot-password',
    element: ForgotPassword,
  },
  {
    path: 'reset-password',
    element: ResetPassword,
  },
  {
    path: 'authenticate',
    element: Authenticate,
  },
  {
    index: true,
    element: <Navigate to="login" />,
  },
] as Array<ComplexRoute>;
