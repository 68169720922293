import { Button } from 'ncoded-component-library';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { ReactComponent as ProfileIcon } from 'icons/Profile.icon.svg';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import VacancyFillCandidateModal from '../../components/VacancyFillCandidateModal';
import VacancyFillModal from '../../components/VacancyFillModal';
import confirm from 'modules/confirm';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import { useNavigate, useParams } from 'react-router-dom';
import { FillVacancyMethod, VacancyStatus } from 'searchality-data';
import PageLoader from 'components/PageLoader';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';

import './VacancyInformation.styles.scss';
import {
  useFillVacancyMutation,
  useGetVacancyQuery,
} from 'api/schoolGroups.api';
import TabsNavigation from 'components/TabsNavigation';
import Heading from 'components/Heading';
import GoBackButton from 'components/GoBackButton';
import useVacancyProfileTabs from '../VacancyCandidates/hooks/useVacancyProfileTabs';

const VacancyInformation: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { schoolGroupId, vacancyId } = useParams();
  const { data: vacancy, isLoading } = useGetVacancyQuery({
    vacancyId,
    schoolGroupId,
  });
  const tabs = useVacancyProfileTabs();
  const vacancyCandidateModalRef = useRef<ModalRef>(null);
  const vacancyFillModalRef = useRef<ModalRef>(null);

  const [fillVacancy] = useFillVacancyMutation();

  const baseClass = 'vacancy-information';

  if (isLoading) {
    return <PageLoader />;
  }

  const {
    createdAt,
    publishedAt,
    editedAt,
    applicantCount,
    publisher,
    creator,
    updater,
    status,
  } = vacancy;

  const publisherName = publisher
    ? `${publisher.firstName} ${publisher.lastName}`
    : '';
  const updaterName = updater ? `${updater.firstName} ${updater.lastName}` : '';
  const creatorName = creator ? `${creator.firstName} ${creator.lastName}` : '';

  const openVacancyFillModal = () => {
    vacancyFillModalRef.current.open();
  };

  const closeVacancyFillModal = () => {
    vacancyFillModalRef.current.close();
  };

  const openVacancyCandidateModal = () => {
    vacancyCandidateModalRef.current.open();
  };

  const closeVacancyCandidateModal = () => {
    vacancyCandidateModalRef.current.close();
  };

  const onFillInternally = async () => {
    fillVacancy({
      fillMethod: FillVacancyMethod.INTERNALLY,
      vacancyId,
      schoolGroupId,
    })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('fillIternallySuccess')));
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  };

  const onFillFromAnotherSource = async () => {
    fillVacancy({
      fillMethod: FillVacancyMethod.ANOTHER_SOURCE,
      vacancyId,
      schoolGroupId,
    })
      .unwrap()
      .then(() => {
        dispatch(popSuccess(t('fillFromAnotherSourceSuccess')));
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  };

  const onVacancyFillConfirm = async (fillType: string) => {
    if (fillType === FillVacancyMethod.INTERNALLY) {
      const res = await confirm({
        title: t('vacancyFilledInternaly.title'),
        content: <p>{t('vacancyFilledInternaly.description')}</p>,
        confirmBtnText: t('vacancyFilledInternaly.buttonText'),
        onSubmit: onFillInternally,
      });

      if (res) {
        closeVacancyFillModal();
      }
    }

    if (fillType === FillVacancyMethod.ANOTHER_SOURCE) {
      const res = await confirm({
        title: t('vacancyFillFromAnotherSource.title'),
        content: <p>{t('vacancyFilledInternaly.description')}</p>,
        confirmBtnText: t('vacancyFilledInternaly.buttonText'),
        onSubmit: onFillFromAnotherSource,
      });
      if (res) {
        closeVacancyFillModal();
      }
    }

    if (fillType === FillVacancyMethod.FROM_CANDIDATE_APPLICATIONS) {
      closeVacancyFillModal();
      openVacancyCandidateModal();
    }
  };

  return (
    <>
      <GoBackButton to="../../" />
      <Heading title={vacancy?.positionTitle}>
        <div className="heading-buttons-group">
          <p className={`vacancy-status`}>
            {t('status')}:&nbsp; <span>{status}</span>
          </p>
          <Button onClick={() => navigate('../preview')} variant="outline">
            {t('preview')}
          </Button>
        </div>
      </Heading>
      <TabsNavigation tabs={tabs} />
      <div className={baseClass}>
        <h5>{t('vacancyInformation')}</h5>
        <ul className={`${baseClass}__details`}>
          <li>
            <p>{t('created')}</p>
            <p>
              {`${DateService.toDateTime(createdAt)} ${t('by')} ${creatorName}`}
            </p>
          </li>
          <li>
            <p>{t('lastEdited')}</p>
            <p>
              {`${DateService.toDateTime(editedAt)} ${t('by')} ${updaterName}`}
            </p>
          </li>
          <li>
            <p>{t('publishedOn')}</p>
            <p>
              {publishedAt
                ? `${DateService.toDateTime(publishedAt)} ${t(
                    'by',
                  )} ${publisherName}`
                : 'N/A'}
            </p>
          </li>
          <li>
            <p>{t('Candidates.total')}</p>
            <p>{applicantCount}</p>
          </li>
        </ul>
        <>
          <div className={`${baseClass}__actions`}>
            <Button
              variant="outline"
              icon={<ProfileIcon />}
              iconPosition="right"
              onClick={openVacancyFillModal}
              disabled={[
                VacancyStatus.FILLED,
                VacancyStatus.ARCHIVED,
                VacancyStatus.DRAFT,
                VacancyStatus.EXPIRED,
              ].includes(status)}
            >
              {t('fillVacancy')}
            </Button>
          </div>
          <VacancyFillModal
            ref={vacancyFillModalRef}
            closeModal={closeVacancyFillModal}
            onConfirm={onVacancyFillConfirm}
          />
          <ValueContextProvider>
            <VacancyFillCandidateModal
              ref={vacancyCandidateModalRef}
              vacancyId={vacancy._id}
              closeModal={closeVacancyCandidateModal}
            />
          </ValueContextProvider>
        </>
      </div>
    </>
  );
};

export default VacancyInformation;
